import { Fragment, useCallback, useMemo, type ReactNode } from 'react';

import MuiAutocomplete, {
  type AutocompleteValue,
  type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import type { IconName } from 'components/Icon';
import { OptionItem } from 'components/OptionItem';
import type { LiteralNodeUnion, Option, Optional } from 'types';

export type AutocompleteProps<
  T,
  Parameters extends Optional<object> = undefined,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
> = Omit<
  MuiAutocompleteProps<Option<T, Parameters>, Multiple, DisableClearable, false>,
  'error' | 'id' | 'name' | 'renderInput' | 'renderOption' | 'value'
> & {
  /**
   * Input's name
   */
  name: string;

  /**
   * Input's label
   */
  label?: string;

  /**
   * Input's placeholder
   */
  placeholder?: string;

  /**
   * Input's value
   */
  value: T;

  /**
   * Determines if the input should display error
   */
  hasError?: boolean;

  /**
   * Input's error message
   */
  error?: string;

  /**
   * Input's helper text
   */
  helperText?: string;

  /**
   * Determines if the option's label and subLabel should be translated
   * Once enabled - the label and subLabel from options are being wrapped with `TFunction`
   *
   * @default true
   */
  shouldTranslateOptions?: boolean;

  /**
   * Helper function to render the option's label
   */
  getListItemLabel?: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;

  /**
   * Helper function to render the option's subLabel
   */
  getListItemSubLabel?: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;

  /**
   * Helper function to render the option's left adornment
   */
  getListItemLeftAdornment?: (option: Option<T, Parameters>, isSelected?: boolean) => LiteralNodeUnion<IconName>;

  /**
   * Helper function to render the option's right adornment
   */
  getListItemRightAdornment?: (option: Option<T, Parameters>, isSelected?: boolean) => LiteralNodeUnion<IconName>;

  /**
   * Helper function to render the additional option
   */
  renderExtraOption?: () => ReactNode;
};

export function Autocomplete<
  T,
  Parameters extends Optional<object> = undefined,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
>({
  name,
  label,
  placeholder,

  fullWidth,
  disabled,

  options,
  value: valueProp,
  hasError,
  error,
  helperText,

  multiple,
  shouldTranslateOptions = true,

  getOptionLabel,

  getListItemLabel,
  getListItemSubLabel,
  getListItemLeftAdornment,
  getListItemRightAdornment,
  renderExtraOption,
  ...props
}: AutocompleteProps<T, Parameters, Multiple, DisableClearable>) {
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (multiple) {
      return options.filter((o) => includes(valueProp as T[], o.value));
    }

    return options.find((o) => isEqual(o.value, valueProp)) ?? null;
  }, [multiple, options, valueProp]);

  const renderOptionLabel = useCallback(
    (option: Option<T, Parameters>) => {
      if (getOptionLabel) {
        return getOptionLabel(option);
      }

      return shouldTranslateOptions ? t(option.label) : option.label;
    },
    [getOptionLabel, shouldTranslateOptions, t],
  );

  const renderListItemLabel = useCallback(
    (option: Option<T, Parameters>, isSelected?: boolean) => {
      if (getListItemLabel) return getListItemLabel(option, isSelected);

      if (!shouldTranslateOptions) return option.label;

      return typeof option.value === 'number' ? t(option.label, { count: option.value }) : t(option.label);
    },
    [getListItemLabel, shouldTranslateOptions, t],
  );

  const renderListItemSubLabel = useCallback(
    (option: Option<T, Parameters>, isSelected?: boolean) => {
      if (getListItemSubLabel) return getListItemSubLabel(option, isSelected);

      if (!option.subLabel) return null;

      if (!shouldTranslateOptions) return option.subLabel;

      return t(option.subLabel);
    },
    [getListItemSubLabel, shouldTranslateOptions, t],
  );

  return (
    <FormControl error={hasError} fullWidth={fullWidth}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      <MuiAutocomplete
        {...props}
        id={name}
        value={value as AutocompleteValue<Option<T, Parameters>, Multiple, DisableClearable, false>}
        options={options}
        getOptionLabel={renderOptionLabel}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        isOptionEqualToValue={(a, b) => isEqual(a.value, b.value)}
        renderInput={(params) => <TextField {...params} name={name} placeholder={placeholder} error={hasError} />}
        renderOption={({ key, ...liProps }, option, { selected, index }) => (
          <Fragment key={key}>
            <OptionItem
              {...liProps}
              key={key}
              option={option}
              isSelected={selected}
              getItemLabel={renderListItemLabel}
              getItemSubLabel={renderListItemSubLabel}
              getItemLeftAdornment={getListItemLeftAdornment}
              getItemRightAdornment={getListItemRightAdornment}
            />

            {index === options.length - 1 && renderExtraOption && (
              <li className={liProps.className} key="Autocomplete-extra-option">
                {renderExtraOption()}
              </li>
            )}
          </Fragment>
        )}
      />

      <Collapse in={hasError || !!helperText} unmountOnExit>
        <FormHelperText error={hasError}>{hasError ? error?.toString() : helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
}
