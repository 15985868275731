import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import type { Nullable } from 'types';

import { Input, type InputProps } from '../Input';

export type NumberInputProps = Omit<InputProps<string>, 'onChange' | 'defaultValue' | 'type'> & {
  defaultValue?: Nullable<string | number>;
  unit?: string;
  onChange?: (value: NumberFormatValues) => void;
};

export function NumberInput({ value, unit, onChange, ...props }: NumberInputProps) {
  return (
    <NumericFormat
      fullWidth
      value={value}
      suffix={unit ? ` ${unit}` : ''}
      onValueChange={onChange}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      customInput={Input}
      {...props}
      {...props}
    />
  );
}
