import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { api } from 'store/api';
import type { NetworkType } from 'store/proxies/types';
import type { Nullable } from 'types';
import { PersistService } from 'utils/persist';

import type {
  OrderPriceModel,
  ProxySetupEnabledFields,
  ProxySetupFormConfigurations,
  ProxySetupFormErrors,
  ProxySetupModel,
} from './models';
import type { OrderState, OrderStep, ResetOrderFlowPayload } from './types';

// Coupon code provided by user
const couponCode = PersistService.get<string>('order-proxy-discount-code');

// Network type selected by user
const selectedNetworkType = PersistService.get<NetworkType>('order-proxy-network-type');

// Current order step
const currentStep = PersistService.get<OrderStep>('order-proxy-current-step');

// User's selected proxy configuration
const proxySettings = PersistService.get<ProxySetupModel>('order-proxy-configuration');

// Proxy configurations for User's selected proxy configuration
const proxyFieldsConfigurations = PersistService.get<ProxySetupFormConfigurations>('order-proxy-fields-configurations');

/**
 * Reducer's initial state
 */
const initialState: OrderState = {
  couponCode,
  selectedNetworkType,
  currentStep,

  price: null,
  isPriceLoading: false,
  proxySettings,
  proxyFieldsConfigurations,
  proxyFieldsEnabled: null,
  proxyFieldsErrors: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCouponCode: (state, { payload }: PayloadAction<Nullable<string>>) => {
      PersistService.set('order-proxy-discount-code', payload);

      state.couponCode = payload;
    },

    setCurrentStep: (state, { payload }: PayloadAction<Nullable<OrderStep>>) => {
      PersistService.set('order-proxy-current-step', payload);

      state.currentStep = payload;
    },

    setSelectedNetworkType: (state, { payload }: PayloadAction<Nullable<NetworkType>>) => {
      PersistService.set('order-proxy-network-type', payload);
      PersistService.multipleRemove(['order-proxy-configuration', 'order-proxy-fields-configurations']);

      state.proxySettings = null;
      state.proxyFieldsConfigurations = null;
      state.selectedNetworkType = payload;
    },

    resetOrderFlow: (
      state,
      {
        payload: { shouldResetCouponCode, shouldResetFlow } = { shouldResetCouponCode: true, shouldResetFlow: true },
      }: PayloadAction<ResetOrderFlowPayload | undefined>,
    ) => {
      PersistService.multipleRemove([
        'order-proxy-network-type',
        'order-proxy-configuration',
        'order-proxy-current-step',
        'order-proxy-fields-configurations',
      ]);

      if (shouldResetFlow) {
        PersistService.multipleRemove(['vpn-flow', 'marketplace-flow']);

        state.selectedNetworkType = null;
        state.currentStep = null;
        state.proxySettings = null;
        state.proxyFieldsConfigurations = null;
        state.proxyFieldsEnabled = null;
        state.price = null;
        state.isPriceLoading = false;
      }

      if (shouldResetCouponCode) {
        PersistService.remove('order-proxy-discount-code');

        state.couponCode = null;
      }
    },

    setOrderPrice: (state, { payload }: PayloadAction<Nullable<OrderPriceModel>>) => {
      state.price = payload;
    },

    setPriceLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPriceLoading = payload;
    },

    setServiceSettings: (state, { payload }: PayloadAction<Nullable<ProxySetupModel>>) => {
      PersistService.set('order-proxy-configuration', payload);

      if (payload?.networkType) {
        PersistService.set('order-proxy-network-type', payload.networkType);
        state.selectedNetworkType = payload.networkType;
      }

      state.proxySettings = payload;
    },

    setProxyFieldsConfigurations: (state, { payload }: PayloadAction<Nullable<ProxySetupFormConfigurations>>) => {
      state.proxyFieldsConfigurations = payload;
    },

    updateProxyFieldsConfigurations: (state, { payload }: PayloadAction<ProxySetupFormConfigurations>) => {
      state.proxyFieldsConfigurations = { ...state.proxyFieldsConfigurations, ...payload };
    },

    setProxyFieldsEnabled: (state, { payload }: PayloadAction<Nullable<ProxySetupEnabledFields>>) => {
      state.proxyFieldsEnabled = payload;
    },

    updateProxyFieldsEnabled: (state, { payload }: PayloadAction<ProxySetupEnabledFields>) => {
      state.proxyFieldsEnabled = { ...state.proxyFieldsEnabled, ...payload };
    },

    setProxySetupFormErrors: (state, { payload }: PayloadAction<Nullable<ProxySetupFormErrors>>) => {
      state.proxyFieldsErrors = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
      PersistService.multipleRemove([
        'order-proxy-current-step',
        'order-proxy-network-type',
        'order-proxy-configuration',
        'order-proxy-discount-code',
        'order-proxy-fields-configurations',
      ]);

      state.proxyFieldsConfigurations = null;
      state.proxySettings = null;
      state.couponCode = null;
      state.selectedNetworkType = null;
      state.currentStep = null;
      state.price = null;
      state.proxyFieldsEnabled = null;
      state.proxyFieldsErrors = null;
    });
  },
});

export const {
  resetOrderFlow,
  setCouponCode,
  setSelectedNetworkType,
  setCurrentStep,

  setOrderPrice,
  setPriceLoading,
  setServiceSettings,
  setProxyFieldsConfigurations,
  updateProxyFieldsConfigurations,
  setProxySetupFormErrors,
  setProxyFieldsEnabled,
  updateProxyFieldsEnabled,
} = slice.actions;
export const { reducer: orderReducer } = slice;
