import { Language } from 'store/accounts/models';
import i18n from 'translations';

const locale = i18n.language === Language.CN ? 'zh' : i18n.language;

export const CurrencyFormatter = Intl.NumberFormat(locale || 'en-US', { style: 'currency', currency: 'USD' });

export const CurrencyFormatterOptionalDecimals = Intl.NumberFormat(locale || 'en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
