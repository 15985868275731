import { type ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';

import Box, { boxClasses } from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { RouterLink } from 'components/RouterLink';
import type { NavItemConfig } from 'modules/common/AppLayout/Sidebar/types';
import { isNavItemActive } from 'utils/navigation';

type NavItemProps = NavItemConfig & {
  onClose?: () => void;
};

export const NavItem = forwardRef<HTMLLIElement, NavItemProps>(
  ({ title, icon, href, label, labelColor, labelVariant, disabled, external, matcher, onClick, onClose }, ref) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const isActive = useMemo(
      () => isNavItemActive({ pathname, disabled, external, href, matcher }),
      [disabled, external, href, matcher, pathname],
    );

    const isHoverable = useMemo(() => !disabled && !isActive, [disabled, isActive]);

    const iconColor = useMemo(() => {
      return isActive ? 'var(--NavItem-active-icon-color)' : 'var(--NavItem-icon-color)';
    }, [isActive]);

    const props = useMemo<ComponentPropsWithoutRef<typeof Box>>(() => {
      if (href) {
        return {
          component: RouterLink,
          href,
          target: external ? '_blank' : undefined,
          rel: external ? 'noreferrer' : undefined,
          onClick: (e) => {
            onClose?.();
            onClick?.(e);
          },
        };
      }

      return {
        role: 'button',
        onClick: (e) => {
          onClose?.();
          onClick?.(e);
        },
      };
    }, [external, href, onClick, onClose]);

    return (
      <Collapse in={!disabled} unmountOnExit>
        <Box ref={ref} component="li" sx={{ userSelect: 'none' }}>
          <Box
            {...props}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              flex: '0 0 auto',
              gap: '16px',

              py: '8px',
              position: 'relative',

              color: 'var(--NavItem-color)',
              textDecoration: 'none',
              whiteSpace: 'nowrap',
              cursor: 'pointer',

              transition: theme.transitions.create(['color'], { duration: 150 }),

              [`& > .${boxClasses.root} > .${boxClasses.root}`]: {
                transition: theme.transitions.create(['color'], { duration: 150 }),
              },

              ...(isActive && { color: 'var(--NavItem-active-color)' }),

              ...(isHoverable && {
                '&:hover': {
                  color: 'var(--NavItem-hover-color)',

                  [`& > .${boxClasses.root} > .${boxClasses.root}`]: { color: 'var(--NavItem-hover-icon-color)' },
                },
              }),
            })}
            tabIndex={0}
          >
            <Box display="flex" alignItems="center" justifyContent="center" flex="0 0 auto">
              {icon && <Icon name={icon} size="small" color={iconColor} />}
            </Box>

            <Box display="inline-flex" flex="1 1 auto">
              <Typography component="span" variant="body-2" color="inherit" fontWeight={isActive ? 700 : 400}>
                {t(`common:sidebar.${title}`)}
              </Typography>
            </Box>

            {label && <Chip color={labelColor} variant={labelVariant} size="small" label={label} />}
          </Box>
        </Box>
      </Collapse>
    );
  },
);
