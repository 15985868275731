import { useEffect, useState } from 'react';

import type { Language } from 'store/accounts/models';
import { getDefaultResources } from 'utils/poe/helpers.mjs';
import PoeLoader from 'utils/poe/loader';

const defaultResources = getDefaultResources();

const poe = new PoeLoader(Object.keys(defaultResources) as Language[], defaultResources);

export function useLoadTranslations() {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  useEffect(() => poe.subscribe(() => setResourcesLoaded(true)), []);

  return {
    resourcesLoaded,
  };
}
