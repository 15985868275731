import { accountEndpoints } from 'store/accounts/services';
import { authEndpoints } from 'store/auth/services';
import { commonEndpoints } from 'store/common/services';
import { marketplaceEndpoints } from 'store/marketplace/services';
import { orderEndpoints } from 'store/orders/services';
import { proxyEndpoints } from 'store/proxies/services';
import { referralsEndpoints } from 'store/referrals/services';
import { vpnEndpoints } from 'store/vpns/services';

import { base } from './base';

export const api = base.injectEndpoints({
  endpoints: (builder) => ({
    ...accountEndpoints(builder),
    ...authEndpoints(builder),
    ...commonEndpoints(builder),
    ...marketplaceEndpoints(builder),
    ...orderEndpoints(builder),
    ...proxyEndpoints(builder),
    ...referralsEndpoints(builder),
    ...vpnEndpoints(builder),
  }),
});

export const {
  // #region Account
  /** Queries */
  useGetAccountBalanceQuery,
  useGetAccountProfileQuery,
  useGetApiKeysQuery,
  useGetBillingPreferencesQuery,
  useGetCreditCardQuery,
  useGetIdentityVerificationTokenQuery,
  useGetInvoicesQuery,
  useGetNotificationPreferencesQuery,
  useGetTopUpQuery,
  useGetTransactionsQuery,
  useGetTwoFactorAuthenticationMethodsQuery,

  /** Lazy Queries */
  useLazyGetAccountBalanceQuery,
  useLazyGetAccountProfileQuery,
  useLazyGetApiKeysQuery,
  useLazyGetBillingPreferencesQuery,
  useLazyGetCreditCardQuery,
  useLazyGetIdentityVerificationTokenQuery,
  useLazyGetInvoicesQuery,
  useLazyGetNotificationPreferencesQuery,
  useLazyGetTopUpQuery,
  useLazyGetTransactionsQuery,
  useLazyGetTwoFactorAuthenticationMethodsQuery,

  /** Mutations */
  useAddCreditCardMutation,
  useBulkDownloadInvoicesMutation,
  useChangeLanguageMutation,
  useChangePasswordMutation,
  useDeleteApiKeyMutation,
  useDeleteCreditCardMutation,
  useDisableHardwareTwoFactorMutation,
  useDisableSoftwareTwoFactorMutation,
  useEnableHardwareTwoFactorMutation,
  useEnableSoftwareTwoFactorMutation,
  useGenerateApiKeyMutation,
  useGetTopUpFeesMutation,
  useResendEmailVerificationLinkMutation,
  useSkipProfileDetailsMutation,
  useTopUpMutation,
  useUpdateAccountProfileMutation,
  useUpdateAutoTopUpPreferencesMutation,
  useUpdateNotificationPreferencesMutation,
  // #endregion Account

  // #region Auth
  /** Queries */
  useGetCaptchaQuery,
  useGetMultiFactorStatusQuery,
  useGetSessionQuery,

  /** Lazy Queries */
  useLazyGetCaptchaQuery,
  useLazyGetMultiFactorStatusQuery,
  useLazyGetSessionQuery,

  /** Mutations */
  useForgotPasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useMultiFactorVerificationMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  // #endregion Auth

  // #region Common
  /** Queries */
  useGetExperimentsQuery,
  useGetFeaturesQuery,
  useGetMaintenanceWindowsQuery,

  /** Lazy Queries */
  useLazyGetExperimentsQuery,
  useLazyGetFeaturesQuery,
  useLazyGetMaintenanceWindowsQuery,
  // #endregion Common

  // #region Proxy
  /** Queries */
  useGetBulkAutoExtendSettingsQuery,
  useGetBulkProxyExtendBandwidthPriceQuery,
  useGetBulkProxyExtendPeriodPriceQuery,
  useGetChangeAuthenticationTypeOptionsQuery,
  useGetChangeProtocolOptionsQuery,
  useGetChangeThreadsOptionsQuery,
  useGetChangeUplinkSpeedOptionsQuery,
  useGetGlobalIPWhitelistQuery,
  useGetHostnameIpQuery,
  useGetIsProxyReplacementAvailableQuery,
  useGetProxyQuery,
  useGetProxyAdminDetailsQuery,
  useGetProxyChangelogQuery,
  useGetProxyEventsQuery,
  useGetProxyExtendBandwidthPriceQuery,
  useGetProxyExtendPeriodPriceQuery,
  useGetProxyIPWhitelistQuery,
  useGetProxySubnetsQuery,
  useGetProxiesQuery,
  useGetProxiesISPsQuery,
  useGetProxiesSummaryQuery,
  useGetReactivateProxyPriceQuery,
  useGetReplaceableProxiesQuery,
  useGetUpgradeThreadsPriceQuery,
  useGetUpgradeUplinkSpeedPriceQuery,

  /** Lazy Queries */
  useLazyGetBulkAutoExtendSettingsQuery,
  useLazyGetBulkProxyExtendBandwidthPriceQuery,
  useLazyGetBulkProxyExtendPeriodPriceQuery,
  useLazyGetChangeAuthenticationTypeOptionsQuery,
  useLazyGetChangeProtocolOptionsQuery,
  useLazyGetChangeThreadsOptionsQuery,
  useLazyGetChangeUplinkSpeedOptionsQuery,
  useLazyGetGlobalIPWhitelistQuery,
  useLazyGetHostnameIpQuery,
  useLazyGetIsProxyReplacementAvailableQuery,
  useLazyGetProxyQuery,
  useLazyGetProxyAdminDetailsQuery,
  useLazyGetProxyChangelogQuery,
  useLazyGetProxyEventsQuery,
  useLazyGetProxyExtendBandwidthPriceQuery,
  useLazyGetProxyExtendPeriodPriceQuery,
  useLazyGetProxyIPWhitelistQuery,
  useLazyGetProxySubnetsQuery,
  useLazyGetProxiesQuery,
  useLazyGetProxiesISPsQuery,
  useLazyGetProxiesSummaryQuery,
  useLazyGetReactivateProxyPriceQuery,
  useLazyGetReplaceableProxiesQuery,
  useLazyGetUpgradeThreadsPriceQuery,
  useLazyGetUpgradeUplinkSpeedPriceQuery,

  /** Mutations */
  useActivateProxyMutation,
  useAddProxyRouteMutation,
  useBulkAutoExtendProxyMutation,
  useBulkChangeProxyAuthenticationTypeMutation,
  useBulkChangeProtocolMutation,
  useBulkDownloadProxyCredentialsMutation,
  useBulkExtendProxyBandwidthMutation,
  useBulkExtendProxyPeriodMutation,
  useCancelProxyMutation,
  useChangeProxyPortMutation,
  useChangeThreadsMutation,
  useChangeUplinkSpeedMutation,
  useDeleteProxyRouteMutation,
  useDisableProxyAutoExtendMutation,
  useDisableProxyHttpsMutation,
  useEnableProxyAutoExtendMutation,
  useEnableProxyHttpsMutation,
  useExpireProxyMutation,
  useExtendProxyBandwidthMutation,
  useExtendProxyPeriodMutation,
  useGetProxyReplacementOptionsMutation,
  useInitializeProxyMutation,
  useReactivateProxyMutation,
  useRegenerateCredentialsMutation,
  useReplaceProxyMutation,
  useRequestCountryMutation,
  useRunDiagnosticRoutineMutation,
  useUpdateAuthenticationTypeMutation,
  useUpdateGlobalIPWhitelistMutation,
  useUpdateIPWhitelistMutation,
  useUpdateProxyProtocolMutation,
  useUpgradeThreadsMutation,
  useUpgradeUplinkSpeedMutation,
  useUpsertProxyNoteMutation,
  // #endregion

  // #region Referrals
  /** Queries */
  useGetBankDetailsQuery,
  useGetReferralPayoutRequestsQuery,
  useGetReferralTransactionsQuery,
  useGetReferralsDetailsQuery,
  useGetReferredUsersQuery,

  /** Lazy Queries */
  useLazyGetBankDetailsQuery,
  useLazyGetReferralPayoutRequestsQuery,
  useLazyGetReferralTransactionsQuery,
  useLazyGetReferralsDetailsQuery,
  useLazyGetReferredUsersQuery,

  /** Mutations */
  useEnrollReferralsMutation,
  useReferralsPayoutMutation,
  useUpsertBankDetailsMutation,
  // #endregion Referrals

  // #region VPN
  /** Queries */
  useGetVPNAdminDetailsQuery,
  useGetVPNChangelogQuery,
  useGetVPNEventsQuery,
  useGetVPNLocationsQuery,
  useGetVPNPortsQuery,
  useGetVPNQuery,

  /** Lazy Queries */
  useLazyGetVPNAdminDetailsQuery,
  useLazyGetVPNChangelogQuery,
  useLazyGetVPNEventsQuery,
  useLazyGetVPNLocationsQuery,
  useLazyGetVPNPortsQuery,
  useLazyGetVPNQuery,

  /** Mutations */
  useCancelVPNMutation,
  useDisableVPNAutoExtendMutation,
  useDownloadOpenVPNConfigurationMutation,
  useDownloadWireGuardVPNConfigurationMutation,
  useEnableVPNAutoExtendMutation,
  useExpireVPNMutation,
  useExtendVPNPeriodMutation,
  useReactivateVPNMutation,
  // #endregion VPN

  // @deprecated

  // Marketplace
  useGetMarketplaceDealsQuery,
  useGetMarketplaceAvailableCountriesQuery,

  // Order
  useGetOrderConfigurationMutation,
  useGetOrderPriceMutation,
  useExecuteOrderMutation,
  useGetOrderQuery,
} = api;
