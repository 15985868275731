import MuiCollapse, { collapseClasses } from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Button, type ButtonProps } from 'components/Button';

const QRImage = styled('img')({
  width: 200,
  height: 200,
});

const LinkButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,

  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const Collapse = styled(MuiCollapse)(({ theme }) => ({
  [`& .${collapseClasses.wrapperInner}`]: {
    display: 'flex',
    flexDirection: 'column',

    span: {
      inlineSize: 320,
      overflowWrap: 'break-word',
    },

    'span + span': {
      marginTop: theme.spacing(1),
    },
  },
}));

const RemoveButton = styled((props: ButtonProps) => (
  <Button size="medium" variant="text" color="error" iconOnly {...props} />
))({
  padding: 0,
});

export default { QRImage, LinkButton, Collapse, RemoveButton };
