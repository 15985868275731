import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { useRouter } from 'hooks/useRouter';
import { paths } from 'paths';
import { useGetIsProxyReplacementAvailableQuery } from 'store/api';

type ReplaceableProxiesGuardProps = {
  children: ReactNode;
};

export function ReplaceableProxiesGuard({ children }: ReplaceableProxiesGuardProps) {
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  const { data: isProxyReplacementAvailable, isLoading } = useGetIsProxyReplacementAvailableQuery();

  const check = useCallback(() => {
    if (isLoading) return;

    if (!isProxyReplacementAvailable) {
      setChecked(true);

      return router.replace({ pathname: paths.index });
    }

    setChecked(true);
  }, [isLoading, isProxyReplacementAvailable, router]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) return null;

  return children;
}
