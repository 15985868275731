import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Banner } from 'components/Banner';
import { Button } from 'components/Button';
import { RouterLink } from 'components/RouterLink';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { paths } from 'paths';
import { VerificationStatus } from 'store/auth/models';

type IdentityVerificationBannerProps = {
  horizontalLayout?: boolean;
};

export function IdentityVerificationBanner({ horizontalLayout }: IdentityVerificationBannerProps) {
  const { t } = useTranslation();

  const { verificationRequest } = useIdentityVerificationStatus();

  const hasFailed = useMemo(() => !!verificationRequest?.reason, [verificationRequest]);

  const actions = useMemo(() => {
    return [
      <Button fullWidth component={RouterLink} href={paths.account.identityVerification}>
        {t('common:buttons.completeVerification')}
      </Button>,
    ];
  }, [t]);

  const { subtitle, title } = useMemo(() => {
    if (verificationRequest?.status === VerificationStatus.FAILED) {
      return {
        title: t('common:idVerification.failed.title'),
        subtitle: t('common:idVerification.failed.subtitle'),
      };
    }

    return {
      title: t('common:idVerification.title'),
      subtitle: t('common:idVerification.subtitle'),
    };
  }, [t, verificationRequest?.status]);

  return (
    <Banner
      title={title}
      subtitle={subtitle}
      actions={actions}
      isVisible
      slotProps={{
        container: { gridTemplateColumns: { xs: '1fr', lg: horizontalLayout ? 'repeat(2, auto)' : '1fr' } },
        content: { spacing: { xs: 1, md: horizontalLayout ? 0.5 : 1 } },
        title: { color: hasFailed ? 'var(--mui-palette-error-main)' : 'var(--mui-palette-text-primary)' },
      }}
      sx={{
        '--Card-boxShadow': 'none',
        '--Card-background': hasFailed ? 'var(--mui-palette-error-50)' : 'var(--mui-palette-warning-300)',
        '--Card-paddingBlock': { xs: '16px', md: horizontalLayout ? '32px' : '16px' },
        '--Card-paddingInline': { xs: '16px', md: horizontalLayout ? '32px' : '16px' },
      }}
    />
  );
}
