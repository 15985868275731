import type { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: '"Inter"',
  hero: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '54px',
    lineHeight: '72px',
    letterSpacing: '-0.02em',
  },
  'hero-2': {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '54px',
    fontWeight: 700,
    lineHeight: '72px',
    letterSpacing: '-0.02em',
  },
  'headline-1': { fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '32px', lineHeight: '44px' },
  'headline-2': { fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: '20px', lineHeight: '32px' },
  'paragraph-1': { fontSize: '32px', fontWeight: 400, lineHeight: '44px' },
  'paragraph-2': { fontSize: '20px', fontWeight: 400, lineHeight: '32px' },
  title: { fontSize: '16px', fontWeight: 700, lineHeight: '24px' },
  subtitle: { fontSize: '14px', fontWeight: 700, lineHeight: '20px' },
  'body-1': { fontSize: '16px', fontWeight: 400, lineHeight: '24px' },
  'body-2': { fontSize: '14px', fontWeight: 400, lineHeight: '20px' },
  caption: { fontSize: '12px', fontWeight: 400, lineHeight: '16px', letterSpacing: '0.04em' },
  badge: { fontSize: '10px', fontWeight: 500, lineHeight: '14px', letterSpacing: '0.08em', textTransform: 'uppercase' },
};
