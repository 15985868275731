import { useCallback, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useSession } from 'hooks/useSession';
import { getOrderStep } from 'modules/orders/helpers';
import { useOrderFlow } from 'modules/orders/hooks';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { resetOrderFlow, setCurrentStep } from 'store/orders/reducers';

import type { OrderNavigationItemProps } from './OrderNavigationItem';

export function useOrderNavigationItems() {
  const { pathname } = useLocation();
  const { isMarketplaceFlow, isVPNFlow } = useOrderFlow();
  const dispatch = useAppDispatch();

  const { isProfileDetailsValid, isSessionLoading } = useSession();

  /**
   * The initial set of order navigation items
   */
  const items = useMemo(() => {
    return [
      { title: 'common:order.steps.type', href: paths.order.index },
      { title: 'common:order.steps.setup', href: paths.order.proxy },
      { title: 'common:order.steps.vpnPlan', href: paths.order.vpn },
      { title: 'common:order.steps.details', href: paths.order.details },
      { title: 'common:order.steps.checkout', href: paths.order.checkout },
    ];
  }, []);

  /**
   * Checks if the order navigation item is clickable
   *
   * User can only go back - so any further navigation item should be disabled
   */
  const isItemEnabled = useCallback(
    (href: string) => {
      const orderPaths = items.map((p) => p.href);

      const currentPathIndex = orderPaths.findIndex((p) => p === pathname);

      return orderPaths.slice(0, currentPathIndex).includes(href);
    },
    [items, pathname],
  );

  /**
   * Handles navigation between order flow steps
   *
   * Resets the order flow once user clicks on the Proxy Type tab
   */
  const onClick = useCallback(
    (href: string) => {
      const step = getOrderStep(href);

      // Reset order flow for any other order step
      if (!step) dispatch(resetOrderFlow({ shouldResetCouponCode: false }));

      dispatch(setCurrentStep(step));
    },
    [dispatch],
  );

  return useMemo<OrderNavigationItemProps[]>(() => {
    return (
      items
        // by flow
        .filter(({ href }) =>
          isVPNFlow ? ![paths.order.index, paths.order.proxy].includes(href) : href !== paths.order.vpn,
        )
        .filter(({ href }) => (isMarketplaceFlow ? ![paths.order.index].includes(href) : true))
        // by profile details
        .filter(({ href }) => href !== paths.order.details || (!isProfileDetailsValid && !isSessionLoading))
        .map((item) => ({ ...item, disabled: !isItemEnabled(item.href), onClick: () => onClick(item.href) }))
    );
  }, [isItemEnabled, isMarketplaceFlow, isProfileDetailsValid, isSessionLoading, isVPNFlow, items, onClick]);
}
