import type { FocusEvent } from 'react';

import MuiSwitch, { type SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import { Field, type FieldProps } from 'formik';

type SwitchProps<Value extends boolean, Values> = FieldProps<Value, Values> &
  Omit<MuiSwitchProps, 'value' | 'defaultChecked' | 'form' | 'type'> & {
    type?: string;
  };

function fieldToSwitch<Value extends boolean, Values>({
  field: { onBlur: fieldOnBlur, ...field },
  form,
  type,
  onBlur,
  ...props
}: SwitchProps<Value, Values>) {
  return {
    onBlur: (e: FocusEvent<HTMLButtonElement>) => {
      if (onBlur) {
        return onBlur(e);
      }

      return fieldOnBlur(e ?? field.name);
    },
    checked: field.value,
    ...field,
    ...props,
  };
}

function Switch<Value extends boolean, Values>(props: SwitchProps<Value, Values>) {
  return <MuiSwitch {...fieldToSwitch(props)} />;
}

export type FormikSwitchProps<Value extends boolean, Values> = Omit<
  SwitchProps<Value, Values>,
  'form' | 'meta' | 'field'
>;

export function FormikSwitch<Value extends boolean, Values>(props: FormikSwitchProps<Value, Values>) {
  return <Field component={Switch} {...props} />;
}
