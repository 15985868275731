import type { Components, Theme } from '@mui/material/styles';

export const MuiSvgIcon: Components<Theme>['MuiSvgIcon'] = {
  styleOverrides: {
    root: {
      '--SvgIcon-height': 'auto',
      '--SvgIcon-width': '100%',
      '--SvgIcon-fill': 'inherit',
      '--SvgIcon-stroke': 'currentcolor',

      fill: 'var(--SvgIcon-fill)',
      stroke: 'var(--SvgIcon-stroke)',

      display: 'block',
      height: 'var(--SvgIcon-height)',
      width: 'var(--SvgIcon-width)',
    },
  },
};
