import { useCallback, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { PaymentElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { useStripeContext } from 'modules/billing/Stripe';
import { paths } from 'paths';
import { useLazyGetBillingPreferencesQuery, useLazyGetCreditCardQuery } from 'store/api';
import { ToastManager } from 'utils/toast';

export function AddCreditCardForm() {
  const { t } = useTranslation();
  const { error, handleSetup, handleSubmit } = useStripeContext();
  const hideModal = useHideModal();

  const [isLoading, setLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);

  const [getBillingPreferences] = useLazyGetBillingPreferencesQuery();
  const [getCreditCard] = useLazyGetCreditCardQuery();

  const onSuccess = useCallback(async () => {
    await getBillingPreferences();
    await getCreditCard();

    setLoading(false);
    ToastManager.success(t('account.modals.card.success'));

    return hideModal();
  }, [getBillingPreferences, getCreditCard, hideModal, t]);

  const onFailure = useCallback(() => setLoading(false), []);

  const onStart = useCallback(() => setLoading(true), []);

  return (
    <form
      onSubmit={(event) =>
        handleSubmit({ event, onFailure, onStart, onSuccess: () => handleSetup({ onSuccess, onFailure }) })
      }
    >
      <Grid container direction="column" rowSpacing={4}>
        <Grid item>
          <PaymentElement onReady={() => setIsFormReady(true)} />
        </Grid>

        {error && (
          <Grid item component={Typography} variant="body-2" color="error.main">
            {error.message}
          </Grid>
        )}

        <Grid item>
          <Grid container columnSpacing={2}>
            <Grid xs={6} item>
              <Button fullWidth color="secondary" onClick={hideModal}>
                {t('common:buttons.close')}
              </Button>
            </Grid>

            <Grid xs={6} item>
              <Button fullWidth type="submit" loading={isLoading} disabled={!isFormReady}>
                {t('common:buttons.continue')}
              </Button>
            </Grid>
          </Grid>

          <Grid container direction="column" rowGap={2} justifyContent="center" alignItems="center" mt={2}>
            <Grid item component={Typography} variant="caption" color="text.secondary">
              {t('order.checkout.trust')}
            </Grid>

            <Grid item component={Typography} variant="caption" color="text.secondary">
              <Trans i18nKey="order.checkout.stripe">
                All payment methods are safe and processed by
                <Link to={paths.external.stripe} target="_blank">
                  Stripe
                </Link>
              </Trans>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
