import type { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';

import type { PaymentMetadata } from 'modules/billing/types';
import type { Nullable } from 'types';

import type { TopUpStatus, TransactionType } from './types';

// #region Common
export type Currency = 'eur' | 'cny' | 'pln' | 'usd';

export enum Language {
  CN = 'zh',
  EN = 'en',
  ES = 'es',
  RU = 'ru',
  VI = 'vi',
}
// #endregion Common

/**
 * @GET /account/balance
 */
export type AccountBalanceModel = {
  balance: number;
};

/**
 * @GET /account/profile
 */
export type AccountProfileModel = {
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  phone: Nullable<string>;
  address1: Nullable<string>;
  address2: Nullable<string>;
  city: Nullable<string>;
  countryCode: Nullable<string>;
  postcode: Nullable<string>;
  companyName: Nullable<string>;
  vatCode: Nullable<string>;
};

/**
 * @POST /account/billing/card/authorize
 */
export type AddCreditCardModel = {
  clientSecret: string;
};

/**
 * @GET /account/api-keys
 */
export type APIKeyModel = {
  id: string;
  apiKey: string;
  apiSecret: string;
  createdAt: string;
};

/**
 * @GET /account/billing/preferences
 */
export type BillingPreferencesModel = {
  isAutoTopUpEnabled: boolean;
  autoTopUpValue: number;
  autoTopUpThreshold: number;
};

/**
 * @GET /account/billing/card-details
 */
export type CreditCardModel = {
  paymentMethodId: string;
  addedAt: string;
  brand: string;
  expMonth: number;
  expYear: number;
  lastFour: string;
};

/**
 * @POST /account/2fa/webauthn
 */
export type EnableHardwareTwoFactorModel = PublicKeyCredentialCreationOptionsJSON;

/**
 * @POST /account/2fa/totp
 */
export type EnableSoftwareTwoFactorModel = {
  message: string;
  secret: string;
  dataUri: string;
};

/**
 * @GET /account/identity/token
 */
export type IdentityVerificationTokenModel = {
  token: string;
};

/**
 * @GET /account/billing/invoices
 */
export type InvoiceModel = {
  id: number;
  createdAt: string;
  invoiceNumber: string;
  amount: number;
  url: string;
};

/**
 * @GET /account/communication/preferences
 */
export type NotificationPreferencesModel = {
  isEmailCommunicationEnabled: boolean;
};

/**
 * @POST /account/billing/top-up
 */
export type TopUpModel = PaymentMetadata & {
  id: string;
};

/**
 * @POST /account/billing/top-up/fees
 */
export type TopUpPaymentFeeModel = {
  finalPrice: number;
  finalPriceInCurrency: number;
  currency: Currency;
  paymentFee: number;
  subtotal: number;
};

/**
 * @GET /account/billing/top-up/{id}
 */
export type TopUpStatusModel = {
  status: TopUpStatus;
};

/**
 * @GET /account/billing/transactions
 */
export type TransactionModel = {
  id: string;
  description: string;
  amount: number;
  type: TransactionType;
  createdAt: string;
  paymentFee: number;
  subtotal: number;
};

/**
 * @GET /account/2fa/authentication-methods
 */
export type TwoFactorAuthenticationMethodsModel = {
  isHardwareTwoFactorEnabled: boolean;
  isSoftwareTwoFactorEnabled: boolean;
};
