/* eslint-disable react/no-array-index-key */
import { type ReactNode, useCallback } from 'react';

import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import isEqual from 'lodash/isEqual';

import type { Nullable, Option, Optional } from 'types';

import { Button } from './Button';

type SelectionBoxProps<T, Parameters extends Optional<object> = undefined> = {
  data: Array<Option<T, Parameters>>;
  value: Nullable<T>;

  size?: 'small' | 'medium';

  onItemClick: (option: Option<T, Parameters>) => void;
  getOptionLabel: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;
  getOptionSubLabel?: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;
};

export function SelectionBox<T, Parameters extends Optional<object> = undefined>({
  data,
  value,

  size = 'medium',

  onItemClick,
  getOptionLabel,
  getOptionSubLabel,
}: SelectionBoxProps<T, Parameters>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isSelected = useCallback((option: Option<T, Parameters>) => isEqual(option.value, value), [value]);

  const renderLabel = useCallback(
    (option: Option<T, Parameters>) => {
      const renderedValue = getOptionLabel(option, isSelected(option));

      if (typeof renderedValue === 'string') {
        return (
          <Typography variant="subtitle" color={isSelected(option) ? 'text.primary' : 'var(--mui-palette-neutral-500)'}>
            {renderedValue}
          </Typography>
        );
      }

      return renderedValue;
    },
    [getOptionLabel, isSelected],
  );

  const renderSubLabel = useCallback(
    (option: Option<T, Parameters>) => {
      if (getOptionSubLabel) return getOptionSubLabel(option, isSelected(option));

      if (!option.subLabel) return null;

      return (
        <Typography variant="caption" color="var(--mui-palette-neutral-500)">
          {option.subLabel}
        </Typography>
      );
    },
    [getOptionSubLabel, isSelected],
  );

  const handleChange = useCallback(
    (item: Option<T, Parameters>) => {
      if (isSelected(item)) return;

      return onItemClick(item);
    },
    [isSelected, onItemClick],
  );

  return (
    <Grid container spacing={2} flexWrap={size === 'small' ? 'nowrap' : undefined}>
      {data.map((option, i) => (
        <Grid
          key={`selection-box-option-${option.label}-${i}`}
          size={{ xs: isMobile ? 6 : 4 }}
          sx={{ button: { '--Button-paddingBlock': '8px', '--Button-paddingInline': '8px' } }}
        >
          <Button fullWidth isSelected={isSelected(option)} size={size} onClick={() => handleChange(option)}>
            {renderLabel(option)}

            {renderSubLabel(option)}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
