import { useMemo } from 'react';

import { useGetVPNQuery } from 'store/api';
import { VPNStatus } from 'store/vpns/types';
import { isAdminUser } from 'utils/user';

import { useFeatureFlag } from './useFeatureFlag';

export function useVPN() {
  const { data: vpn, isLoading: isVPNLoading, ...query } = useGetVPNQuery();
  const { isLegacyVpnSupportEnabled } = useFeatureFlag();

  const shouldDisplayVPN = useMemo(() => {
    if (isVPNLoading) return false;

    if (!vpn) return false;

    return vpn.status === VPNStatus.ACTIVE && (isLegacyVpnSupportEnabled || isAdminUser());
  }, [isLegacyVpnSupportEnabled, isVPNLoading, vpn]);

  return useMemo(() => {
    return { ...query, vpn, isVPNLoading, shouldDisplayVPN };
  }, [isVPNLoading, query, shouldDisplayVPN, vpn]);
}
