import { useCallback, useRef } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useFreshChatAPI } from './useFreshchatAPI';

export function useListenFreshChatClose() {
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const closureIsMobile = useRef(isMobileDevice);
  closureIsMobile.current = isMobileDevice;

  const { hideFreshChat } = useFreshChatAPI();

  const listenFreshChatClose = useCallback(() => {
    window.fcWidget?.on('widget:closed', () => {
      // hide on close for mobile devices
      if (closureIsMobile.current) {
        hideFreshChat();
      }
    });
  }, [hideFreshChat]);

  return listenFreshChatClose;
}
