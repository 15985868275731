import { Component } from 'react';

import type { ModalStateType, ModalManagerRenderFunctionType } from './types';

interface ModalHostProps<S extends ModalStateType = ModalStateType> {
  renderFn: ModalManagerRenderFunctionType<S>;
}

export default class ModalHost<S extends ModalStateType = ModalStateType> extends Component<
  ModalHostProps<S>,
  ModalStateType<S['id'], S['params']>
> {
  constructor(props: ModalHostProps<S>) {
    super(props);

    this.state = {};
  }

  render() {
    const { renderFn } = this.props;

    return renderFn(this.state as S);
  }
}
