import type { Components, Theme } from '@mui/material/styles';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  defaultProps: { closeAfterTransition: false },
  styleOverrides: {
    root: {
      '--Menu-paper-borderRadius': '8px',
      '--Menu-paper-boxShadow': 'var(--mui-shadows-4)',
      '--Menu-paper-marginBlock': '8px',
      '--Menu-paper-marginInline': 0,

      '--Menu-list-paddingBlock': 0,
      '--Menu-list-paddingInline': 0,
    },

    paper: {
      borderRadius: 'var(--Menu-paper-borderRadius)',
      boxShadow: 'var(--Menu-paper-boxShadow)',
      marginBlock: 'var(--Menu-paper-marginBlock)',
      marginInline: 'var(--Menu-paper-marginInline)',
    },

    list: {
      paddingBlock: 'var(--Menu-list-paddingBlock)',
      paddingInline: 'var(--Menu-list-paddingInline)',
    },
  },
};
