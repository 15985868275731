export const tags = [
  // #region Account
  'Account-APIKey',
  'Account-Balance',
  'Account-Billing-Preferences',
  'Account-CreditCard',
  'Account-IdentityVerification-Token',
  'Account-Invoice',
  'Account-Notification-Preferences',
  'Account-Profile',
  'Account-Top-Up',
  'Account-Transaction',
  'Account-Two-Factor-Authentication-Methods',
  // #endregion Account

  // #region Auth
  'Captcha',
  'Session',
  // #endregion Auth

  // #region Common
  'Features',
  'Experiments',
  'Maintenance-Window',
  // #endregion Common

  // #region Marketplace
  'Marketplace',
  'Marketplace-Country',
  // #endregion Marketplace

  // #region Proxies
  'Proxy',
  'Proxy-Admin-Details',
  'Proxy-Authentication-Type',
  'Proxy-Bulk-Auto-Extend-Settings',
  'Proxy-Bulk-Extend-Bandwidth-Price',
  'Proxy-Bulk-Extend-Period-Price',
  'Proxy-Changelog',
  'Proxy-Change-Threads-Options',
  'Proxy-Change-Uplink-Speed-Options',
  'Proxy-Event',
  'Proxy-Extend-Bandwidth-Price',
  'Proxy-Extend-Period-Price',
  'Proxy-Global-IPWhitelist',
  'Proxy-Hostname',
  'Proxy-IPWhitelist',
  'Proxy-ISP',
  'Proxy-Overview',
  'Proxy-Protocol',
  'Proxy-Reactivate-Price',
  'Proxy-Replacement-Status',
  'Proxy-Subnet',
  'Proxy-Summary',
  'Proxy-Upgrade-Threads-Price',
  'Proxy-Upgrade-Uplink-Speed-Price',
  'Replaceable-Proxy',
  // #endregion Proxies

  // #region Referrals
  'Referrals-Bank-Details',
  'Referrals-Details',
  'Referrals-Payout-Request',
  'Referrals-Transaction',
  'Referrals-User',
  // #endregion Referrals

  // #region VPN
  'VPN',
  'VPN-Admin-Details',
  'VPN-Changelog',
  'VPN-Event',
  'VPN-Location',
  'VPN-Port',
  // #endregion VPN

  // #region @deprecated

  // Proxy order
  'Order-Configuration',

  // #endregion @deprecated
] as const;
