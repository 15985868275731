import type { Components, Theme } from '@mui/material/styles';

export const MuiFab: Components<Theme>['MuiFab'] = {
  defaultProps: { size: 'medium' },
  styleOverrides: {
    root: {
      '--Fab-background': 'var(--mui-palette-background-paper)',
      '--Fab-boxShadow': 'var(--mui-shadows-4)',
      '--Fab-bottom': '16px',
      '--Fab-left': '16px',
      '--Fab-zIndex': 1199,

      background: 'var(--Fab-background)',
      boxShadow: 'var(--Fab-boxShadow)',

      position: 'fixed',
      bottom: 'var(--Fab-bottom)',
      left: 'var(--Fab-left)',

      zIndex: 'var(--Fab-zIndex)',
    },
  },
};
