import { type ReactNode, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Flag } from 'components/Flag';
import { FormikAutocomplete, FormikInput } from 'components/form-partials/formik';
import { Icon } from 'components/Icon';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { COUNTRIES } from 'constants/countries';
import { useAppSelector } from 'store';
import type { Nullable } from 'types';

export type CountryRequestValues = {
  codes: Nullable<string>;
  comment?: string;
};

export type CountryRequestStep = 'form' | 'success';

type CountryRequestFormProps = {
  step: CountryRequestStep;
};

type Variant = {
  title: string;
  subtitle: string;
  icon?: ReactNode;
  actions: ReactNode[];
};

export function CountryRequestForm({ step }: CountryRequestFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, handleSubmit } = useFormikContext<CountryRequestValues>();

  const { proxyFieldsConfigurations } = useAppSelector((state) => state.order);

  const options = useMemo(() => {
    if (!proxyFieldsConfigurations) return COUNTRIES;

    return COUNTRIES.filter(({ value }) => {
      const countryOptions = proxyFieldsConfigurations.country;

      if (!countryOptions) return true;

      return countryOptions.supportedCountries.every((supportedCountry) => supportedCountry.value !== value);
    });
  }, [proxyFieldsConfigurations]);

  const { title, subtitle, actions, icon } = useMemo<Variant>(() => {
    if (step === 'success') {
      return {
        title: t('order.modals.countryRequest.success.title'),
        subtitle: t('order.modals.countryRequest.success.subtitle'),
        icon: <Icon name="tick-circle" color="success" size={40} />,
        actions: [
          <Button key="cancel" fullWidth onClick={hideModal}>
            {t('common:buttons.close')}
          </Button>,
        ],
      };
    }

    return {
      title: t('order.modals.countryRequest.title'),
      subtitle: t('order.modals.countryRequest.subtitle'),
      actions: [
        <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
          {t('common:buttons.cancel')}
        </Button>,
        <Button key="request" type="submit" loading={isSubmitting} fullWidth disabled={!isValid}>
          {t('order.modals.countryRequest.action')}
        </Button>,
      ],
    };
  }, [hideModal, isSubmitting, isValid, step, t]);

  return (
    <ModalContainer component="form" containerProps={{ onSubmit: handleSubmit, noValidate: true }}>
      <Stack spacing={4}>
        <Stack spacing={1} alignItems="center" textAlign="center">
          {icon}

          <Typography variant="headline-1">{title}</Typography>

          <Typography variant="body-1" color="neutral.500">
            {subtitle}
          </Typography>
        </Stack>

        <Collapse in={step === 'form'} unmountOnExit>
          <Stack spacing={4}>
            <FormikAutocomplete
              name="codes"
              label={t('common:form.country')}
              placeholder={t('common:form.select')}
              options={options.toSorted((prev, next) => {
                const translatedPrev = t(prev.label);
                const translatedNext = t(next.label);

                return translatedPrev < translatedNext ? -1 : 1;
              })}
              getListItemLeftAdornment={(o) => <Flag size={16} countryCode={o.value} />}
            />

            <FormikInput
              name="comment"
              label={t('common:form.optional', { field: t('common:form.comment') })}
              multiline
              rows={4}
              placeholder={t('order.modals.countryRequest.comment.placeholder')}
            />
          </Stack>
        </Collapse>

        <Stack direction="row" spacing={2} alignItems="center">
          {actions.map((actionItem) => actionItem)}
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
