import { type ReactNode, useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon, type IconProps } from 'components/Icon';
import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useBalance } from 'hooks/useBalance';
import { useSession } from 'hooks/useSession';
import { TopUpStatus } from 'store/accounts/types';

import { useGetTopUpStatus } from './useGetTopUpStatus';
import { useTopUpModalContext } from '../context';

type Variant = {
  isLoader?: boolean;
  icon?: IconProps;
  title?: string;
  subtitle?: string;
  action?: ReactNode;
};

type StatusStepProps = {
  isOrderFlow: boolean;
};

export function StatusStep({ isOrderFlow }: StatusStepProps) {
  const { t } = useTranslation();
  const { topUpId, values, setStep, setValues } = useTopUpModalContext();
  const posthog = usePostHog();
  const hideModal = useHideModal();

  const { session } = useSession();
  const { refetch: getUserBalance } = useBalance();

  const { data } = useGetTopUpStatus(topUpId);

  const handleClose = useCallback(
    (isSuccess = false) => {
      posthog?.capture(posthogEvents[isOrderFlow ? 'orderTopUp' : 'topUp'][isSuccess ? 'success' : 'failure'], {
        ...(isSuccess && { amount: values?.amount.toString() }),
      });

      if (isSuccess) {
        GA_EVENTS.topUpSuccess(values?.amount || 0, session?.userId);
        getUserBalance();
        hideModal();
      } else {
        setStep('top-up-form');
        setValues(null);
      }
    },
    [getUserBalance, hideModal, isOrderFlow, posthog, session?.userId, setStep, setValues, values?.amount],
  );

  const { subtitle, title, action, icon, isLoader } = useMemo<Variant>(() => {
    if (!data || data?.status === TopUpStatus.PENDING) {
      return {
        isLoader: true,
        loader: <CircularProgress size={40} />,
        subtitle: t('account.modals.status.topUp.pending.subtitle'),
      };
    }

    if (data?.status === TopUpStatus.COMPLETED) {
      return {
        icon: { name: 'tick-circle', color: 'success' },
        title: t('account.modals.status.topUp.success.title'),
        subtitle: t('account.modals.status.topUp.success.subtitle'),
        action: (
          <Button fullWidth onClick={() => handleClose(true)}>
            {t('account.modals.status.topUp.success.action')}
          </Button>
        ),
      };
    }

    return {
      icon: { name: 'close-circle', color: 'error' },
      title: t('account.modals.status.topUp.failure.title'),
      subtitle: t('account.modals.status.topUp.failure.subtitle'),
      action: (
        <Button fullWidth onClick={() => handleClose()}>
          {t('account.modals.status.topUp.failure.action')}
        </Button>
      ),
    };
  }, [data, handleClose, t]);

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item>
        <Grid container direction="column" rowSpacing={1.5} alignItems="center">
          <Grid item>
            {icon && <Icon size={40} {...icon} />}
            {isLoader && <CircularProgress size={64} />}
          </Grid>

          {(title || subtitle) && (
            <Grid item>
              <Grid container direction="column" textAlign="center" rowSpacing={1}>
                {title && (
                  <Grid item component={Typography} variant="headline-2">
                    {title}
                  </Grid>
                )}

                {subtitle && (
                  <Grid item component={Typography} variant="body-1" color="var(--mui-palette-neutral-500)">
                    {subtitle}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {action && <Grid item>{action}</Grid>}
    </Grid>
  );
}
