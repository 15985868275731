import { v4 as uuid } from 'uuid';

import type { Nullable, Paginate } from 'types';
import { isPaginatedDTO } from 'utils/guards';
import { lowercaseTypeToEnumKey, uppercaseTypeToEnumKey } from 'utils/string';

import {
  type AccountBalanceDTO,
  type AccountProfileDTO,
  type AddCreditCardDTO,
  type APIKeyDTO,
  type BillingPreferencesDTO,
  type CreditCardDTO,
  type CurrencyDTO,
  type EnableHardwareTwoFactorDTO,
  type EnableSoftwareTwoFactorDTO,
  type IdentityVerificationTokenDTO,
  type InvoiceDTO,
  LanguageDTO,
  type NotificationPreferencesDTO,
  type TopUpDTO,
  type TopUpPaymentFeeDTO,
  type TopUpStatusDTO,
  type TransactionDTO,
  type TwoFactorAuthenticationMethodsDTO,
} from './dtos';
import {
  type AccountBalanceModel,
  type AccountProfileModel,
  type AddCreditCardModel,
  type APIKeyModel,
  type BillingPreferencesModel,
  type CreditCardModel,
  type Currency,
  type EnableHardwareTwoFactorModel,
  type EnableSoftwareTwoFactorModel,
  type IdentityVerificationTokenModel,
  type InvoiceModel,
  Language,
  type NotificationPreferencesModel,
  type TopUpModel,
  type TopUpPaymentFeeModel,
  type TopUpStatusModel,
  type TransactionModel,
  type TwoFactorAuthenticationMethodsModel,
} from './models';

// #region Common
export function toCurrency(from: CurrencyDTO): Currency {
  return lowercaseTypeToEnumKey(from);
}

export function toCurrencyDTO(from: Currency): CurrencyDTO {
  return uppercaseTypeToEnumKey(from);
}

export function toLanguage(from: Nullable<LanguageDTO>): Language {
  if (from === LanguageDTO.CN) return Language.CN;

  if (!from) return Language.EN;

  return lowercaseTypeToEnumKey(from) as Language;
}

export function toLanguageDTO(from: Nullable<Language>): LanguageDTO {
  if (from === Language.CN) return LanguageDTO.CN;

  if (!from) return LanguageDTO.EN;

  return uppercaseTypeToEnumKey(from) as LanguageDTO;
}
// #endregion Common

export function toAccountBalanceModel(from: AccountBalanceDTO): AccountBalanceModel {
  return from;
}

export function toAccountProfileModel(from: AccountProfileDTO): AccountProfileModel {
  return from;
}

export function toAddCreditCardModel({ authToken }: AddCreditCardDTO): AddCreditCardModel {
  return { clientSecret: authToken };
}

export function toApiKeyModel(from: APIKeyDTO): APIKeyModel;
export function toApiKeyModel(from: APIKeyDTO[]): APIKeyModel[];
export function toApiKeyModel(from: Paginate<APIKeyDTO>): Paginate<APIKeyModel>;

export function toApiKeyModel(
  from: APIKeyDTO | APIKeyDTO[] | Paginate<APIKeyDTO>,
): APIKeyModel | APIKeyModel[] | Paginate<APIKeyModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toApiKeyModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toApiKeyModel(item)) };
  }

  return { ...from, id: uuid() };
}

export function toBillingPreferencesModel({ autoTopUp }: BillingPreferencesDTO): BillingPreferencesModel {
  return {
    autoTopUpThreshold: autoTopUp.threshold,
    autoTopUpValue: autoTopUp.value,
    isAutoTopUpEnabled: autoTopUp.enabled,
  };
}

export function toCreditCardModel(from: CreditCardDTO): CreditCardModel {
  return from;
}

export function toEnableHardwareTwoFactorModel(from: EnableHardwareTwoFactorDTO): EnableHardwareTwoFactorModel {
  return from;
}

export function toEnableSoftwareTwoFactorModel(from: EnableSoftwareTwoFactorDTO): EnableSoftwareTwoFactorModel {
  return from;
}

export function toIdentityVerificationTokenModel(from: IdentityVerificationTokenDTO): IdentityVerificationTokenModel {
  return from;
}

export function toInvoiceModel(from: InvoiceDTO): InvoiceModel;
export function toInvoiceModel(from: InvoiceDTO[]): InvoiceModel[];
export function toInvoiceModel(from: Paginate<InvoiceDTO>): Paginate<InvoiceModel>;

export function toInvoiceModel(
  from: InvoiceDTO | InvoiceDTO[] | Paginate<InvoiceDTO>,
): InvoiceModel | InvoiceModel[] | Paginate<InvoiceModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toInvoiceModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toInvoiceModel(item)) };
  }

  return from;
}

export function toNotificationPreferencesModel(from: NotificationPreferencesDTO): NotificationPreferencesModel {
  return from;
}

export function toTopUpModel(from: TopUpDTO): TopUpModel {
  return from;
}

export function toTopUpPaymentFeeModel({ currency, ...from }: TopUpPaymentFeeDTO): TopUpPaymentFeeModel {
  return { ...from, currency: toCurrency(currency) };
}

export function toTopUpStatusModel(from: TopUpStatusDTO): TopUpStatusModel {
  return from;
}

export function toTransactionModel(from: TransactionDTO): TransactionModel;
export function toTransactionModel(from: TransactionDTO[]): TransactionModel[];
export function toTransactionModel(from: Paginate<TransactionDTO>): Paginate<TransactionModel>;

export function toTransactionModel(
  from: TransactionDTO | TransactionDTO[] | Paginate<TransactionDTO>,
): TransactionModel | TransactionModel[] | Paginate<TransactionModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toTransactionModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toTransactionModel(item)) };
  }

  return { ...from, id: uuid() };
}

export function toTwoFactorAuthenticationMethodsModel({
  totp,
  webauthn,
}: TwoFactorAuthenticationMethodsDTO): TwoFactorAuthenticationMethodsModel {
  return {
    isHardwareTwoFactorEnabled: webauthn,
    isSoftwareTwoFactorEnabled: totp,
  };
}
