import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import type { Components, Theme } from '@mui/material/styles';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: { color: 'primary' },
  styleOverrides: {
    root: {
      '--Input-borderColor': 'rgba(var(--mui-palette-text-primaryChannel) / 0.12)',
      '--Input-borderWidth': '1px',

      '--Input-hover-borderColor': 'rgba(var(--mui-palette-primary-mainChannel) / 0.24)',
      '--Input-backgroundColor': 'var(--mui-palette-background-paper)',

      backgroundColor: 'var(--Input-backgroundColor)',
      paddingRight: 'var(--Input-paddingInline)',
      paddingLeft: 'var(--Input-paddingInline)',

      [`&.${outlinedInputClasses.error}`]: {
        '--Input-borderColor': 'rgba(var(--mui-palette-error-mainChannel) / 0.24)',
      },

      [`&.${outlinedInputClasses.focused}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderWidth: 'var(--Input-borderWidth)',
        },
      },

      [`&:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.focused}):not(.${outlinedInputClasses.error}):hover`]:
        {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--Input-hover-borderColor)',
          },
        },

      'label + &': { marginTop: 8 },
    },

    input: {
      padding: 0,
      height: 'auto',

      [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
        '&::placeholder': { opacity: '1 !important' },
      },

      '&:-webkit-autofill': {
        marginInline: 'calc(var(--Input-paddingInline) * -1)',
        paddingInline: 'var(--Input-paddingInline)',
        boxShadow: 'none',
        WebkitTextFillColor: 'var(--mui-palette-text-primary)',
        caretColor: 'var(--mui-palette-common-black)',
      },
    },

    notchedOutline: ({ theme }) => ({
      transition: theme.transitions.create(['border-color', 'box-shadow']),

      borderColor: 'var(--Input-borderColor)',
      borderWidth: 'var(--Input-borderWidth)',
    }),

    inputAdornedEnd: {
      paddingRight: 'var(--Input-adorned-end-padding)',
    },

    inputAdornedStart: {
      paddingLeft: 'var(--Input-adorned-start-padding)',
    },
  },
};
