import { inputBaseClasses } from '@mui/material/InputBase';
import type { Components, Theme } from '@mui/material/styles';

export const MuiInputAdornment: Components<Theme>['MuiInputAdornment'] = {
  styleOverrides: {
    root: {
      '--InputAdornment-color': 'var(--mui-palette-text-secondary)',

      display: 'inline-flex',
      margin: 0,
      color: 'var(--InputAdornment-color)',

      [`&.${inputBaseClasses.disabled} &`]: {
        '--InputAdornment-color': 'var(--mui-palette-text-disabled)',
      },
    },
  },
};
