import type { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';

import type { PaymentMetadata } from 'modules/billing/types';
import type { Nullable } from 'types';

import type { TopUpStatus, TransactionType } from './types';

// #region Common
export type CurrencyDTO = 'EUR' | 'CNY' | 'PLN' | 'USD';

export enum LanguageDTO {
  CN = 'CN',
  EN = 'EN',
  ES = 'ES',
  RU = 'RU',
  VI = 'VI',
}
// #endregion Common

/**
 * @GET /account/balance
 */
export type AccountBalanceDTO = {
  balance: number;
};

/**
 * @GET /account/profile
 */
export type AccountProfileDTO = {
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  phone: Nullable<string>;
  address1: Nullable<string>;
  address2: Nullable<string>;
  city: Nullable<string>;
  countryCode: Nullable<string>;
  postcode: Nullable<string>;
  companyName: Nullable<string>;
  vatCode: Nullable<string>;
};

/**
 * @POST /account/billing/card/authorize
 */
export type AddCreditCardDTO = {
  authKey: string;
  authToken: string;
};

/**
 * @GET /account/api-keys
 */
export type APIKeyDTO = {
  apiKey: string;
  apiSecret: string;
  createdAt: string;
};

/**
 * @GET /account/billing/preferences
 */
export type BillingPreferencesDTO = {
  autoTopUp: {
    enabled: boolean;
    value: number;
    threshold: number;
  };
};

/**
 * @GET /account/billing/card-details
 */
export type CreditCardDTO = {
  paymentMethodId: string;
  addedAt: string;
  brand: string;
  expMonth: number;
  expYear: number;
  lastFour: string;
};

/**
 * @POST /account/2fa/webauthn
 */
export type EnableHardwareTwoFactorDTO = PublicKeyCredentialCreationOptionsJSON;

/**
 * @POST /account/2fa/totp
 */
export type EnableSoftwareTwoFactorDTO = {
  message: string;
  secret: string;
  dataUri: string;
};

/**
 * @GET /account/identity/token
 */
export type IdentityVerificationTokenDTO = {
  token: string;
};

/**
 * @GET /account/billing/invoices
 */
export type InvoiceDTO = {
  id: number;
  createdAt: string;
  invoiceNumber: string;
  amount: number;
  url: string;
};

/**
 * @GET /account/communication/preferences
 */
export type NotificationPreferencesDTO = {
  isEmailCommunicationEnabled: boolean;
};

/**
 * @POST /account/billing/top-up
 */
export type TopUpDTO = PaymentMetadata & {
  id: string;
};

/**
 * @POST /account/billing/top-up/fees
 */
export type TopUpPaymentFeeDTO = {
  finalPrice: number;
  finalPriceInCurrency: number;
  currency: CurrencyDTO;
  paymentFee: number;
  subtotal: number;
};

/**
 * @GET /account/billing/top-up/{id}
 */
export type TopUpStatusDTO = {
  status: TopUpStatus;
};

/**
 * @GET /account/billing/transactions
 */
export type TransactionDTO = {
  description: string;
  amount: number;
  type: TransactionType;
  createdAt: string;
  paymentFee: number;
  subtotal: number;
};

/**
 * @GET /account/2fa/authentication-methods
 */
export type TwoFactorAuthenticationMethodsDTO = {
  totp: boolean;
  webauthn: boolean;
};
