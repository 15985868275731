import { type ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import type { Nullable } from 'types';

import { Canny } from './Canny';
import { CannyLoader } from './CannyLoader';

type TCannyContext = {
  appID?: string;
  isLoaded: boolean;
  canny: Canny;
};

const CannyContext = createContext<TCannyContext | undefined>(undefined);

type CannyProviderProps = {
  children: ReactNode;
};

export function CannyProvider({ children }: CannyProviderProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const refCanny = useRef<Nullable<typeof window.Canny>>(null);

  const appID = useMemo(() => process.env.REACT_APP_CANNY_APP_ID, []);

  const canny = useMemo(() => {
    return new Canny(refCanny.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (!appID) return;

    (async () => {
      const loader = new CannyLoader();

      try {
        refCanny.current = await loader.load();

        setIsLoaded(true);
      } catch {
        /**
         * Probably do nothing - to verify
         */
        // console.error(err);
      }
    })();
  }, [appID]);

  const value = useMemo<TCannyContext>(() => {
    return { appID, canny, isLoaded };
  }, [appID, canny, isLoaded]);

  return <CannyContext.Provider value={value}>{children}</CannyContext.Provider>;
}

export function useCannyContext() {
  const context = useContext(CannyContext);

  if (context === undefined) {
    throw new Error('Cannot use "useCannyContext" outside the "<CannyProvider />" context provider');
  }

  return context;
}
