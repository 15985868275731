import { forwardRef, useMemo, type ReactNode } from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import Card, { type CardProps as MuiCardProps } from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import type Grow from '@mui/material/Grow';
import Stack, { type StackProps } from '@mui/material/Stack';
import Typography, { type TypographyProps } from '@mui/material/Typography';

import { Icon, isIconName, type IconName, type IconProps } from 'components/Icon';
import type { LiteralNodeUnion } from 'types';

type BannerSlotProps = {
  title?: Partial<TypographyProps>;
  subtitle?: Partial<TypographyProps>;
  icon?: Partial<IconProps>;
  container?: Partial<BoxProps>;
  content?: Partial<StackProps>;
  contentWrapper?: Partial<StackProps>;
};

type BannerProps = MuiCardProps & {
  icon?: LiteralNodeUnion<IconName>;
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode[];
  actionsReversed?: boolean;

  TransitionComponent?: typeof Collapse | typeof Grow;

  isVisible?: boolean;
  slotProps?: BannerSlotProps;
};

export const Banner = forwardRef<HTMLDivElement, BannerProps>(
  (
    {
      title,
      subtitle,
      actions = [],
      icon,
      isVisible,
      actionsReversed = false,
      TransitionComponent = Collapse,
      slotProps,
      ...props
    },
    ref,
  ) => {
    const Title = useMemo(() => {
      if (typeof title === 'string') {
        return (
          <Typography variant="title" {...slotProps?.title}>
            {title}
          </Typography>
        );
      }

      return title;
    }, [slotProps?.title, title]);

    const Subtitle = useMemo(() => {
      if (typeof subtitle === 'string') {
        return (
          <Typography variant="body-2" whiteSpace="pre-line" {...slotProps?.subtitle}>
            {subtitle}
          </Typography>
        );
      }

      return subtitle;
    }, [slotProps?.subtitle, subtitle]);

    const Adornment = useMemo(() => {
      if (!icon) return null;

      if (isIconName(icon)) {
        return <Icon name={icon} {...slotProps?.icon} />;
      }

      return icon;
    }, [icon, slotProps?.icon]);

    return (
      <TransitionComponent in={isVisible} unmountOnExit>
        <Card
          ref={ref}
          sx={{
            '--Card-bxoShadow': 'none',
            '--Card-paddingBlock': '32px',
            '--Card-paddingInline': '32px',
            '--Card-background': 'var(--mui-palette-neutral-100)',
          }}
          {...props}
        >
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2, auto)' }}
            gap={4}
            {...slotProps?.container}
          >
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              spacing={2}
              alignItems={{ lg: 'center' }}
              flex="1 1 auto"
              {...slotProps?.contentWrapper}
            >
              {Adornment}

              {(title || subtitle) && (
                <Stack alignItems={{ xs: 'center', lg: 'flex-start' }} {...slotProps?.content}>
                  {Title}

                  {Subtitle}
                </Stack>
              )}
            </Stack>

            {actions.length > 0 && (
              <Stack
                direction={{ xs: actionsReversed ? 'column-reverse' : 'column', lg: 'row' }}
                flex="1 1 auto"
                spacing={2}
                alignItems={{ lg: 'center' }}
                justifyContent={{ lg: 'flex-end' }}
              >
                {actions.map((actionItem) => actionItem)}
              </Stack>
            )}
          </Box>
        </Card>
      </TransitionComponent>
    );
  },
);
