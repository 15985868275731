import type { Components, Theme } from '@mui/material/styles';

export const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: {
      color: 'inherit',
      fontSize: 'var(--icon-fontSize-md)',
      minWidth: 'auto',
    },
  },
};
