import type { Components, Theme } from '@mui/material';

export const MuiModal: Components<Theme>['MuiModal'] = {
  styleOverrides: {
    root: {
      '--ModalContainer-background': 'var(--mui-palette-background-paper)',
      '--ModalContainer-radius': '32px',
      '--ModalContainer-paddingBlock': '32px',
      '--ModalContainer-paddingInline': '32px',
      '--ModalContainer-maxHeight': '80%',
      '--ModalContainer-maxWidth': '420px',
      '--ModalContainer-boxShadow': 'var(--mui-shadows-9)',

      '--ModalContent-maxHeight': '100%',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    backdrop: {
      backgroundColor: 'rgba(16, 26, 51, 0.48)',
    },
  },
};
