/* eslint-disable @typescript-eslint/promise-function-async */
import { PersistService } from './persist';

type Options = {
  retries?: number;
  interval?: number;
  exponentialBackOff?: boolean;
};

export function retry<T>(
  fn: () => Promise<T>,
  { retries = 3, interval = 500, exponentialBackOff = true }: Options = {},
) {
  return new Promise<T>((resolve, reject) => {
    const hasRefreshed = PersistService.get<boolean>('retry-lazy-refreshed') || false;

    fn()
      .then((component) => {
        PersistService.set('retry-lazy-refreshed', false);

        return resolve(component);
      })
      .catch(() => {
        setTimeout(() => {
          if (retries === 1 && !hasRefreshed) {
            PersistService.set('retry-lazy-refreshed', true);

            return window.location.reload();
          }

          retry(fn, { retries: retries - 1, interval: exponentialBackOff ? interval * 2 : interval }).then(
            resolve,
            reject,
          );
        }, interval);
      });
  });
}
