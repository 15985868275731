import { useCallback, useMemo } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useGetProxyIPWhitelistQuery, useUpdateIPWhitelistMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { UpdateIPWhitelistPayload } from 'store/proxies/payloads';
import { getValidationErrors } from 'utils/error';
import type { FormErrors } from 'utils/form';
import { ToastManager } from 'utils/toast';

import { IPWhitelistForm } from './IPWhitelistForm';

export type IPWhitelistModalProps = {
  proxyId: ProxyModel['id'];
};

type IPWhitelistValues = {
  ipWhitelist: string[];
};

const schema: Yup.Schema<IPWhitelistValues> = Yup.object().shape({
  ipWhitelist: Yup.array().of(Yup.string().default('').optional()).min(1).required(),
});

export function IPWhitelistModal({ proxyId }: IPWhitelistModalProps) {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetProxyIPWhitelistQuery(proxyId);
  const posthog = usePostHog();
  const [updateIpWhitelist] = useUpdateIPWhitelistMutation();

  const hideModal = useHideModal();

  const initialValues = useMemo<IPWhitelistValues>(() => {
    if (!data) return { ipWhitelist: [''] };

    return {
      ipWhitelist: data.whitelistedIps.slice(0, data.allowedWhitelistIpCount),
    };
  }, [data]);

  const mapResponseKeyIntoFormKey = useCallback((key: keyof UpdateIPWhitelistPayload) => {
    if (key === 'ips') return 'ipWhitelist';

    throw new Error('Unsupported key');
  }, []);

  const validate = useCallback(
    ({ ipWhitelist }: IPWhitelistValues) => {
      const errors: FormErrors = {};

      const filteredWhitelist = ipWhitelist.filter((i) => Boolean(i.length));

      if (filteredWhitelist.length === 0 && !isLoading && !isFetching) {
        errors.globalError = t('errors:minItems', { field: t('common:form.ipWhitelist'), min: 1 }) ?? '';
      }

      return errors;
    },
    [isFetching, isLoading, t],
  );

  return (
    <Formik<IPWhitelistValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async ({ ipWhitelist }, { setErrors, setSubmitting }) => {
        try {
          await updateIpWhitelist({ proxyId, ips: ipWhitelist.filter((i) => Boolean(i.length)) }).unwrap();

          posthog?.capture(posthogEvents.proxy.ipWhitelist.success, { proxyId });

          ToastManager.success(t('proxies.modals.ipWhitelist.success'));
          hideModal();
        } catch (error) {
          const errors = getValidationErrors(error, mapResponseKeyIntoFormKey);

          if (errors && Object.keys(errors).length > 0) {
            setErrors(errors);
          }

          posthog?.capture(posthogEvents.proxy.ipWhitelist.failed, { proxyId });

          setSubmitting(false);
        }
      }}
      validate={validate}
      validationSchema={schema}
      validateOnMount
      validateOnChange
    >
      {({ handleSubmit, isValid, dirty, isSubmitting }) => {
        const actions = [
          <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
            {t('common:buttons.cancel')}
          </Button>,
          <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
            {t('common:buttons.update')}
          </Button>,
        ];

        return (
          <ModalContainer
            component="form"
            containerProps={{ onSubmit: handleSubmit, noValidate: true }}
            title={t('proxies.modals.ipWhitelist.title')}
            subtitle={t('proxies.modals.ipWhitelist.subtitle')}
            actions={actions}
            disablePortal
          >
            <IPWhitelistForm proxyIds={[proxyId]} />
          </ModalContainer>
        );
      }}
    </Formik>
  );
}
