import Stack from '@mui/material/Stack';

import { Outlet } from 'react-router-dom';

import { CannyProvider } from 'components/Canny/CannyProvider';

import { FeatureRequestNavigation } from './FeatureRequestNavigation';

export function FeatureRequestLayout() {
  return (
    <CannyProvider>
      <Stack
        component="main"
        flex="1 1 auto"
        minHeight="100%"
        bgcolor={{ xs: 'var(--mui-palette-background-paper)', md: 'var(--mui-palette-background-default)' }}
        sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
      >
        <FeatureRequestNavigation />

        <Stack
          sx={{
            '--FeatureRequestContent-paddingBlock': {
              xs: 'calc(var(--FeatureRequestNavigation-height) + 32px)',
              md: 'calc(var(--FeatureRequestNavigation-height) + 64px)',
            },
            '--FeatureRequestContent-paddingInline': { xs: '16px', lg: '0px' },
            '--FeatureRequestContent-padding':
              'var(--FeatureRequestContent-paddingBlock) var(--FeatureRequestContent-paddingInline) calc(var(--FeatureRequestContent-paddingBlock) + 64px) var(--FeatureRequestContent-paddingInline)',
            '--FeatureRequestContent-maxWidth': '976px',
          }}
        >
          <Stack
            p="var(--FeatureRequestContent-padding)"
            m="var(--FeatureRequestContent-margin)"
            width="var(--FeatureRequestContent-width)"
            maxWidth="var(--FeatureRequestContent-maxWidth)"
          >
            <Outlet />
          </Stack>
        </Stack>
      </Stack>
    </CannyProvider>
  );
}
