import type { Components, Theme } from '@mui/material/styles';
import { switchClasses } from '@mui/material/Switch';

function getSwitchVars(color: string): Record<string, string> {
  const vars = {
    '--Switch-track-background': 'var(--mui-palette-divider)',
    '--Switch-track-disabled-background': `rgba(var(--mui-palette-${color}-mainChannel) / 0.48)`,
    '--Switch-track-checked-background': `var(--mui-palette-${color}-main)`,
  };

  if (color === 'error') {
    vars['--Switch-track-background'] = 'rgba(var(--mui-palette-error-mainChannel) / 0.24)';
    vars['--Switch-track-checked-background'] = 'var(--mui-palette-primary-main)';
  }

  return vars;
}

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  defaultProps: { color: 'primary', disableRipple: true, focusVisibleClassName: 'Mui-focusVisible' },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Switch-height': '24px',
      '--Switch-width': '40px',

      '--Switch-base-margin': '4px',
      '--Switch-base-translateX': '16px',

      '--Switch-thumb-width': '16px',
      '--Switch-thumb-height': '16px',

      height: 'var(--Switch-height)',
      width: 'var(--Switch-width)',
      padding: 0,

      [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        margin: 'var(--Switch-base-margin)',

        [`&.${switchClasses.checked}`]: {
          transform: 'translateX(var(--Switch-base-translateX))',
          color: 'var(--mui-palette-common-white)',

          [`& + .${switchClasses.track}`]: {
            backgroundColor: 'var(--Switch-track-checked-background)',
            opacity: 1,
          },

          [`&.${switchClasses.disabled} + ${switchClasses.track}`]: { opacity: 0.5 },
        },

        [`&.${switchClasses.disabled}`]: {
          [`& .${switchClasses.thumb}`]: { color: 'var(--mui-palette-common-white)' },

          [`& + .${switchClasses.track}`]: { opacity: 0.7 },
        },
      },

      [`& .${switchClasses.thumb}`]: {
        width: 'var(--Switch-thumb-width)',
        height: 'var(--Switch-thumb-height)',
        boxShadow: 'var(--mui-shadows-4)',
      },

      [`& .${switchClasses.track}`]: {
        backgroundColor: 'var(--Switch-track-background)',
        opacity: 1,
        borderRadius: 'calc(var(--Switch-height) / 2)',
        transition: theme.transitions.create(['background-color'], { duration: 200 }),
      },

      [`& .MuiSwitch-colorPrimary + .${switchClasses.track}`]: { ...getSwitchVars('primary') },
      [`& .MuiSwitch-colorSecondary + .${switchClasses.track}`]: { ...getSwitchVars('secondary') },
      [`& .MuiSwitch-colorSuccess + .${switchClasses.track}`]: { ...getSwitchVars('success') },
      [`& .MuiSwitch-colorError + .${switchClasses.track}`]: { ...getSwitchVars('error') },
      [`& .MuiSwitch-colorWarning + .${switchClasses.track}`]: { ...getSwitchVars('warning') },
      [`& .MuiSwitch-colorInfo + .${switchClasses.track}`]: { ...getSwitchVars('info') },
    }),

    sizeSmall: {
      '--Switch-height': '18px',
      '--Switch-width': '30px',

      '--Switch-base-margin': '3px',
      '--Switch-base-translateX': '12px',

      '--Switch-thumb-width': '12px',
      '--Switch-thumb-height': '12px',
    },
  },
};
