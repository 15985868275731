import { useMemo } from 'react';

import { useGetSessionQuery } from 'store/api';

type UseSessionArgs = {
  skip?: boolean;
  pollingInterval?: number;
};

export function useSession({ pollingInterval, skip }: UseSessionArgs = {}) {
  const {
    data: session,
    isLoading: isSessionLoading,
    ...query
  } = useGetSessionQuery(undefined, { skip, ...(pollingInterval && { pollingInterval }) });

  const isProfileDetailsValid = useMemo(
    () => session?.isProfileSkipped || session?.isProfileValid,
    [session?.isProfileSkipped, session?.isProfileValid],
  );

  const isSuspended = useMemo(() => session?.isSuspended, [session?.isSuspended]);

  const hasTrialLimitExceeded = useMemo(
    () => session?.trialCount === session?.trialLimit,
    [session?.trialCount, session?.trialLimit],
  );

  return useMemo(
    () => ({ session, isSessionLoading, isSuspended, isProfileDetailsValid, hasTrialLimitExceeded, ...query }),
    [hasTrialLimitExceeded, isProfileDetailsValid, isSessionLoading, isSuspended, query, session],
  );
}
