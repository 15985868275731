import { type MouseEvent, useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { useHideModal } from 'components/modals/context';
import { RouterLink } from 'components/RouterLink';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useRouter } from 'hooks/useRouter';
import { useSession } from 'hooks/useSession';
import { useShowModal } from 'modules/common/GlobalModals';
import { useOrderFlow } from 'modules/orders/hooks';
import { paths } from 'paths';
import { useAppDispatch, useAppSelector } from 'store';
import { resetOrderFlow, setCurrentStep } from 'store/orders/reducers';

import { OrderNavigationItem } from './OrderNavigationItem';
import { useOrderNavigationItems } from './useOrderNavigationItems';

export function OrderNavigation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const router = useRouter();
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const posthog = usePostHog();
  const { session } = useSession();
  const { couponCode, proxySettings, price, currentStep, selectedNetworkType } = useAppSelector((state) => state.order);

  const isNewCustomer = useIsNewCustomer();
  const { isMarketplaceFlow, isVPNFlow } = useOrderFlow();

  const items = useOrderNavigationItems();

  const shouldDisplayBackArrow = useMemo(() => {
    if (!isMobile) return false;

    return pathname !== paths.order.index;
  }, [isMobile, pathname]);

  const onConfirm = useCallback(() => {
    dispatch(resetOrderFlow());

    GA_EVENTS.removeFromCart({
      userId: session?.userId,
      value: price?.finalPrice ?? 0,
      count: proxySettings?.quantity ?? 1,
      coupon: couponCode ?? undefined,
      networkType: proxySettings?.networkType ?? null,
    });

    posthog?.capture(posthogEvents.order.exit, { isMarketplaceFlow, isNewCustomer });

    hideModal();

    router.replace(isMarketplaceFlow ? paths.marketplace.index : paths.index);
  }, [
    couponCode,
    dispatch,
    hideModal,
    isMarketplaceFlow,
    isNewCustomer,
    posthog,
    price?.finalPrice,
    proxySettings?.networkType,
    proxySettings?.quantity,
    router,
    session?.userId,
  ]);

  const onOrderExit = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      return showModal('orders.cancel', {
        title: t('common:order.modals.quit.title'),
        subtitle: t('common:order.modals.quit.subtitle'),
        onConfirm,
        confirmLabel: t('common:buttons.quit'),
      });
    },
    [onConfirm, showModal, t],
  );

  const onBackClick = useCallback(() => {
    if (currentStep === 'proxy-setup') {
      return dispatch(resetOrderFlow());
    }

    if (isVPNFlow) {
      dispatch(setCurrentStep('vpn-plan'));
    }

    return dispatch(setCurrentStep(selectedNetworkType ? 'proxy-setup' : null));
  }, [currentStep, dispatch, isVPNFlow, selectedNetworkType]);

  return (
    <Stack
      component="header"
      bgcolor="var(--OrderNavigation-background)"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="var(--OrderNavigation-zIndex)"
    >
      <Stack
        direction="row"
        flex="1 1 auto"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="0px 1px 0px 0px rgba(16, 26, 51, 0.08)"
        minHeight="var(--OrderNavigation-height)"
        maxHeight="var(--OrderNavigation-height)"
        p={2}
        sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
      >
        <Fade in={shouldDisplayBackArrow} unmountOnExit>
          <IconButton onClick={onBackClick}>
            <Icon name="chevron-left" color="var(--mui-palette-neutral-400)" />
          </IconButton>
        </Fade>

        <Box
          display="inline-flex"
          component={RouterLink}
          href={paths.index}
          onClick={onOrderExit}
          zIndex="var(--OrderNavigation-logo-zIndex)"
        >
          <Logo wide={!isMobile} />
        </Box>

        <Fade in={!isMobile} unmountOnExit>
          <Stack
            direction="row"
            spacing={2}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            justifyContent="center"
            zIndex="var(--OrderNavigation-tabs-zIndex)"
          >
            {items.map((item) => (
              <OrderNavigationItem key={item.title} {...item} />
            ))}
          </Stack>
        </Fade>

        <IconButton
          LinkComponent={RouterLink}
          href={paths.index}
          onClick={onOrderExit}
          sx={{ zIndex: 'var(--OrderNavigation-close-zIndex)' }}
        >
          <Icon name="cross" color="var(--mui-palette-neutral-400)" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
