import { useCallback, useMemo } from 'react';

import type { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Flag } from 'components/Flag';
import { posthogEvents } from 'constants/posthogEvents';
import { useAppSelector } from 'store';
import { toLanguageDTO } from 'store/accounts/helpers';
import { Language } from 'store/accounts/models';
import { useChangeLanguageMutation } from 'store/api';
import type { Option } from 'types';

import Styled, { type ContainerProps } from './styled';

export type LanguageDropdownProps = ContainerProps & {
  iconOnly?: boolean;
};

export function LanguageDropdown({ iconOnly = false, floating = false }: LanguageDropdownProps) {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, accessToken } = useAppSelector((state) => state.auth);
  const posthog = usePostHog();
  const [changeLanguage] = useChangeLanguageMutation();

  const getFlagForLanguage = useCallback((language: Language) => {
    if (language === Language.CN) return 'CN';

    if (language === Language.VI) return 'VN';

    if (language === Language.ES) return 'ES';

    if (language === Language.RU) return 'RU';

    return 'US';
  }, []);

  const getLanguageValue = useCallback((language: string) => {
    if (language.includes('en-') || language.includes('en_')) return 'en';

    return language;
  }, []);

  const options = useMemo<Array<Option<Language>>>(
    () => Object.entries(Language).map(([key, value]) => ({ label: t(`common:languages.${key}`), value })),
    [t],
  );

  const handleChangeLanguage = useCallback(
    (e: SelectChangeEvent) => {
      try {
        const lng = e.target.value as Language;

        i18n.changeLanguage(lng);

        if (isAuthenticated && accessToken) {
          changeLanguage({ language: toLanguageDTO(lng) });
        }

        posthog.capture(posthogEvents.common.languageChanged, { language: toLanguageDTO(lng) });
      } catch {
        // noop
      }
    },
    [accessToken, changeLanguage, i18n, isAuthenticated, posthog],
  );

  return (
    <Styled.Container floating={floating}>
      <Styled.Select
        fullWidth
        size="small"
        value={getLanguageValue(i18n.language)}
        iconOnly={iconOnly}
        onChange={handleChangeLanguage}
      >
        {options.map(({ label, value }) => (
          <Styled.MenuItem
            disableRipple
            iconOnly={iconOnly}
            key={typeof value !== 'object' ? value : undefined}
            value={typeof value !== 'object' ? value : undefined}
          >
            <Flag size={16} countryCode={getFlagForLanguage(value)} />

            {!iconOnly && <Typography variant="body-2">{label}</Typography>}
          </Styled.MenuItem>
        ))}
      </Styled.Select>
    </Styled.Container>
  );
}
