import type { AppEndpointBuilder } from 'store/types';
import type { Paginate, BasePaginationParameters } from 'types';

import {
  toBankDetailsModel,
  toPayoutRequestModel,
  toReferralsDetailsModel,
  toReferralTransactionModel,
  toReferredUserModel,
} from './helpers';
import type {
  ReferralsDetailsModel,
  BankDetailsModel,
  ReferredUserModel,
  ReferralTransactionModel,
  PayoutRequestModel,
} from './models';
import type { UpsertBankDetailsPayload } from './payloads';

export const referralsEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getBankDetails: builder.query<BankDetailsModel, void>({
    query: () => ({
      url: 'referrals/bank-details',
      method: 'GET',
    }),
    providesTags: ['Referrals-Bank-Details'],
    transformResponse: toBankDetailsModel,
  }),

  getReferralsDetails: builder.query<ReferralsDetailsModel, void>({
    query: () => ({
      url: 'referrals/details',
      method: 'GET',
    }),
    providesTags: ['Referrals-Details'],
    transformResponse: toReferralsDetailsModel,
  }),

  getReferredUsers: builder.query<Paginate<ReferredUserModel>, BasePaginationParameters>({
    query: (params) => ({
      url: 'referrals/referred-users',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ email }) => ({ type: 'Referrals-User' as const, id: email })),
            { type: 'Referrals-User', id: 'LIST' },
          ]
        : [{ type: 'Referrals-User', id: 'LIST' }],
    transformResponse: toReferredUserModel,
  }),

  getReferralTransactions: builder.query<Paginate<ReferralTransactionModel>, BasePaginationParameters>({
    query: (params) => ({
      url: 'referrals/transactions',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ appliedAt }) => ({ type: 'Referrals-Transaction' as const, id: appliedAt })),
            { type: 'Referrals-Transaction', id: 'LIST' },
          ]
        : [{ type: 'Referrals-Transaction', id: 'LIST' }],
    transformResponse: toReferralTransactionModel,
  }),

  getReferralPayoutRequests: builder.query<Paginate<PayoutRequestModel>, BasePaginationParameters>({
    query: (params) => ({
      url: 'referrals/payout-requests',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ createdAt }) => ({ type: 'Referrals-Payout-Request' as const, id: createdAt })),
            { type: 'Referrals-Payout-Request', id: 'LIST' },
          ]
        : [{ type: 'Referrals-Payout-Request', id: 'LIST' }],
    transformResponse: toPayoutRequestModel,
  }),
  // #endregion Queries

  // #region Mutations
  enrollReferrals: builder.mutation<void, void>({
    query: () => ({
      url: 'referrals/enroll',
      method: 'POST',
    }),
    invalidatesTags: ['Referrals-Details'],
  }),

  upsertBankDetails: builder.mutation<void, UpsertBankDetailsPayload>({
    query: (data) => ({
      url: 'referrals/bank-details',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Referrals-Bank-Details'],
  }),

  referralsPayout: builder.mutation<void, void>({
    query: () => ({
      url: 'referrals/payout',
      method: 'POST',
    }),
    invalidatesTags: ['Referrals-Details'],
  }),
  // #endregion Mutations
});
