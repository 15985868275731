import type { Components, Theme } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const MuiTableHead: Components<Theme>['MuiTableHead'] = {
  styleOverrides: {
    root: {
      '--TableHead-opacity': 1,
      '--TableHead-zIndex': 'auto',

      opacity: 'var(--TableHead-opacity)',

      [`& .${tableCellClasses.root}`]: {
        '--TableCell-paddingBlock': '9px',
        '--TableCell-color': 'var(--mui-palette-neutral-400)',
        '--TableCell-fontSize': '10px',
        '--TableCell-fontWeight': '500',
        '--TableCell-lineHeight': '14px',
        '--TableCell-letterSpacing': '0.08em',

        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        overflow: 'visible',
      },
    },
  },
};
