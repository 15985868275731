import { forwardRef } from 'react';

import MuiButton, { type ButtonProps as MuiButtonProps, type ButtonTypeMap } from '@mui/material/Button';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

type ButtonProps = MuiButtonProps & {
  isSelected?: boolean;
};

export const Button: OverridableComponent<ButtonTypeMap<ButtonProps>> = forwardRef(
  ({ fullWidth = true, variant = 'outlined', isSelected, ...props }, ref) => {
    return (
      <MuiButton
        ref={ref}
        fullWidth={fullWidth}
        variant={variant}
        sx={{
          '--SelectionBox-button-background': 'transparent',
          '--SelectionBox-button-border': '1px solid rgba(var(--mui-palette-text-primaryChannel) / 0.12)',
          '--SelectionBox-button-color': 'var(--mui-palette-neutral-500)',

          '--SelectionBox-button-paddingBlock': '8px',
          '--SelectionBox-button-paddingInline': '8px',

          '--SelectionBox-button-hover-border': '1px solid var(--mui-palette-primary-main)',
          '--SelectionBox-button-hover-color': 'var(--mui-palette-text-primary)',

          '--SelectionBox-button-selected-border': '1px solid var(--mui-palette-primary-main)',
          '--SelectionBox-button-selected-color': 'var(--mui-palette-text-primary)',

          flexDirection: 'column',
          background: 'var(--SelectionBox-button-background)',
          border: 'var(--SelectionBox-button-border)',
          color: 'var(--SelectionBox-button-color)',

          paddingBlock: 'var(--SelectionBox-button-paddingBlock)',
          paddingInline: 'var(--SelectionBox-button-paddingInline)',

          '&:hover': {
            border: 'var(--SelectionBox-button-hover-border)',
            color: 'var(--SelectionBox-button-hover-color)',
          },

          ...(isSelected && {
            border: 'var(--SelectionBox-button-selected-border)',
            color: 'var(--SelectionBox-button-selected-color)',
          }),
        }}
        {...props}
      />
    );
  },
) as OverridableComponent<ButtonTypeMap<ButtonProps>>;
