import { useEffect, useMemo } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { useUplinkSpeedFormatter } from 'hooks/useUplinkSpeedFormatter';
import { useChangeUplinkSpeedMutation, useGetChangeUplinkSpeedOptionsQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/types';
import type { Option } from 'types';
import { ToastManager } from 'utils/toast';

import { ChangeUplinkSpeedForm, type ChangeUplinkSpeedValues } from './ChangeUplinkSpeedForm';

export type ChangeUplinkSpeedModalProps = {
  proxyIds: number[];
};

export function ChangeUplinkSpeedModal({ proxyIds }: ChangeUplinkSpeedModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const prepareUplinkSpeedLabel = useUplinkSpeedFormatter();

  const [changeUplinkSpeed] = useChangeUplinkSpeedMutation();
  const {
    data: uplinkSpeedOptions,
    isFetching: isOptionsLoading,
    refetch,
  } = useGetChangeUplinkSpeedOptionsQuery(proxyIds[0], {
    skip: proxyIds.length > 1,
  });

  const options = useMemo<Array<Option<number>>>(() => {
    return uplinkSpeedOptions?.options.map((i) => ({ value: i, label: prepareUplinkSpeedLabel(i) })) ?? [];
  }, [uplinkSpeedOptions?.options, prepareUplinkSpeedLabel]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Formik<ChangeUplinkSpeedValues>
      initialValues={{ uplinkSpeed: uplinkSpeedOptions?.currentUplinkSpeed ?? 100 }}
      enableReinitialize
      onSubmit={async ({ uplinkSpeed }) => {
        try {
          await changeUplinkSpeed({ proxyId: proxyIds[0], uplinkSpeed }).unwrap();

          ToastManager.success(t('proxies.modals.upgradeBandwidthSpeed.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.message && typeof err.message === 'string') {
            ToastManager.error(t(`errors:${err.message}`));
          } else {
            ToastManager.error(t('proxies.modals.upgradeBandwidthSpeed.failure'));
          }
        }
      }}
    >
      <ChangeUplinkSpeedForm options={options} isLoading={isOptionsLoading} />
    </Formik>
  );
}
