import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

import type { Nullable, Optional, Paginate } from 'types';

export function isPaginatedDTO<T>(value: T | Paginate<T>): value is Paginate<T> {
  return isObject(value) && 'data' in value && 'metadata' in value;
}

export function isDefined<T>(value: Optional<Nullable<T>>): value is Exclude<T, undefined | null> {
  return value !== undefined && value !== null && !isNil(value);
}

export function isQueryDataLoaded<T>(value: T, isLoading?: boolean): value is Exclude<T, undefined | null> {
  return isDefined(value) && !isLoading;
}

export function isEnum<T extends object>(value: T) {
  return (token: unknown): token is T[keyof T] => {
    return Object.values(value).includes(token);
  };
}
