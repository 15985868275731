import { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import type { EnableSoftwareTwoFactorModel } from 'store/accounts/models';
import { useEnableSoftwareTwoFactorMutation } from 'store/api';
import type { Nullable } from 'types';
import { getValidationErrors } from 'utils/error';

import Styled from './styled';

export type ToTPModalProps = {
  onConfirm: () => void;
};

type ToTPValues = {
  code: string;
};

const schema: Yup.Schema<ToTPValues> = Yup.object().shape({
  code: Yup.string().required(),
});

export function ToTPModal({ onConfirm }: ToTPModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [configuration, setConfiguration] = useState<Nullable<EnableSoftwareTwoFactorModel>>(null);
  const [enableSoftwareTwoFactor, { isLoading }] = useEnableSoftwareTwoFactorMutation();
  const hideModal = useHideModal();

  const [isCodeVisible, setIsCodeVisible] = useState(false);

  const getInitialConfiguration = useCallback(async () => {
    const response = await enableSoftwareTwoFactor({ code: null }).unwrap();

    setConfiguration(response);
  }, [enableSoftwareTwoFactor]);

  useEffect(() => {
    getInitialConfiguration();
  }, [getInitialConfiguration]);

  return (
    <Formik<ToTPValues>
      initialValues={{ code: '' }}
      onSubmit={async ({ code }, { setErrors }) => {
        try {
          await enableSoftwareTwoFactor({ code }).unwrap();

          posthog?.capture(posthogEvents.account.security.twoFactor.enabled.success, { type: 'totp' });

          onConfirm();
        } catch (error) {
          const errors = getValidationErrors(error);

          if (errors && Object.keys(errors).length > 0) {
            setErrors(errors);
          }

          posthog?.capture(posthogEvents.account.security.twoFactor.enabled.failed, { type: 'totp' });
        }
      }}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }) => {
        const actions = [
          <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
            {t('common:buttons.cancel')}
          </Button>,
          <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
            {t('common:buttons.verify')}
          </Button>,
        ];

        const footer = (
          <Stack direction="column" rowGap={2}>
            <Box display="flex" gap={1}>
              <Typography variant="caption" color="var(--mui-palette-neutral-500)" textAlign="center">
                {t('account.modals.totp.helper')}
                <Styled.LinkButton
                  variant="caption"
                  onClick={() => setIsCodeVisible((prevIsCodeVisible) => !prevIsCodeVisible)}
                >
                  {t('account.modals.totp.helperLink')}
                </Styled.LinkButton>
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Styled.Collapse in={isCodeVisible}>
                <Typography variant="caption" color="var(--mui-palette-neutral-500)">
                  {t('account.modals.totp.enterCode')}
                </Typography>

                <Typography variant="caption" color="text.primary">
                  {!configuration && isLoading ? <Skeleton /> : configuration?.secret}
                </Typography>
              </Styled.Collapse>
            </Box>
          </Stack>
        );

        return (
          <ModalContainer
            component="form"
            containerProps={{ onSubmit: handleSubmit }}
            title={t('account.modals.totp.title')}
            subtitle={t('account.modals.totp.subtitle')}
            actions={actions}
            footer={footer}
          >
            <Stack direction="column" rowGap={4}>
              <Box display="flex" justifyContent="center">
                {!configuration && isLoading ? (
                  <Skeleton variant="rectangular" width={200} height={200} />
                ) : (
                  <Styled.QRImage src={configuration?.dataUri} alt="qr code" />
                )}
              </Box>

              <FormikInput
                fullWidth
                placeholder={t('common:form.enter', { value: t('common:form.code') })}
                name="code"
              />
            </Stack>
          </ModalContainer>
        );
      }}
    </Formik>
  );
}
