import { checkboxClasses } from '@mui/material/Checkbox';
import type { Components, Theme } from '@mui/material/styles';

function CheckedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_30_171)">
        <rect width="16" height="16" rx="4" fill="var(--mui-palette-primary-main)" />
        <path
          d="M2.66663 8L5.99996 11.3333"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00004 11.3333L13.3334 4"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_171">
          <rect width="16" height="16" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function IndeterminateIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="var(--mui-palette-primary-main)" />
      <path d="M12 8H3.99976" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

function Icon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="var(--mui-palette-text-primary)" fillOpacity="0.08" />
    </svg>
  );
}

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    checkedIcon: <CheckedIcon />,
    indeterminateIcon: <IndeterminateIcon />,
    icon: <Icon />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Checkbox-borderRadius': '4px',
      '--Checkbox-padding': '0px',

      padding: 'var(--Checkbox-padding)',

      '& svg': {
        borderRadius: 'var(--Checkbox-borderRadius)',

        '& rect': {
          transition: theme.transitions.create('all', { duration: 300 }),
        },
      },

      [`&:hover:not(.${checkboxClasses.checked})`]: {
        '& svg rect': {
          stroke: 'rgba(var(--mui-palette-primary-mainChannel) / 0.24)',
          fill: 'var(--mui-palette-common-white)',
        },
      },
    }),
  },
};
