/* eslint-disable react/no-array-index-key */
import { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'hooks/useSession';
import { useGetGlobalIPWhitelistQuery, useUpdateGlobalIPWhitelistMutation } from 'store/api';
import type { UpdateGlobalIPWhitelistPayload } from 'store/proxies/payloads';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

export type GlobalIPWhitelistModalProps = {
  onConfirm: () => void;
};

type IPWhitelistValues = {
  ipWhitelist: string[];
};

const schema: Yup.Schema<IPWhitelistValues> = Yup.object().shape({
  ipWhitelist: Yup.array().of(Yup.string().default('').optional()).required(),
});

export function GlobalIPWhitelistModal({ onConfirm }: GlobalIPWhitelistModalProps) {
  const { t } = useTranslation();
  const { data } = useGetGlobalIPWhitelistQuery();
  const posthog = usePostHog();
  const [updateGlobalIPWhitelist] = useUpdateGlobalIPWhitelistMutation();

  const hideModal = useHideModal();
  const { session } = useSession();

  const initialValues = useMemo<IPWhitelistValues>(() => {
    if (!data) return { ipWhitelist: ['', '', ''] };

    const ipWhitelist = Array.from(Array(data.allowedWhitelistIpCount)).map(() => '');

    if (!data.whitelistedIps.length) {
      return { ipWhitelist };
    }

    return {
      ipWhitelist: ipWhitelist.map((item, i) => data.whitelistedIps[i] ?? item),
    };
  }, [data]);

  const mapResponseKeyIntoFormKey = useCallback(
    (key: keyof UpdateGlobalIPWhitelistPayload): keyof IPWhitelistValues => {
      if (key === 'ips') return 'ipWhitelist';

      throw new Error('Unsupported key');
    },
    [],
  );

  return (
    <Formik<IPWhitelistValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async ({ ipWhitelist }, { setErrors, setSubmitting }) => {
        try {
          await updateGlobalIPWhitelist({ ips: ipWhitelist.filter((i) => Boolean(i.length)) }).unwrap();

          posthog?.capture(posthogEvents.proxy.globalIPWhitelist.success);

          ToastManager.success(t('proxies.modals.globalIPWhitelist.success'));
          onConfirm();
        } catch (error) {
          const errors = getValidationErrors(error, mapResponseKeyIntoFormKey);

          if (errors && Object.keys(errors).length > 0) {
            setErrors(errors);
          }

          posthog?.capture(posthogEvents.proxy.globalIPWhitelist.failed);

          setSubmitting(false);
        }
      }}
      validationSchema={schema}
      validateOnMount
      validateOnChange
    >
      {({ values, handleSubmit, setFieldValue, isValid, dirty, isSubmitting }) => {
        const actions = [
          <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
            {t('common:buttons.cancel')}
          </Button>,
          <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
            {t('common:buttons.update')}
          </Button>,
        ];

        const setIP = () => {
          const isAnyFieldEmpty = values.ipWhitelist.some((item) => !item.length);

          if (!isAnyFieldEmpty) return setFieldValue('ipWhitelist[0]', session?.userIp || '');

          const emptyIndex = values.ipWhitelist.findIndex((item) => !item.length);

          return setFieldValue(`ipWhitelist[${emptyIndex}]`, session?.userIp || '');
        };

        return (
          <ModalContainer
            component="form"
            containerProps={{ onSubmit: handleSubmit, noValidate: true }}
            title={t('proxies.modals.globalIPWhitelist.title')}
            subtitle={t('proxies.modals.globalIPWhitelist.subtitle')}
            actions={actions}
            disablePortal
          >
            <Grid container direction="column" rowSpacing={4}>
              <Grid item display="flex" flexDirection="column" rowGap={2}>
                {values.ipWhitelist.map((_, index) => (
                  <Grid item key={`ip-whitelist-${index}`}>
                    <FormikInput fullWidth name={`ipWhitelist[${index}]`} />
                  </Grid>
                ))}
              </Grid>
              {session?.userIp && (
                <Grid item display="flex" justifyContent="center">
                  <Link variant="caption" color="#707D96" onClick={setIP} underline="always" sx={{ cursor: 'pointer' }}>
                    {t('common:buttons.setYourCurrentIp', { ip: session.userIp })}
                  </Link>
                </Grid>
              )}
            </Grid>
          </ModalContainer>
        );
      }}
    </Formik>
  );
}
