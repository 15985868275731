import { useCallback, useEffect, useMemo, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { SelectionBox } from 'components/SelectionBox';
import { Tooltip } from 'components/Tooltip';
import { useCreditCard } from 'hooks/useCreditCard';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { toPaymentOption } from 'modules/billing/helpers';
import type { Nullable, Option } from 'types';
import { CurrencyFormatter, CurrencyFormatterOptionalDecimals } from 'utils/currency';

import { AmountField } from './AmountField';
import { PaymentMethod } from './PaymentMethod';
import { useTopUpModalContext } from '../context';
import Styled from '../styled';
import type { TopUpOption, TopUpValues } from '../types';

const selectionBoxOptions = [10, 25, 50, 100, 200].map<Option<number>>((value) => ({
  value,
  label: CurrencyFormatterOptionalDecimals.format(value),
}));

type TopUpFormProps = {
  options: TopUpOption[];
};

export function TopUpForm({ options }: TopUpFormProps) {
  const { t } = useTranslation();
  const { isValid, values, setFieldValue, setValues } = useFormikContext<TopUpValues>();
  const { fee, isFeeLoading, isTopUpLoading, handleCancelModal } = useTopUpModalContext();

  const { isCardLoading } = useCreditCard();

  const [selectedPriceOption, selectPriceOption] = useState<Nullable<number>>(null);

  const { isPaymentMethodEnabled } = useFeatureFlag();

  const enabledOptions = useMemo(() => {
    return options.filter(({ type }) => isPaymentMethodEnabled(toPaymentOption(type)));
  }, [isPaymentMethodEnabled, options]);

  const subtotal = useMemo(() => CurrencyFormatter.format(!fee ? 0 : fee.subtotal), [fee]);
  const paymentFee = useMemo(() => CurrencyFormatter.format(!fee ? 0 : fee.paymentFee), [fee]);
  const formattedPrice = useMemo(() => CurrencyFormatter.format(!fee ? 0 : fee.finalPrice), [fee]);

  const onPaymentMethodSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const selectedOption = enabledOptions.find(({ type }) => type === e.target.value);

      if (!selectedOption) {
        return;
      }

      setValues((prevValues) => ({ ...prevValues, ...selectedOption }));
    },
    [enabledOptions, setValues],
  );

  useEffect(() => {
    const foundAmountOption = selectionBoxOptions.find(({ value }) => value === values.amount);

    if (foundAmountOption?.value !== selectedPriceOption) {
      selectPriceOption(values.amount);
    }
  }, [selectedPriceOption, values.amount]);

  return (
    <Form noValidate>
      <Grid container rowSpacing={2} direction="column">
        <Styled.TopUpGrid container rowSpacing={4} direction="column">
          <Grid item>
            <Grid container rowSpacing={2} direction="column">
              <Grid item>
                <AmountField />
              </Grid>
              <Grid item>
                <SelectionBox
                  size="small"
                  data={selectionBoxOptions}
                  value={selectedPriceOption}
                  onItemClick={(option) => {
                    selectPriceOption(option.value);
                    setFieldValue('amount', option.value);
                  }}
                  getOptionLabel={(item) => item.label}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormLabel>{t('common:form.paymentMethod')}</FormLabel>

              <Styled.PaymentMethodsDropdown value={values.type} onChange={onPaymentMethodSelect}>
                {enabledOptions.map((option) => (
                  <MenuItem key={option.type} value={option.type}>
                    <PaymentMethod {...option} isSelected={option.type === values.type} />
                  </MenuItem>
                ))}
              </Styled.PaymentMethodsDropdown>
            </FormGroup>
          </Grid>
        </Styled.TopUpGrid>

        <Grid item>
          <Collapse in={!!fee} unmountOnExit>
            <Styled.PaymentFeeRoot direction="column" rowGap={2}>
              <Grid item component={Divider} />

              <Styled.PaymentFeeItem>
                <Typography variant="body-2" color="text.secondary">
                  {t('common:form.subtotal')}
                </Typography>

                <Typography variant="body-2">{subtotal}</Typography>
              </Styled.PaymentFeeItem>

              <Collapse in={Boolean(fee && fee.paymentFee !== 0)} unmountOnExit>
                <Styled.PaymentFeeItem>
                  <Styled.PaymentFeeItemLabel>
                    <Typography variant="body-2" color="text.secondary">
                      {t('common:form.paymentFee', { paymentFee: values.paymentFee })}
                    </Typography>

                    <Tooltip title={t('common:tooltips.fee')} arrow>
                      <Styled.IconButton>
                        {/* TODO: Fix this color */}
                        <Icon name="question" color={(theme) => alpha(theme.palette.text.primary, 0.24)} size="small" />
                      </Styled.IconButton>
                    </Tooltip>
                  </Styled.PaymentFeeItemLabel>

                  <Typography variant="body-2">{paymentFee}</Typography>
                </Styled.PaymentFeeItem>
              </Collapse>

              <Grid item component={Divider} />

              <Styled.PaymentFeeItem>
                <Typography variant="title" color="text.secondary">
                  {t('common:form.total')}
                </Typography>

                <Typography variant="headline-2">{formattedPrice}</Typography>
              </Styled.PaymentFeeItem>
            </Styled.PaymentFeeRoot>
          </Collapse>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} pt={4}>
        <Grid xs={6} item>
          <Button color="secondary" fullWidth onClick={handleCancelModal} disabled={isTopUpLoading}>
            {t('common:buttons.cancel')}
          </Button>
        </Grid>

        <Grid xs={6} item>
          <Button
            fullWidth
            type="submit"
            loading={isCardLoading || isFeeLoading || isTopUpLoading}
            disabled={!isValid || !fee}
          >
            {t('common:buttons.continue')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
