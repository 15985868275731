import { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useDebounce } from 'hooks/useDebounce';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import type { PaymentOption } from 'modules/billing/types';
import { useBlackFridayExperiment } from 'modules/common/hooks';
import { useAppDispatch } from 'store';
import { useGetOrderPriceMutation } from 'store/api';
import { toOrderConfigurationPayload } from 'store/orders/helpers';
import type { ProxySetupModel } from 'store/orders/models';
import { setCouponCode, setPriceLoading, setOrderPrice } from 'store/orders/reducers';
import { ServiceType } from 'store/proxies/types';
import type { Nullable } from 'types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { useShowUnavailableDeals } from './useShowUnavailableDeals';

export function useOrderPrice(values: Nullable<ProxySetupModel>, paymentOption?: PaymentOption) {
  const posthog = usePostHog();
  const isNewCustomer = useIsNewCustomer();
  const { t } = useTranslation();
  const [internalValues, setInternalValues] = useState(values);
  const debouncedValues = useDebounce(internalValues);
  const { isLegacyMobileProxiesEnabled } = useFeatureFlag();

  const dispatch = useAppDispatch();

  const [getOrderPrice, { isLoading, isUninitialized }] = useGetOrderPriceMutation();
  const showDealUnavailable = useShowUnavailableDeals();
  const { blackFridayCouponCode } = useBlackFridayExperiment();

  const getErrorMessage = useCallback(
    (errors: Record<string, string | string[]>) => {
      if (debouncedValues?.couponCode === blackFridayCouponCode) {
        return null;
      }

      return errors.couponCode.toString();
    },
    [blackFridayCouponCode, debouncedValues?.couponCode],
  );

  useEffect(() => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (async () => {
      try {
        if (!debouncedValues) return;

        const mappedValues = toOrderConfigurationPayload(debouncedValues, isLegacyMobileProxiesEnabled);

        let areAllFieldsSelected =
          !!mappedValues.ipVersion && !!mappedValues.proxyProtocol && !!mappedValues.authenticationType;

        // fully filled is when everything is considered selected (skipping Any option)
        if (
          mappedValues.serviceType === ServiceType.PROXY_RESIDENTIAL ||
          (mappedValues.serviceType === ServiceType.PROXY_MOBILE && !isLegacyMobileProxiesEnabled)
        ) {
          // Do nothing
        } else if (mappedValues.serviceType === ServiceType.PROXY_RESIDENTIAL_STATIC) {
          areAllFieldsSelected = areAllFieldsSelected && !!mappedValues.country && !!mappedValues.isp;
        } else if (mappedValues.serviceType === ServiceType.PROXY_DATACENTER) {
          areAllFieldsSelected =
            areAllFieldsSelected &&
            !!mappedValues.country &&
            (mappedValues.ipVersion === 'IPv4' || (mappedValues.ipVersion === 'IPv6' && !!mappedValues.package));
        } else if (mappedValues.serviceType === ServiceType.PROXY_MOBILE && isLegacyMobileProxiesEnabled) {
          areAllFieldsSelected = areAllFieldsSelected && !!mappedValues.country;
        }

        const response = await getOrderPrice({
          ...mappedValues,
          ...(paymentOption && { paymentMethod: paymentOption, paymentMethodMetadata: 'blank' }),
        }).unwrap();

        if (areAllFieldsSelected && !response.finalPrice) {
          posthog?.capture(posthogEvents.order.configurationDeadEnd, { ...mappedValues, isNewCustomer });
        }

        dispatch(setOrderPrice(response));
      } catch (error) {
        showDealUnavailable(error);
        const validationErrors = getValidationErrors(error);

        if (validationErrors?.couponCode) {
          const errorMessage = getErrorMessage(validationErrors);

          if (errorMessage) {
            ToastManager.error(errorMessage);
          }

          return dispatch(setCouponCode(null));
        }

        dispatch(setOrderPrice(null));
      }
    })();
  }, [
    debouncedValues,
    dispatch,
    getOrderPrice,
    paymentOption,
    posthog,
    t,
    showDealUnavailable,
    isNewCustomer,
    isLegacyMobileProxiesEnabled,
    getErrorMessage,
  ]);

  useEffect(() => {
    if (!values || !internalValues) return;

    const { lastUpdatedKey, ...rest } = values;
    // eslint-disable-next-line sonarjs/no-unused-vars
    const { lastUpdatedKey: internalLastUpdatedKey, ...internalRest } = internalValues;

    if (isEqual(rest, internalRest)) {
      return;
    }

    setInternalValues(values);
  }, [internalValues, values]);

  useEffect(() => {
    if (isUninitialized) return;

    dispatch(setPriceLoading(isLoading));
  }, [dispatch, isLoading, isUninitialized]);

  return useMemo(() => ({ isLoading }), [isLoading]);
}
