import { useMemo } from 'react';

import { useGetProxiesQuery, useGetVPNQuery } from 'store/api';

export function useIsNewCustomer() {
  const { data: proxies } = useGetProxiesQuery({ page: 1, perPage: 10 });
  const { data: vpn } = useGetVPNQuery();

  return useMemo(() => proxies?.data.length === 0 || !vpn, [proxies?.data.length, vpn]);
}
