import type { Components, Theme } from '@mui/material/styles';
import { tabsClasses } from '@mui/material/Tabs';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  defaultProps: { scrollButtons: false },
  styleOverrides: {
    root: {
      '--Tabs-spacing': '16px',
      '--Tabs-indicator-background': 'var(--mui-palette-primary-main)',
      '--Tabs-indicator-borderRadius': '0px',
      '--Tabs-indicator-height': '2px',
      '--Tabs-maxHeight': '40px',
      '--Tabs-boxShadow': 'var(--mui-shadows-1)',

      alignItems: 'flex-end',
      boxShadow: 'var(--Tabs-boxShadow)',
    },

    scroller: { maxHeight: 'var(--Tabs-maxHeight)' },

    flexContainer: {
      height: '100%',

      [`&:not(.${tabsClasses.flexContainerVertical})`]: { gap: 'var(--Tabs-spacing)' },
    },

    indicator: {
      backgroundColor: 'var(--Tabs-indicator-background)',
      borderRadius: 'var(--Tabs-indicator-borderRadius)',
      height: 'var(--Tabs-indicator-height)',
    },
  },
};
