import { useMemo, useRef } from 'react';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import type { Action, Nullable } from 'types';

import { BulkActionsMenu } from './BulkActionsMenu';
import Styled from './styled';

export type TableItemID = string | number;

type ActionType = {
  action: Action;
};

type ActionsType = {
  actions: Action[];
};

export type BulkActionsType = (ActionType | ActionsType) & {
  selectedOne: string;
  selectedMultiple: string;
  selectedAll: string;
  selectAllAction: string;
};

export type BulkActionsProps = BulkActionsType & {
  ids?: TableItemID[];
  total: number;
  isOpen?: boolean;
  isAllSelected: boolean;
  onSelectAll: () => void;
  onCloseClick: () => void;

  // Do we need that?
  isAllButtonDisabled?: boolean;
};

export function BulkActions({
  ids = [],
  isOpen = false,
  selectedOne,
  selectedMultiple,
  selectedAll,
  selectAllAction,
  total,
  isAllSelected,
  isAllButtonDisabled,
  onCloseClick,
  onSelectAll,
  ...props
}: BulkActionsProps) {
  const { t } = useTranslation();

  const containerRef = useRef<Nullable<HTMLDivElement>>(null);

  const label = useMemo(() => {
    if (isAllSelected) {
      return selectedAll;
    }

    return ids.length === 1 ? selectedOne : selectedMultiple;
  }, [ids.length, isAllSelected, selectedAll, selectedMultiple, selectedOne]);

  return (
    <Styled.BulkActionsContainer
      ref={containerRef}
      open={isOpen}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Styled.BulkActionsContent container rowSpacing={1} columnSpacing={2}>
        <Grid item>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item>
              <Chip label={isAllSelected ? total : ids.length} />
            </Grid>

            <Grid item>
              <Typography variant="body-2" color="common.white">
                {label}
              </Typography>
            </Grid>

            {!isAllSelected && !isAllButtonDisabled && (
              <Grid item>
                <Typography
                  variant="body-2"
                  color="var(--mui-palette-neutral-600)"
                  component={Link}
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectAll();
                  }}
                >
                  {selectAllAction}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            {'action' in props ? (
              <Grid item>
                <Button size="medium" onClick={props.action.onClick}>
                  {props.action.label}
                </Button>
              </Grid>
            ) : (
              props.actions.length > 0 && (
                <Grid item>
                  <BulkActionsMenu
                    actions={props.actions}
                    anchorRef={containerRef}
                    MenuProps={{
                      anchorOrigin: { vertical: -16, horizontal: 'right' },
                      transformOrigin: { vertical: 'bottom', horizontal: 'right' },
                    }}
                    renderIndicator={({ onClick }) => (
                      <Button
                        size="medium"
                        onClick={(e) => {
                          onClick(e);
                        }}
                      >
                        {t('common:buttons.takeAnAction')}...
                      </Button>
                    )}
                  />
                </Grid>
              )
            )}

            <Grid item>
              <IconButton onClick={onCloseClick}>
                <Icon name="cross" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Styled.BulkActionsContent>
    </Styled.BulkActionsContainer>
  );
}
