import { modalClasses } from '@mui/material/Modal';
import type { Components, Theme } from '@mui/material/styles';

export const MuiPopover: Components<Theme>['MuiPopover'] = {
  styleOverrides: {
    root: {
      zIndex: 1402,

      [`& .${modalClasses.backdrop}`]: {
        backgroundColor: 'transparent',
      },
    },
  },
};
