import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useDisableHardwareTwoFactorMutation, useDisableSoftwareTwoFactorMutation } from 'store/api';
import { getValidationErrors } from 'utils/error';

export type PasswordModalProps = {
  onConfirm: (type: string) => void;
  type: 'totp' | 'webauthn';
};

type PasswordModalsValues = {
  password: string;
};

const schema: Yup.Schema<PasswordModalsValues> = Yup.object().shape({
  password: Yup.string().required(),
});

export function PasswordModal({ type, onConfirm }: PasswordModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [disableHardwareTwoFactor] = useDisableHardwareTwoFactorMutation();
  const [disableSoftwareTwoFactor] = useDisableSoftwareTwoFactorMutation();
  const hideModal = useHideModal();

  return (
    <Formik<PasswordModalsValues>
      initialValues={{ password: '' }}
      onSubmit={async ({ password }, { setErrors }) => {
        try {
          if (type === 'totp') {
            await disableSoftwareTwoFactor({ password }).unwrap();
          } else {
            await disableHardwareTwoFactor({ password }).unwrap();
          }

          onConfirm(type);
        } catch (error) {
          const errors = getValidationErrors(error);

          if (errors && Object.keys(errors).length > 0) {
            setErrors(errors);
          }

          posthog.capture(posthogEvents.account.security.twoFactor.disabled.failed, { type });
        }
      }}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }) => (
        <ModalContainer
          component="form"
          containerProps={{ onSubmit: handleSubmit }}
          title={t('account.modals.password.title')}
          subtitle={t('account.modals.password.subtitle')}
          actions={[
            <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
              {t('common:buttons.cancel')}
            </Button>,
            <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
              {t('common:buttons.verify')}
            </Button>,
          ]}
        >
          <FormikInput
            fullWidth
            type="password"
            placeholder={t('common:form.enter', { value: t('common:form.password') })}
            name="password"
          />
        </ModalContainer>
      )}
    </Formik>
  );
}
