import { v4 as uuid } from 'uuid';

import type { Paginate } from 'types';
import { isPaginatedDTO } from 'utils/guards';

import type { VPNAdminDetailsDTO, VPNChangelogDTO, VPNDTO, VPNEventDTO, VPNLocationDTO, VPNPortDTO } from './dtos';
import type {
  VPNAdminDetailsModel,
  VPNChangelogModel,
  VPNEventModel,
  VPNLocationModel,
  VPNModel,
  VPNPortModel,
} from './models';

export function toVPNModel(from: VPNDTO): VPNModel;
export function toVPNModel(from: VPNDTO[]): VPNModel[];
export function toVPNModel(from: Paginate<VPNDTO>): Paginate<VPNModel>;

export function toVPNModel(from: VPNDTO | VPNDTO[] | Paginate<VPNDTO>): VPNModel | VPNModel[] | Paginate<VPNModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toVPNModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toVPNModel(item)) };
  }

  const { autoExtendEnabled, authentication = {}, ...rest } = from;

  return {
    ...rest,
    isAutoExtendEnabled: autoExtendEnabled,
    authentication: { username: authentication.username ?? null, password: authentication.password ?? null },
  };
}

export function toVPNAdminDetailsModel(from: VPNAdminDetailsDTO): VPNAdminDetailsModel;
export function toVPNAdminDetailsModel(from: VPNAdminDetailsDTO[]): VPNAdminDetailsModel[];
export function toVPNAdminDetailsModel(from: Paginate<VPNAdminDetailsDTO>): Paginate<VPNAdminDetailsModel>;

export function toVPNAdminDetailsModel(
  from: VPNAdminDetailsDTO | VPNAdminDetailsDTO[] | Paginate<VPNAdminDetailsDTO>,
): VPNAdminDetailsModel | VPNAdminDetailsModel[] | Paginate<VPNAdminDetailsModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toVPNAdminDetailsModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toVPNAdminDetailsModel(item)) };
  }

  const { authentication = {}, ...rest } = from;

  return {
    ...rest,
    authentication: { username: authentication.username ?? null, password: authentication.password ?? null },
  };
}

export function toVPNChangelogModel(from: VPNChangelogDTO): VPNChangelogModel;
export function toVPNChangelogModel(from: VPNChangelogDTO[]): VPNChangelogModel[];
export function toVPNChangelogModel(from: Paginate<VPNChangelogDTO>): Paginate<VPNChangelogModel>;

export function toVPNChangelogModel(
  from: VPNChangelogDTO | VPNChangelogDTO[] | Paginate<VPNChangelogDTO>,
): VPNChangelogModel | VPNChangelogModel[] | Paginate<VPNChangelogModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toVPNChangelogModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toVPNChangelogModel(item)) };
  }

  return { ...from, id: uuid() };
}

export function toVPNEventModel(from: VPNEventDTO): VPNEventModel;
export function toVPNEventModel(from: VPNEventDTO[]): VPNEventModel[];
export function toVPNEventModel(from: Paginate<VPNEventDTO>): Paginate<VPNEventModel>;

export function toVPNEventModel(
  from: VPNEventDTO | VPNEventDTO[] | Paginate<VPNEventDTO>,
): VPNEventModel | VPNEventModel[] | Paginate<VPNEventModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toVPNEventModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toVPNEventModel(item)) };
  }

  return { ...from, id: uuid() };
}

export function toVPNLocationModel(from: VPNLocationDTO): VPNLocationModel;
export function toVPNLocationModel(from: VPNLocationDTO[]): VPNLocationModel[];
export function toVPNLocationModel(from: Paginate<VPNLocationDTO>): Paginate<VPNLocationModel>;

export function toVPNLocationModel(
  from: VPNLocationDTO | VPNLocationDTO[] | Paginate<VPNLocationDTO>,
): VPNLocationModel | VPNLocationModel[] | Paginate<VPNLocationModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toVPNLocationModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toVPNLocationModel(item)) };
  }

  return from;
}

export function toVPNPortModel(from: VPNPortDTO): VPNPortModel;
export function toVPNPortModel(from: VPNPortDTO[]): VPNPortModel[];
export function toVPNPortModel(from: Paginate<VPNPortDTO>): Paginate<VPNPortModel>;

export function toVPNPortModel(
  from: VPNPortDTO | VPNPortDTO[] | Paginate<VPNPortDTO>,
): VPNPortModel | VPNPortModel[] | Paginate<VPNPortModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toVPNPortModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toVPNPortModel(item)) };
  }

  const { number, ...rest } = from;

  return { ...rest, port: number };
}
