import { type MouseEvent, useCallback } from 'react';

import Stack from '@mui/material/Stack';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as ProxyServerOffIcon } from 'assets/icons/empty/proxy-server-off.svg';
import { Button } from 'components/Button';
import { Result } from 'components/Result';
import { useRouter } from 'hooks/useRouter';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { resetOrderFlow } from 'store/orders/reducers';

export function OrderDisabled() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const router = useRouter();

  const onReturn = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      dispatch(resetOrderFlow());

      router.replace(paths.index);
    },
    [dispatch, router],
  );

  return (
    <Stack flex="1 1 auto" alignItems="center" justifyContent="center">
      <Result
        icon={<ProxyServerOffIcon />}
        title={t('order.disabled.title')}
        subtitle={t('order.disabled.subtitle')}
        actions={
          <Button component={Link} to={paths.index} onClick={onReturn}>
            {t('order.disabled.action')}
          </Button>
        }
      />
    </Stack>
  );
}
