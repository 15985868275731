import type { Components, Theme } from '@mui/material/styles';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: { color: 'secondary', size: 'medium', disableRipple: true },
  styleOverrides: {
    root: {
      '--IconButton-padding': 0,

      padding: 'var(--IconButton-padding)',
    },

    colorPrimary: { color: 'var(--mui-palette-primary-main)' },
    colorSecondary: { color: 'var(--mui-palette-text-secondary)' },
    colorInfo: { color: 'var(--mui-palette-info-main)' },
    colorSuccess: { color: 'var(--mui-palette-success-main)' },
    colorWarning: { color: 'var(--mui-palette-warning-main)' },
    colorError: { color: 'var(--mui-palette-error-main)' },
  },
};
