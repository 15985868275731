/* eslint-disable react/no-unescaped-entities */
import { useEffect, useMemo } from 'react';

import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as NewEmailSvg } from 'assets/images/new-email.svg';
import { Banner } from 'components/Banner';
import { posthogEvents } from 'constants/posthogEvents';
import { useEmailVerification } from 'modules/accounts/hooks';

type EmailVerificationBannerProps = {
  horizontalLayout?: boolean;
  isOrder?: boolean;
};

export function EmailVerificationBanner({ horizontalLayout, isOrder }: EmailVerificationBannerProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const { isEmailVerificationRequired, session, isSessionLoading, handleResendVerificationEmail } =
    useEmailVerification(true);

  useEffect(() => {
    if (isEmailVerificationRequired && session?.email && isOrder) {
      posthog?.capture(posthogEvents.email.confirmation.displayed, { email: session.email });
    }
  }, [posthog, isEmailVerificationRequired, session?.email, isOrder]);

  const subtitle = useMemo(() => {
    if (isSessionLoading) {
      return <Skeleton width="100%" height={40} />;
    }

    return (
      <Typography
        variant="body-2"
        color={isOrder ? 'var(--mui-palette-text-primary)' : 'var(--mui-palette-neutral-400)'}
        textAlign={{ xs: 'center', md: horizontalLayout ? 'start' : 'center' }}
      >
        {isOrder ? (
          <Trans i18nKey="order.banners.emailVerification.subtitleOrder" email={session?.email}>
            To complete the order, please verify your email. We've sent a verification link to
            {{ email: session?.email }}. Didn't receive an email?
            <Link variant="body-2" color="primary.main" onClick={handleResendVerificationEmail}>
              Resend
            </Link>
          </Trans>
        ) : (
          <Trans i18nKey="order.banners.emailVerification.subtitle" email={session?.email}>
            We've sent a verification link to {{ email: session?.email }}
            Didn't receive an email?
            <Link variant="body-2" color="primary.main" onClick={handleResendVerificationEmail}>
              Resend
            </Link>
          </Trans>
        )}
      </Typography>
    );
  }, [handleResendVerificationEmail, horizontalLayout, isOrder, isSessionLoading, session?.email]);

  return (
    <Banner
      title={t('order.banners.emailVerification.title')}
      subtitle={subtitle}
      icon={<NewEmailSvg />}
      isVisible
      slotProps={{
        container: { gridTemplateColumns: '1fr' },
        content: { spacing: 1, alignItems: { xs: 'center', md: horizontalLayout ? 'flex-start' : 'center' } },
        contentWrapper: {
          direction: { xs: 'column', md: horizontalLayout ? 'row' : 'column' },
          alignItems: 'center',
        },
        title: { color: isOrder ? 'var(--mui-palette-text-primary)' : 'var(--mui-palette-common-white)' },
      }}
      sx={{
        '--Card-boxShadow': 'none',
        '--Card-background': isOrder ? 'var(--mui-palette-neutral-200)' : 'var(--mui-palette-primary-900)',
        '--Card-paddingBlock': { xs: '16px', md: horizontalLayout ? '32px' : '16px' },
        '--Card-paddingInline': { xs: '16px', md: horizontalLayout ? '32px' : '16px' },

        svg: {
          minWidth: 128,
          height: 128,

          circle: { stroke: isOrder ? 'var(--mui-palette-neutral-200)' : 'var(--mui-palette-primary-900)' },
        },
      }}
    />
  );
}
