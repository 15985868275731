import { inputClasses } from '@mui/material/Input';
import { inputBaseClasses } from '@mui/material/InputBase';
import type { Components, Theme } from '@mui/material/styles';

export const MuiInput: Components<Theme>['MuiInput'] = {
  styleOverrides: {
    root: {
      '--Input-borderColor': 'rgba(var(--mui-palette-text-primaryChannel) / 0.12)',
      '--Input-hover-borderColor': 'rgba(var(--mui-palette-primary-mainChannel) / 0.24)',

      '&::before': {
        borderColor: 'var(--Input-borderColor)',
      },

      [`&:hover:not(.${inputClasses.disabled}, .${inputClasses.error})::before`]: {
        borderBottom: '2px solid var(--Input-hover-borderColor)',
      },

      [`&.${inputClasses.disabled}::before`]: {
        borderBottomStyle: 'solid',
      },

      'label + &': { marginTop: 8 },
    },

    input: {
      padding: 0,
      height: 'auto',

      [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
        '&::placeholder': { opacity: '1 !important' },
      },

      '&:-webkit-autofill': {
        marginInline: 'calc(var(--Input-paddingInline) * -1)',
        paddingInline: 'var(--Input-paddingInline)',
        boxShadow: 'none',
        WebkitTextFillColor: 'var(--mui-palette-text-primary)',
        caretColor: 'var(--mui-palette-common-black)',
      },
    },

    inputAdornedEnd: {
      paddingRight: 'var(--Input-adorned-end-padding)',
    },

    inputAdornedStart: {
      paddingLeft: 'var(--Input-adorned-start-padding)',
    },
  },
};
