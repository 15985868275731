import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

function getFilledVars(color: string): Record<string, string> {
  const vars = {
    '--Chip-background': `var(--mui-palette-${color}-300)`,
    '--Chip-color': 'var(--mui-palette-text-primary)',
  };

  if (color === 'primary') {
    vars['--Chip-background'] = 'var(--mui-palette-primary-main)';
    vars['--Chip-color'] = 'var(--mui-palette-primary-contrastText)';
  }

  if (color === 'black') {
    vars['--Chip-background'] = 'var(--mui-palette-neutral-950)';
    vars['--Chip-color'] = 'var(--mui-palette-common-white)';
  }

  if (color === 'neutral') {
    vars['--Chip-background'] = 'var(--mui-palette-neutral-100)';
    vars['--Chip-color'] = 'var(--mui-palette-text-primary)';
  }

  return vars;
}

// TODO: Verify every Chip in the application
export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    color: 'neutral',
    variant: 'filled',
    size: 'small',
    deleteIcon: <Icon name="cross" size="small" color="var(--mui-palette-neutral-500)" />,
  },
  styleOverrides: {
    root: {
      '--Chip-borderRadius': '16px',
      '--Chip-minWidth': 'unset',
      '--Chip-paddingBlock': '4px',
      '--Chip-paddingInline': '0px',

      '--Chip-label-paddingBlock': '0px',
      '--Chip-label-paddingInline': '8px',

      height: 'auto',

      paddingBlock: 'var(--Chip-paddingBlock)',
      paddingInline: 'var(--Chip-paddingInline)',
      borderRadius: 'var(--Chip-borderRadius)',
      minHeight: 'var(--Chip-minHeight)',
      minWidth: 'var(--Chip-minWidth)',

      textTransform: 'uppercase',
      borderColor: 'var(--Chip-borderColor)',
      backgroundColor: 'var(--Chip-background)',
      color: 'var(--Chip-color)',
    },

    filledPrimary: getFilledVars('primary'),
    filledSecondary: getFilledVars('secondary'),
    filledNeutral: getFilledVars('neutral'),
    filledSuccess: getFilledVars('success'),
    filledError: getFilledVars('error'),
    filledWarning: getFilledVars('warning'),
    filledInfo: getFilledVars('info'),
    filledBlack: getFilledVars('black'),

    label: {
      paddingBlock: 'var(--Chip-label-paddingBlock)',
      paddingInline: 'var(--Chip-label-paddingInline)',

      fontSize: 'var(--Chip-label-fontSize)',
      fontWeight: 'var(--Chip-label-fontWeight)',
      lineHeight: 'var(--Chip-label-lineHeight)',
      letterSpacing: 'var(--Chip-label-letterSpacing)',
    },
    sizeSmall: {
      '--Chip-minHeight': '18px',
      '--Chip-paddingBlock': '2px',
      '--Chip-paddingInline': '0px',
      '--Chip-label-paddingBlock': '0px',
      '--Chip-label-paddingInline': '8px',

      '--Chip-label-fontSize': '10px',
      '--Chip-label-fontWeight': '500',
      '--Chip-label-lineHeight': '14px',
      '--Chip-label-letterSpacing': '0.08em',
    },
    sizeMedium: {
      '--Chip-minHeight': '24px',
      '--Chip-paddingBlock': '4px',
      '--Chip-paddingInline': '0px',
      '--Chip-label-paddingBlock': '0px',
      '--Chip-label-paddingInline': '12px',

      '--Chip-label-fontSize': '14px',
      '--Chip-label-fontWeight': '600',
      '--Chip-label-lineHeight': '20px',
      '--Chip-label-letterSpacing': '0',
    },
  },
};
