export enum MatcherType {
  Equals = 'equals',
  StartsWith = 'startsWith',
  Includes = 'includes',
  Or = 'or',
}

type BaseMatcher = {
  type: MatcherType.Equals | MatcherType.Includes | MatcherType.StartsWith;
  href: string;
};

type OrMatcher = {
  type: MatcherType.Or;
  href: string;
  orHref: string;
};

export type Matcher = BaseMatcher | OrMatcher;

type IsNavItemActiveArgs = {
  pathname: string;
  disabled?: boolean;
  external?: boolean;
  href?: string;
  matcher?: Matcher;
};

export function isNavItemActive({ pathname, disabled, external, href, matcher }: IsNavItemActiveArgs) {
  if (disabled || !href || external) return false;

  if (!matcher || matcher.type === MatcherType.Equals) return pathname === href;

  if (matcher.type === MatcherType.StartsWith) {
    return pathname.startsWith(matcher.href);
  }

  if (matcher.type === MatcherType.Includes) {
    return pathname.includes(matcher.href);
  }

  if (matcher.type === MatcherType.Or) {
    return pathname === matcher.href || pathname === matcher.orHref;
  }

  return false;
}
