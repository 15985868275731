import { autocompleteClasses } from '@mui/material/Autocomplete';
import { inputBaseClasses } from '@mui/material/InputBase';
import type { Components, Theme } from '@mui/material/styles';

export const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      '--FormLabel-color': 'var(--mui-palette-neutral-500)',
      '--FormLabel-fontSize': '16px',
      '--FormLabel-lineHeight': '24px',
      '--FormLabel-fontWeight': 700,

      color: 'var(--FormLabel-color)',
      fontSize: 'var(--FormLabel-fontSize)',
      lineHeight: 'var(--FormLabel-lineHeight)',
      fontWeight: 'var(--FormLabel-fontWeight)',

      [`&:has(+ .${inputBaseClasses.sizeSmall}), &:has(+ .${autocompleteClasses.root} .${inputBaseClasses.sizeSmall})`]:
        {
          '--FormLabel-fontSize': '14px',
          '--FormLabel-lineHeight': '20px',
        },
    },
  },
};
