import { filledInputClasses } from '@mui/material/FilledInput';
import { inputBaseClasses } from '@mui/material/InputBase';
import { selectClasses } from '@mui/material/Select';
import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: (props) => <Icon {...props} size="small" name="chevron-down" />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Select-select-paddingBlock': 0,
      '--Select-select-paddingInline': 0,
      '--Select-select-paddingRight': '24px',
      '--Select-icon-right': '16px',

      cursor: 'pointer',
      transition: theme.transitions.create('all', { duration: 225 }),

      [`&.${inputBaseClasses.root}`]: {
        paddingBlock: '0px',
        paddingInline: '0px',

        [`& .${selectClasses.select}`]: {
          display: 'inline-flex',
          alignItems: 'center',

          borderRadius: 'var(--Select-select-borderRadius)',
          paddingBlock: 'var(--Input-paddingBlock)',
          paddingInline: 'var(--Input-paddingInline)',

          paddingRight: 'calc(var(--Input-paddingInline) + var(--Select-select-paddingRight)) !important',

          [`&.${filledInputClasses.input}:focus`]: {
            backgroundColor: 'transparent',
          },
        },

        [`&.${inputBaseClasses.adornedEnd} .${selectClasses.select}`]: {
          paddingRight: 'var(--Input-adorned-end-padding) !important',
        },

        [`&.${inputBaseClasses.adornedStart} .${selectClasses.select}`]: {
          paddingLeft: 'var(--Input-adorned-start-padding) !important',
        },
      },

      [`&.${inputBaseClasses.sizeSmall}`]: {
        '--Select-select-height': '16px',
        '--Select-icon-right': '10px',
      },

      'label + &': { marginTop: 8 },
    }),

    icon: ({ theme }) => ({
      transition: theme.transitions.create(['transform'], { duration: 300 }),

      right: 'var(--Select-icon-right)',
    }),
  },
};
