import { type FieldProps, getIn, Field } from 'formik';

import {
  NumberInput as BaseNumberInput,
  type NumberInputProps as BaseNumberInputProps,
} from 'components/form-partials/base';

type NumberInputProps<Values extends object = object> = FieldProps<string, Values> &
  Omit<BaseNumberInputProps, 'hasError' | 'error' | 'value'> & { name: string };

function fieldToNumberInput<Values extends object = object>({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue },
  onBlur,
  onChange,
  ...props
}: NumberInputProps<Values>): BaseNumberInputProps {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  return {
    hasError,
    error,
    disabled: disabled ?? isSubmitting,
    onBlur: (e) => {
      if (onBlur) return onBlur(e);

      return fieldOnBlur(e ?? field.name);
    },
    onChange: (args) => {
      if (onChange) return onChange(args);

      const { value } = args;

      const parsedValue = parseFloat(value);

      return setFieldValue(field.name, parsedValue);
    },
    ...field,
    ...props,
  };
}

function NumberInput<Values extends object = object>(props: NumberInputProps<Values>) {
  return <BaseNumberInput {...fieldToNumberInput(props)} />;
}

export type FormikNumberInputProps<Values extends object = object> = Omit<
  NumberInputProps<Values>,
  'form' | 'meta' | 'field'
>;

export function FormikNumberInput<Values extends object = object>(props: FormikNumberInputProps<Values>) {
  return <Field component={NumberInput} {...props} />;
}
