import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function useAdminModal() {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletDevice = useMediaQuery(theme.breakpoints.down('md'));

  const maxModalWidth = useMemo(() => {
    if (isMobileDevice) return '80% !important';

    if (isTabletDevice) return `${theme.breakpoints.values.sm}px !important`;

    return `${theme.breakpoints.values.md}px !important`;
  }, [isMobileDevice, isTabletDevice, theme.breakpoints.values.md, theme.breakpoints.values.sm]);

  return useMemo(() => ({ maxModalWidth }), [maxModalWidth]);
}
