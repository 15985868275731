import type { Option } from 'types';

export const COUNTRIES: Array<Option<string>> = [
  { label: 'countriesregions:countries.AF', value: 'AF' },
  { label: 'countriesregions:countries.AX', value: 'AX' },
  { label: 'countriesregions:countries.AL', value: 'AL' },
  { label: 'countriesregions:countries.DZ', value: 'DZ' },
  { label: 'countriesregions:countries.AS', value: 'AS' },
  { label: 'countriesregions:countries.AD', value: 'AD' },
  { label: 'countriesregions:countries.AO', value: 'AO' },
  { label: 'countriesregions:countries.AI', value: 'AI' },
  { label: 'countriesregions:countries.AQ', value: 'AQ' },
  { label: 'countriesregions:countries.AG', value: 'AG' },
  { label: 'countriesregions:countries.AR', value: 'AR' },
  { label: 'countriesregions:countries.AM', value: 'AM' },
  { label: 'countriesregions:countries.AW', value: 'AW' },
  { label: 'countriesregions:countries.AU', value: 'AU' },
  { label: 'countriesregions:countries.AT', value: 'AT' },
  { label: 'countriesregions:countries.AZ', value: 'AZ' },
  { label: 'countriesregions:countries.BS', value: 'BS' },
  { label: 'countriesregions:countries.BH', value: 'BH' },
  { label: 'countriesregions:countries.BD', value: 'BD' },
  { label: 'countriesregions:countries.BB', value: 'BB' },
  { label: 'countriesregions:countries.BY', value: 'BY' },
  { label: 'countriesregions:countries.BE', value: 'BE' },
  { label: 'countriesregions:countries.BZ', value: 'BZ' },
  { label: 'countriesregions:countries.BJ', value: 'BJ' },
  { label: 'countriesregions:countries.BM', value: 'BM' },
  { label: 'countriesregions:countries.BT', value: 'BT' },
  { label: 'countriesregions:countries.BO', value: 'BO' },
  { label: 'countriesregions:countries.BA', value: 'BA' },
  { label: 'countriesregions:countries.BW', value: 'BW' },
  { label: 'countriesregions:countries.BV', value: 'BV' },
  { label: 'countriesregions:countries.BR', value: 'BR' },
  { label: 'countriesregions:countries.IO', value: 'IO' },
  { label: 'countriesregions:countries.VG', value: 'VG' },
  { label: 'countriesregions:countries.BN', value: 'BN' },
  { label: 'countriesregions:countries.BG', value: 'BG' },
  { label: 'countriesregions:countries.BF', value: 'BF' },
  { label: 'countriesregions:countries.BI', value: 'BI' },
  { label: 'countriesregions:countries.KH', value: 'KH' },
  { label: 'countriesregions:countries.CM', value: 'CM' },
  { label: 'countriesregions:countries.CA', value: 'CA' },
  { label: 'countriesregions:countries.CV', value: 'CV' },
  { label: 'countriesregions:countries.BQ', value: 'BQ' },
  { label: 'countriesregions:countries.KY', value: 'KY' },
  { label: 'countriesregions:countries.CF', value: 'CF' },
  { label: 'countriesregions:countries.TD', value: 'TD' },
  { label: 'countriesregions:countries.CL', value: 'CL' },
  { label: 'countriesregions:countries.CN', value: 'CN' },
  { label: 'countriesregions:countries.CX', value: 'CX' },
  { label: 'countriesregions:countries.CC', value: 'CC' },
  { label: 'countriesregions:countries.CO', value: 'CO' },
  { label: 'countriesregions:countries.KM', value: 'KM' },
  { label: 'countriesregions:countries.CG', value: 'CG' },
  { label: 'countriesregions:countries.CD', value: 'CD' },
  { label: 'countriesregions:countries.CK', value: 'CK' },
  { label: 'countriesregions:countries.CR', value: 'CR' },
  { label: 'countriesregions:countries.CI', value: 'CI' },
  { label: 'countriesregions:countries.HR', value: 'HR' },
  { label: 'countriesregions:countries.CU', value: 'CU' },
  { label: 'countriesregions:countries.CW', value: 'CW' },
  { label: 'countriesregions:countries.CY', value: 'CY' },
  { label: 'countriesregions:countries.CZ', value: 'CZ' },
  { label: 'countriesregions:countries.DK', value: 'DK' },
  { label: 'countriesregions:countries.DJ', value: 'DJ' },
  { label: 'countriesregions:countries.DM', value: 'DM' },
  { label: 'countriesregions:countries.DO', value: 'DO' },
  { label: 'countriesregions:countries.EC', value: 'EC' },
  { label: 'countriesregions:countries.EG', value: 'EG' },
  { label: 'countriesregions:countries.SV', value: 'SV' },
  { label: 'countriesregions:countries.GQ', value: 'GQ' },
  { label: 'countriesregions:countries.ER', value: 'ER' },
  { label: 'countriesregions:countries.EE', value: 'EE' },
  { label: 'countriesregions:countries.SZ', value: 'SZ' },
  { label: 'countriesregions:countries.ET', value: 'ET' },
  { label: 'countriesregions:countries.FK', value: 'FK' },
  { label: 'countriesregions:countries.FO', value: 'FO' },
  { label: 'countriesregions:countries.FJ', value: 'FJ' },
  { label: 'countriesregions:countries.FI', value: 'FI' },
  { label: 'countriesregions:countries.FR', value: 'FR' },
  { label: 'countriesregions:countries.GF', value: 'GF' },
  { label: 'countriesregions:countries.PF', value: 'PF' },
  { label: 'countriesregions:countries.TF', value: 'TF' },
  { label: 'countriesregions:countries.GA', value: 'GA' },
  { label: 'countriesregions:countries.GM', value: 'GM' },
  { label: 'countriesregions:countries.GE', value: 'GE' },
  { label: 'countriesregions:countries.DE', value: 'DE' },
  { label: 'countriesregions:countries.GH', value: 'GH' },
  { label: 'countriesregions:countries.GI', value: 'GI' },
  { label: 'countriesregions:countries.GR', value: 'GR' },
  { label: 'countriesregions:countries.GL', value: 'GL' },
  { label: 'countriesregions:countries.GD', value: 'GD' },
  { label: 'countriesregions:countries.GP', value: 'GP' },
  { label: 'countriesregions:countries.GU', value: 'GU' },
  { label: 'countriesregions:countries.GT', value: 'GT' },
  { label: 'countriesregions:countries.GG', value: 'GG' },
  { label: 'countriesregions:countries.GN', value: 'GN' },
  { label: 'countriesregions:countries.GW', value: 'GW' },
  { label: 'countriesregions:countries.GY', value: 'GY' },
  { label: 'countriesregions:countries.HT', value: 'HT' },
  { label: 'countriesregions:countries.HM', value: 'HM' },
  { label: 'countriesregions:countries.HN', value: 'HN' },
  { label: 'countriesregions:countries.HK', value: 'HK' },
  { label: 'countriesregions:countries.HU', value: 'HU' },
  { label: 'countriesregions:countries.IS', value: 'IS' },
  { label: 'countriesregions:countries.IN', value: 'IN' },
  { label: 'countriesregions:countries.ID', value: 'ID' },
  { label: 'countriesregions:countries.IR', value: 'IR' },
  { label: 'countriesregions:countries.IQ', value: 'IQ' },
  { label: 'countriesregions:countries.IE', value: 'IE' },
  { label: 'countriesregions:countries.IM', value: 'IM' },
  { label: 'countriesregions:countries.IL', value: 'IL' },
  { label: 'countriesregions:countries.IT', value: 'IT' },
  { label: 'countriesregions:countries.JM', value: 'JM' },
  { label: 'countriesregions:countries.JP', value: 'JP' },
  { label: 'countriesregions:countries.JE', value: 'JE' },
  { label: 'countriesregions:countries.JO', value: 'JO' },
  { label: 'countriesregions:countries.KZ', value: 'KZ' },
  { label: 'countriesregions:countries.KE', value: 'KE' },
  { label: 'countriesregions:countries.KI', value: 'KI' },
  { label: 'countriesregions:countries.KW', value: 'KW' },
  { label: 'countriesregions:countries.KG', value: 'KG' },
  { label: 'countriesregions:countries.LA', value: 'LA' },
  { label: 'countriesregions:countries.LV', value: 'LV' },
  { label: 'countriesregions:countries.LB', value: 'LB' },
  { label: 'countriesregions:countries.LS', value: 'LS' },
  { label: 'countriesregions:countries.LR', value: 'LR' },
  { label: 'countriesregions:countries.LY', value: 'LY' },
  { label: 'countriesregions:countries.LI', value: 'LI' },
  { label: 'countriesregions:countries.LT', value: 'LT' },
  { label: 'countriesregions:countries.LU', value: 'LU' },
  { label: 'countriesregions:countries.MO', value: 'MO' },
  { label: 'countriesregions:countries.MG', value: 'MG' },
  { label: 'countriesregions:countries.MW', value: 'MW' },
  { label: 'countriesregions:countries.MY', value: 'MY' },
  { label: 'countriesregions:countries.MV', value: 'MV' },
  { label: 'countriesregions:countries.ML', value: 'ML' },
  { label: 'countriesregions:countries.MT', value: 'MT' },
  { label: 'countriesregions:countries.MH', value: 'MH' },
  { label: 'countriesregions:countries.MQ', value: 'MQ' },
  { label: 'countriesregions:countries.MR', value: 'MR' },
  { label: 'countriesregions:countries.MU', value: 'MU' },
  { label: 'countriesregions:countries.YT', value: 'YT' },
  { label: 'countriesregions:countries.MX', value: 'MX' },
  { label: 'countriesregions:countries.FM', value: 'FM' },
  { label: 'countriesregions:countries.MD', value: 'MD' },
  { label: 'countriesregions:countries.MC', value: 'MC' },
  { label: 'countriesregions:countries.MN', value: 'MN' },
  { label: 'countriesregions:countries.ME', value: 'ME' },
  { label: 'countriesregions:countries.MS', value: 'MS' },
  { label: 'countriesregions:countries.MA', value: 'MA' },
  { label: 'countriesregions:countries.MZ', value: 'MZ' },
  { label: 'countriesregions:countries.MM', value: 'MM' },
  { label: 'countriesregions:countries.NA', value: 'NA' },
  { label: 'countriesregions:countries.NR', value: 'NR' },
  { label: 'countriesregions:countries.NP', value: 'NP' },
  { label: 'countriesregions:countries.NL', value: 'NL' },
  { label: 'countriesregions:countries.NC', value: 'NC' },
  { label: 'countriesregions:countries.NZ', value: 'NZ' },
  { label: 'countriesregions:countries.NI', value: 'NI' },
  { label: 'countriesregions:countries.NE', value: 'NE' },
  { label: 'countriesregions:countries.NG', value: 'NG' },
  { label: 'countriesregions:countries.NU', value: 'NU' },
  { label: 'countriesregions:countries.NF', value: 'NF' },
  { label: 'countriesregions:countries.KP', value: 'KP' },
  { label: 'countriesregions:countries.MK', value: 'MK' },
  { label: 'countriesregions:countries.MP', value: 'MP' },
  { label: 'countriesregions:countries.NO', value: 'NO' },
  { label: 'countriesregions:countries.OM', value: 'OM' },
  { label: 'countriesregions:countries.PK', value: 'PK' },
  { label: 'countriesregions:countries.PW', value: 'PW' },
  { label: 'countriesregions:countries.PS', value: 'PS' },
  { label: 'countriesregions:countries.PA', value: 'PA' },
  { label: 'countriesregions:countries.PG', value: 'PG' },
  { label: 'countriesregions:countries.PY', value: 'PY' },
  { label: 'countriesregions:countries.PE', value: 'PE' },
  { label: 'countriesregions:countries.PH', value: 'PH' },
  { label: 'countriesregions:countries.PN', value: 'PN' },
  { label: 'countriesregions:countries.PL', value: 'PL' },
  { label: 'countriesregions:countries.PT', value: 'PT' },
  { label: 'countriesregions:countries.PR', value: 'PR' },
  { label: 'countriesregions:countries.QA', value: 'QA' },
  { label: 'countriesregions:countries.RE', value: 'RE' },
  { label: 'countriesregions:countries.RO', value: 'RO' },
  { label: 'countriesregions:countries.RU', value: 'RU' },
  { label: 'countriesregions:countries.RW', value: 'RW' },
  { label: 'countriesregions:countries.WS', value: 'WS' },
  { label: 'countriesregions:countries.SM', value: 'SM' },
  { label: 'countriesregions:countries.ST', value: 'ST' },
  { label: 'countriesregions:countries.SA', value: 'SA' },
  { label: 'countriesregions:countries.SN', value: 'SN' },
  { label: 'countriesregions:countries.RS', value: 'RS' },
  { label: 'countriesregions:countries.SC', value: 'SC' },
  { label: 'countriesregions:countries.SL', value: 'SL' },
  { label: 'countriesregions:countries.SG', value: 'SG' },
  { label: 'countriesregions:countries.SX', value: 'SX' },
  { label: 'countriesregions:countries.SK', value: 'SK' },
  { label: 'countriesregions:countries.SI', value: 'SI' },
  { label: 'countriesregions:countries.SB', value: 'SB' },
  { label: 'countriesregions:countries.SO', value: 'SO' },
  { label: 'countriesregions:countries.ZA', value: 'ZA' },
  { label: 'countriesregions:countries.GS', value: 'GS' },
  { label: 'countriesregions:countries.KR', value: 'KR' },
  { label: 'countriesregions:countries.SS', value: 'SS' },
  { label: 'countriesregions:countries.ES', value: 'ES' },
  { label: 'countriesregions:countries.LK', value: 'LK' },
  { label: 'countriesregions:countries.BL', value: 'BL' },
  { label: 'countriesregions:countries.SH', value: 'SH' },
  { label: 'countriesregions:countries.KN', value: 'KN' },
  { label: 'countriesregions:countries.LC', value: 'LC' },
  { label: 'countriesregions:countries.MF', value: 'MF' },
  { label: 'countriesregions:countries.PM', value: 'PM' },
  { label: 'countriesregions:countries.VC', value: 'VC' },
  { label: 'countriesregions:countries.SD', value: 'SD' },
  { label: 'countriesregions:countries.SR', value: 'SR' },
  { label: 'countriesregions:countries.SJ', value: 'SJ' },
  { label: 'countriesregions:countries.SE', value: 'SE' },
  { label: 'countriesregions:countries.CH', value: 'CH' },
  { label: 'countriesregions:countries.SY', value: 'SY' },
  { label: 'countriesregions:countries.TW', value: 'TW' },
  { label: 'countriesregions:countries.TJ', value: 'TJ' },
  { label: 'countriesregions:countries.TZ', value: 'TZ' },
  { label: 'countriesregions:countries.TH', value: 'TH' },
  { label: 'countriesregions:countries.TL', value: 'TL' },
  { label: 'countriesregions:countries.TG', value: 'TG' },
  { label: 'countriesregions:countries.TK', value: 'TK' },
  { label: 'countriesregions:countries.TO', value: 'TO' },
  { label: 'countriesregions:countries.TT', value: 'TT' },
  { label: 'countriesregions:countries.TN', value: 'TN' },
  { label: 'countriesregions:countries.TR', value: 'TR' },
  { label: 'countriesregions:countries.TM', value: 'TM' },
  { label: 'countriesregions:countries.TC', value: 'TC' },
  { label: 'countriesregions:countries.TV', value: 'TV' },
  { label: 'countriesregions:countries.UM', value: 'UM' },
  { label: 'countriesregions:countries.VI', value: 'VI' },
  { label: 'countriesregions:countries.UG', value: 'UG' },
  { label: 'countriesregions:countries.UA', value: 'UA' },
  { label: 'countriesregions:countries.AE', value: 'AE' },
  { label: 'countriesregions:countries.GB', value: 'GB' },
  { label: 'countriesregions:countries.US', value: 'US' },
  { label: 'countriesregions:countries.UY', value: 'UY' },
  { label: 'countriesregions:countries.UZ', value: 'UZ' },
  { label: 'countriesregions:countries.VU', value: 'VU' },
  { label: 'countriesregions:countries.VA', value: 'VA' },
  { label: 'countriesregions:countries.VE', value: 'VE' },
  { label: 'countriesregions:countries.VN', value: 'VN' },
  { label: 'countriesregions:countries.WF', value: 'WF' },
  { label: 'countriesregions:countries.EH', value: 'EH' },
  { label: 'countriesregions:countries.YE', value: 'YE' },
  { label: 'countriesregions:countries.ZM', value: 'ZM' },
  { label: 'countriesregions:countries.ZW', value: 'ZW' },
];

export const COUNTRIES_BY_CODE = COUNTRIES.reduce<Record<string, Option<string>>>((acc, { label, value }) => {
  return { ...acc, [value]: { label, value } };
}, {});
