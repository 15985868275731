import { type MouseEvent, useCallback } from 'react';

import Box from '@mui/material/Box';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { usePostHog } from 'posthog-js/react';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { RouterLink } from 'components/RouterLink';
import { posthogEvents } from 'constants/posthogEvents';
import { paths } from 'paths';
import { useAppSelector } from 'store';

type AppSwitchMenuProps = Omit<MenuProps, 'onClose'> & {
  onClose: () => void;
};

export function AppSwitchMenu({ onClose, ...props }: AppSwitchMenuProps) {
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const posthog = usePostHog();

  const onVPNClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      posthog.capture(posthogEvents.common.switchedToVPNCheap);

      window.location.href = `${process.env.REACT_APP_VPN_CHEAP_APP_URL}?access_token=${accessToken}&refresh_token=${refreshToken}`;
    },
    [accessToken, posthog, refreshToken],
  );

  return (
    <Menu {...props} slotProps={{ paper: { sx: { width: '247px' } } }} onClick={onClose}>
      <MenuItem component={RouterLink} href={paths.index}>
        <Stack direction="row" flex="1 1 auto" spacing={2} alignItems="center" justifyContent="space-between">
          <Box display="inline-flex">
            <Logo menu />
          </Box>

          <Icon name="check" size={16} color="var(--mui-palette-primary-main)" />
        </Stack>
      </MenuItem>

      <MenuItem component={RouterLink} href={process.env.REACT_APP_VPN_CHEAP_APP_URL} onClick={onVPNClick}>
        <Stack direction="row" flex="1 1 auto" spacing={2} alignItems="center" justifyContent="space-between">
          <Box display="inline-flex">
            <Logo vpn />
          </Box>
        </Stack>
      </MenuItem>
    </Menu>
  );
}
