import { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';
import { matchPath, useLocation } from 'react-router-dom';

import { paths } from 'paths';
import { useGetIsProxyReplacementAvailableQuery, useGetMaintenanceWindowsQuery } from 'store/api';
import { toDashboardMaintenanceStatus } from 'utils/maintenance';

export function useMaintenance() {
  const { pathname } = useLocation();

  const {
    data: maintenance,
    fulfilledTimeStamp: maintenanceFulfilledTimeStamp,
    refetch: getMaintenance,
  } = useGetMaintenanceWindowsQuery();
  const {
    data: isProxyReplacementAvailable,
    fulfilledTimeStamp: replacementAvailabilityFulfilledTimeStamp,
    refetch: getReplacementAvailability,
  } = useGetIsProxyReplacementAvailableQuery();

  const closestWindow = useMemo(() => {
    if (!maintenance) return null;

    return toDashboardMaintenanceStatus(maintenance);
  }, [maintenance]);

  const shouldDisplayMaintenanceBanner = useMemo(() => {
    if (!closestWindow) return false;

    return !(!closestWindow.end || !closestWindow.start);
  }, [closestWindow]);

  const shouldDisplayBanner = useMemo(() => {
    return shouldDisplayMaintenanceBanner || !!isProxyReplacementAvailable;
  }, [isProxyReplacementAvailable, shouldDisplayMaintenanceBanner]);

  const shouldRefetchMaintenanceWindow = useMemo(() => {
    const fiveMinutesAhead = dayjs(maintenanceFulfilledTimeStamp).add(5, 'minutes');

    const isTimeElapsed = dayjs().isAfter(fiveMinutesAhead);

    return (
      isTimeElapsed &&
      [paths.proxies.index, `${paths.proxies.index}/:proxyId`, paths.index].some((value) => matchPath(value, pathname))
    );
  }, [maintenanceFulfilledTimeStamp, pathname]);

  const shouldRefetchReplacementAvailability = useMemo(() => {
    const fiveMinutesAhead = dayjs(replacementAvailabilityFulfilledTimeStamp).add(5, 'minutes');

    const isTimeElapsed = dayjs().isAfter(fiveMinutesAhead);

    return (
      isTimeElapsed &&
      [paths.proxies.index, `${paths.proxies.index}/:proxyId`, paths.index].some((value) => matchPath(value, pathname))
    );
  }, [pathname, replacementAvailabilityFulfilledTimeStamp]);

  useEffect(() => {
    if (!shouldRefetchMaintenanceWindow) return;

    getMaintenance();
  }, [getMaintenance, shouldRefetchMaintenanceWindow]);

  useEffect(() => {
    if (!shouldRefetchReplacementAvailability) return;

    getReplacementAvailability();
  }, [getReplacementAvailability, shouldRefetchReplacementAvailability]);

  return useMemo(
    () => ({ shouldDisplayBanner, maintenance: closestWindow, isProxyReplacementAvailable }),
    [closestWindow, isProxyReplacementAvailable, shouldDisplayBanner],
  );
}
