import { alpha } from '@mui/material/styles';
import type { PaletteRange } from '@mui/material/styles/createPalette';

export const neutral: PaletteRange = {
  50: '#EDF0F2',
  100: '#DFE3E6',
  200: '#B5C0CA',
  300: '#91A1B1',
  400: '#6D8397',
  500: '#526373',
  600: '#424F5C',
  700: '#313B45',
  800: '#20262D',
  900: '#111518',
  950: '#080A0C',
};

export const yellow: PaletteRange = {
  50: '#FFFBEB',
  100: '#FFF7D6',
  200: '#FFEEA8',
  300: '#FFE680',
  400: '#FFDC52',
  500: '#FFD429',
  600: '#EBBC00',
  700: '#B38F00',
  800: '#755E00',
  900: '#3D3100',
  950: '#1F1800',
};

export const orange: PaletteRange = {
  50: '#FFF5EB',
  100: '#FFEAD1',
  200: '#FFD4A3',
  300: '#FFC17A',
  400: '#FFAC4D',
  500: '#FF961F',
  600: '#E67A00',
  700: '#AD5C00',
  800: '#703C00',
  900: '#381E00',
  950: '#1F1000',
};

export const green: PaletteRange = {
  50: '#EBFFF6',
  100: '#DBFFEF',
  200: '#B8FFDF',
  300: '#94FFCF',
  400: '#70FFBF',
  500: '#4AFFAD',
  600: '#0AFF91',
  700: '#00C76D',
  800: '#008549',
  900: '#004224',
  950: '#001F11',
};

export const cyan: PaletteRange = {
  50: '#EBFDFF',
  100: '#DBFBFF',
  200: '#B8F7FF',
  300: '#8FF2FF',
  400: '#6BEEFF',
  500: '#47E9FF',
  600: '#05E2FF',
  700: '#00ABC2',
  800: '#007585',
  900: '#003B42',
  950: '#001B1F',
};

export const blue: PaletteRange = {
  50: '#E6EEFF',
  100: '#CDDCFE',
  200: '#9BBAFD',
  300: '#6897FD',
  400: '#3675FC',
  500: '#0452FB',
  600: '#0342C9',
  700: '#023197',
  800: '#022164',
  900: '#011032',
  950: '#000819',
};

export const magenta: PaletteRange = {
  50: '#FFF0FD',
  100: '#FFE0FC',
  200: '#FFC7F9',
  300: '#FFA8F6',
  400: '#FF8FF4',
  500: '#FF70F1',
  600: '#FF29EA',
  700: '#DB00C5',
  800: '#940085',
  900: '#470040',
  950: '#240020',
};

export const lavaRed: PaletteRange = {
  50: '#FFEDEB',
  100: '#FFEDEB',
  200: '#FFC0B8',
  300: '#FFA094',
  400: '#FF8170',
  500: '#FF604A',
  600: '#FF270A',
  700: '#C71700',
  800: '#850F00',
  900: '#420800',
  950: '#1F0400',
};

export const secondary: PaletteRange = {
  50: alpha('#0452FB', 0.0), // Unused
  100: alpha('#0452FB', 0.01),
  200: alpha('#0452FB', 0.02),
  300: alpha('#0452FB', 0.04),
  400: alpha('#0452FB', 0.08),
  500: alpha('#0452FB', 0.12),
  600: alpha('#0452FB', 0.16),
  700: alpha('#0452FB', 0.2),
  800: alpha('#0452FB', 0.24),
  900: alpha('#0452FB', 0.48),
  950: alpha('#0452FB', 0.52),
};
