import type { Paginate } from 'types';
import { isPaginatedDTO } from 'utils/guards';

import type {
  BankDetailsDTO,
  PayoutRequestDTO,
  ReferralsDetailsDTO,
  ReferralTransactionDTO,
  ReferredUserDTO,
} from './dtos';
import type {
  BankDetailsModel,
  PayoutRequestModel,
  ReferralsDetailsModel,
  ReferralTransactionModel,
  ReferredUserModel,
} from './models';

export function toBankDetailsModel(from: BankDetailsDTO): BankDetailsModel;
export function toBankDetailsModel(from: BankDetailsDTO[]): BankDetailsModel[];

export function toBankDetailsModel(from: BankDetailsDTO | BankDetailsDTO[]): BankDetailsModel | BankDetailsModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toBankDetailsModel(item));
  }

  return from;
}

export function toPayoutRequestModel(from: PayoutRequestDTO): PayoutRequestModel;
export function toPayoutRequestModel(from: PayoutRequestDTO[]): PayoutRequestModel[];
export function toPayoutRequestModel(from: Paginate<PayoutRequestDTO>): Paginate<PayoutRequestModel>;

export function toPayoutRequestModel(
  from: PayoutRequestDTO | PayoutRequestDTO[] | Paginate<PayoutRequestDTO>,
): PayoutRequestModel | PayoutRequestModel[] | Paginate<PayoutRequestModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toPayoutRequestModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toPayoutRequestModel(item)) };
  }

  return from;
}

export function toReferralTransactionModel(from: ReferralTransactionDTO): ReferralTransactionModel;
export function toReferralTransactionModel(from: ReferralTransactionDTO[]): ReferralTransactionModel[];
export function toReferralTransactionModel(from: Paginate<ReferralTransactionDTO>): Paginate<ReferralTransactionModel>;

export function toReferralTransactionModel(
  from: ReferralTransactionDTO | ReferralTransactionDTO[] | Paginate<ReferralTransactionDTO>,
): ReferralTransactionModel | ReferralTransactionModel[] | Paginate<ReferralTransactionModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toReferralTransactionModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toReferralTransactionModel(item)) };
  }

  return from;
}

export function toReferralsDetailsModel(from: ReferralsDetailsDTO): ReferralsDetailsModel {
  return from;
}

export function toReferredUserModel(from: ReferredUserDTO): ReferredUserModel;
export function toReferredUserModel(from: ReferredUserDTO[]): ReferredUserModel[];
export function toReferredUserModel(from: Paginate<ReferredUserDTO>): Paginate<ReferredUserModel>;

export function toReferredUserModel(
  from: ReferredUserDTO | ReferredUserDTO[] | Paginate<ReferredUserDTO>,
): ReferredUserModel | ReferredUserModel[] | Paginate<ReferredUserModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toReferredUserModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toReferredUserModel(item)) };
  }

  return from;
}
