/* eslint-disable react/no-array-index-key */
import { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormikInput } from 'components/form-partials/formik';
import { Icon } from 'components/Icon';
import { useSession } from 'hooks/useSession';
import { OrderFieldTooltip } from 'modules/orders/OrderFieldTooltip';
import { useGetProxyIPWhitelistQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';

type IPWhitelistFormProps = {
  proxyIds: Array<ProxyModel['id']>;
};

export function IPWhitelistForm({ proxyIds }: IPWhitelistFormProps) {
  const { t } = useTranslation();
  const { session } = useSession();
  const { values, setFieldValue, errors } = useFormikContext<{ ipWhitelist: string[] }>();
  const { data: whitelistData } = useGetProxyIPWhitelistQuery(proxyIds[0], { skip: proxyIds.length > 1 });

  const MAX_IPS = useMemo(() => whitelistData?.allowedWhitelistIpCount || 1, [whitelistData]);

  const handleAddNewIPField = useCallback(() => {
    setFieldValue('ipWhitelist', values.ipWhitelist.concat(['']));
  }, [values.ipWhitelist, setFieldValue]);

  const setIP = useCallback(() => {
    const isAnyFieldEmpty = values.ipWhitelist.some((item) => !item.length);

    if (!isAnyFieldEmpty) return setFieldValue('ipWhitelist[0]', session?.userIp || '');

    const emptyIndex = values.ipWhitelist.findIndex((item) => !item.length);

    return setFieldValue(`ipWhitelist[${emptyIndex}]`, session?.userIp || '');
  }, [values, setFieldValue, session]);

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item>
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <Grid container direction="column" rowSpacing={4}>
              {values.ipWhitelist.map((_, index) => (
                <Grid item key={`ip-whitelist-${index}`} display="flex">
                  <FormikInput fullWidth name={`ipWhitelist[${index}]`} />

                  {index > 0 ? (
                    <IconButton
                      sx={{ ml: 2, p: 0 }}
                      disableRipple
                      onClick={() =>
                        setFieldValue(
                          'ipWhitelist',
                          values.ipWhitelist.filter((__, itemIndex) => itemIndex !== index),
                        )
                      }
                    >
                      <Icon name="minus-circle" size="small" color="#FF604A" />
                    </IconButton>
                  ) : (
                    <OrderFieldTooltip title={t('order.tooltips.ipWhitelist')} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {values.ipWhitelist.length < MAX_IPS && (
            <Grid item>
              <Link variant="caption" color="primary.main" onClick={handleAddNewIPField} sx={{ cursor: 'pointer' }}>
                {t('common:buttons.addAnother')}
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      {session?.userIp && (
        <Grid item>
          <Link variant="caption" color="#707D96" onClick={setIP} underline="always" sx={{ cursor: 'pointer' }}>
            {t('common:buttons.setYourCurrentIp', { ip: session.userIp })}
          </Link>
        </Grid>
      )}
      {getIn(errors, 'globalError') && (
        <Grid item>
          <Typography variant="body-2" color="error.main">
            {getIn(errors, 'globalError')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
