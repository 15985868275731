import { uppercaseTypeToEnumKey } from 'utils/string';

import { PAYMENT_TYPE_DOES_NOT_NEED_PAYMENT_ELEMENT_RENDERED } from './constants';
import { type PaymentMetadata, PaymentOption, type PaymentOptionType, type PaymentTokenMetadata } from './types';

/**
 * Converts PaymentOptionType to PaymentOption
 * By default this function returns `WALLET`
 *
 * This function is used to define the selected payment option.
 * Converts the in-app model to API model.
 *
 * @param {PaymentOptionType} from
 * @returns {PaymentOption}
 */
export function toPaymentOption(from: PaymentOptionType): PaymentOption {
  if (from === 'new-card' || from === 'another-card' || from === 'existing-card') {
    return PaymentOption.STRIPE;
  }

  if (from === 'balance') {
    return PaymentOption.WALLET;
  }

  return PaymentOption[uppercaseTypeToEnumKey(from)];
}

/**
 * Defines if the payment method should be handled with <Stripe /> component
 *
 * @param {PaymentOptionType} type
 * @returns {boolean}
 */
export function shouldBeHandledByStripe(type: PaymentOptionType): boolean {
  return type !== 'crypto' && type !== 'balance';
}

/**
 * Defines if the payment element should be rendered
 *
 * @param {PaymentOptionType} type
 * @returns {boolean}
 */
export function shouldRenderPaymentElement(type: PaymentOptionType): boolean {
  return !PAYMENT_TYPE_DOES_NOT_NEED_PAYMENT_ELEMENT_RENDERED.includes(type);
}

/**
 * Typescript guard to obtain proper payment metadata configuration type
 *
 * @param {PaymentMetadata} options
 * @returns {boolean}
 */
export function isTokenPaymentMetadata(options: PaymentMetadata): options is PaymentTokenMetadata {
  return options.type === 'token';
}
