import { forwardRef, useMemo } from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

import type { LiteralNodeUnion, LiteralNumberUnion } from 'types';

import { icons } from './icons';

export type IconName = keyof typeof icons;
export type IconSize = 'small' | 'medium' | 'large';

export function isIconName(name: LiteralNodeUnion<IconName>): name is IconName {
  return typeof name === 'string' && Object.keys(icons).includes(name);
}

export type IconProps = Omit<SvgIconProps, 'size' | 'color' | 'ref' | 'sx'> & {
  name: IconName;
  color?: BoxProps['color'];
  size?: LiteralNumberUnion<IconSize>;
  sx?: BoxProps['sx'];
};

export const Icon = forwardRef<HTMLDivElement, IconProps>(
  ({ name, size, width: widthProp = 32, height: heightProp = 32, color, className, sx, fill, ...props }, ref) => {
    const IconComponent = useMemo(() => icons[name], [name]);

    const { height, minWidth, width } = useMemo(() => {
      if (typeof size === 'number') return { width: size, height: size, minWidth: size };

      if (size === 'small') {
        return {
          width: 'var(--icon-fontSize-sm)',
          height: 'var(--icon-fontSize-sm)',
          minWidth: 'var(--icon-fontSize-sm)',
        };
      }

      if (size === 'medium') {
        return {
          width: 'var(--icon-fontSize-md)',
          height: 'var(--icon-fontSize-md)',
          minWidth: 'var(--icon-fontSize-md)',
        };
      }

      return {
        width: 'var(--icon-fontSize-lg)',
        height: 'var(--icon-fontSize-lg)',
        minWidth: 'var(--icon-fontSize-lg)',
      };
    }, [size]);

    const iconSize = useMemo(() => {
      if (typeof size === 'number') {
        return { width: widthProp, height: heightProp };
      }

      return { width: 16 / Number(widthProp), height: 16 / Number(heightProp) };
    }, [heightProp, size, widthProp]);

    return (
      <Box
        ref={ref}
        height={height}
        width={width}
        minWidth={minWidth}
        color={color}
        className={className}
        sx={{ ...sx, fill }}
      >
        <SvgIcon data-testid="SvgIcon-Test" component={IconComponent} {...iconSize} {...props} />
      </Box>
    );
  },
);
