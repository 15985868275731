import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { FreshChat, type FreshChatProps } from 'components/Freshchat';
import { useAppSelector } from 'store';

import { useSession } from './useSession';

const isChatEnabled = () => {
  const isEnabled = process.env.REACT_APP_FRESH_CHAT_ENABLED;

  return isEnabled?.toLowerCase() !== 'false';
};

export function useFreshChat(onInit: () => void) {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { session, isSuspended } = useSession({ skip: !isAuthenticated });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const freshChatProps = useMemo<FreshChatProps>(
    (): FreshChatProps => ({
      externalId: session?.userId ? session.userId.toString() : undefined,
      firstName: session?.firstName ?? undefined,
      lastName: session?.lastName ?? undefined,
      email: session?.email,
      config: { headerProperty: { hideChatButton: isMobile } },
      profileLink: session?.userId
        ? `https://app.proxy-cheap.com/admin/users/${session.userId.toString()}/overview`
        : undefined,
      isSuspended,
      onInit,
    }),
    [session?.userId, session?.firstName, session?.lastName, session?.email, isMobile, isSuspended, onInit],
  );

  return isChatEnabled() ? <FreshChat {...freshChatProps} /> : null;
}
