import { Fragment, useState } from 'react';

import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { paths } from 'paths';
import type { ProxyModel } from 'store/proxies/models';

import Styled from './styled';

type MultiUpdatesAccordionProps = {
  proxyIds: Array<ProxyModel['id']>;
  customLabel?: string;
};

export function MultiUpdatesAccordion({ proxyIds, customLabel }: MultiUpdatesAccordionProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <Styled.MultiProxyAccordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary expandIcon={<Icon name="arrow-down" size="small" color="primary.main" />}>
        <Typography variant="body-2" color="gray">
          {customLabel ?? t('proxies.my-proxies.multiaction.title', { count: proxyIds.length })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {proxyIds.map((proxyId, index) => (
          <Fragment key={proxyId}>
            <Typography
              variant="body-2"
              color="primary.main"
              component={Link}
              to={`${paths.proxies.index}/${proxyId}`}
              target="blank"
            >
              {proxyId}
              {index !== proxyIds.length - 1 && ', '}
            </Typography>
          </Fragment>
        ))}
      </AccordionDetails>
    </Styled.MultiProxyAccordion>
  );
}
