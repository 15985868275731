import { createSlice } from '@reduxjs/toolkit';

import { PersistService } from 'utils/persist';

import type { CommonState } from './types';

const reviewBannerDismissed = !!PersistService.get('review.dismissed');

const initialState: CommonState = {
  reviewBannerDismissed,
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    dismissReviewBanner: (state) => {
      PersistService.set('review.dismissed', true);

      state.reviewBannerDismissed = true;
    },
  },
});

export const { dismissReviewBanner } = slice.actions;

export const { reducer: commonReducer } = slice;
