import type { Components, Theme } from '@mui/material/styles';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    root: {
      '--Input-borderRadius': '8px',
      '--Input-paddingBlock': '12px',
      '--Input-paddingInline': '16px',
      '--Input-minHeight': '48px',

      '--Input-adorned-end-padding': '8px',
      '--Input-adorned-start-padding': '8px',

      borderRadius: 'var(--Input-borderRadius) !important',
      paddingBlock: 'var(--Input-paddingBlock)',
      paddingInline: 'var(--Input-paddingInline)',
      minHeight: 'var(--Input-minHeight)',

      display: 'inline-flex !important',
      alignItems: 'center',
      alignSelf: 'stretch',
    },

    input: {
      fontSize: 'var(--Input-fontSize, 14px)',
      lineHeight: 'var(--Input-lineHeight, 20px)',
      fontWeight: 'var(--Input-fontWeight, 400)',

      '&::placeholder': {
        color: 'rgba(var(--mui-palette-text-primaryChannel) / 0.48)',
        opacity: 'var(--Input-placeholder-opacity, 1)',
      },

      '&:-webkit-autofill': {
        borderRadius: 'inherit',
        marginBlock: 'calc(var(--Input-paddingBlock) * -1)',
        marginInline: 'calc(var(--Input-paddingInline) * -1)',
        paddingBlock: 'var(--Input-paddingBlock)',
        paddingInline: 'var(--Input-paddingInline)',
        boxShadow: 'none',
        WebkitTextFillColor: 'var(--mui-palette-text-primary)',
        caretColor: 'var(--mui-palette-common-black)',
      },
    },

    multiline: {
      '--Input-paddingBlock': '12px',
    },

    sizeSmall: {
      '--Input-minHeight': '36px',

      '--Input-paddingBlock': '8px',
      '--Input-paddingInline': '12px',
    },
  },
};
