import type { Shadows } from '@mui/material/styles';

export const shadows: Shadows = [
  'none',
  '0px 1px 0px 0px rgba(16, 26, 51, 0.08)', // Shadow/1px - var(--mui-shadows-1)
  '0px 0px 0px 1px rgba(16, 26, 51, 0.08)', // Shadow/Border - var(--mui-shadows-2)
  '0px 2px 4px 0px rgba(16, 26, 51, 0.08)', // Shadow/S - var(--mui-shadows-3)
  '0px 0px 0px 1px rgba(16, 26, 51, 0.08), 0px 2px 4px 0px rgba(16, 26, 51, 0.08)', // Shadow/S + Border - var(--mui-shadows-4)
  '0px 4px 8px 0px rgba(16, 26, 51, 0.04)', // Shadow/M - var(--mui-shadows-5)
  '0px 0px 0px 1px rgba(16, 26, 51, 0.08), 0px 4px 8px 0px rgba(16, 26, 51, 0.04)', // Shadow/M + Border - var(--mui-shadows-6)
  '0px 8px 16px 0px rgba(16, 26, 51, 0.08)', // Shadow/L - var(--mui-shadows-7)
  '0px 0px 0px 1px rgba(16, 26, 51, 0.08), 0px 8px 16px 0px rgba(16, 26, 51, 0.08)', // Shadow/L + Border - var(--mui-shadows-8)
  '0px 16px 24px 0px rgba(16, 26, 51, 0.16)', // Shadow/XL - var(--mui-shadows-9)
  '0px 0px 0px 1px rgba(16, 26, 51, 0.08), 0px 16px 24px 0px rgba(16, 26, 51, 0.16)', // Shadow/XL + Border - var(--mui-shadows-10)
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];
