import { alpha } from '@mui/material/styles';

import type { CreatePaymentMethodData, PaymentIntent, StripeElementsOptions } from '@stripe/stripe-js';

import type { Nullable } from 'types';

import { PAYMENT_TYPES_WITH_CUSTOM_PAYMENT_AND_REDIRECT } from './constants';
import type { BuildStripeElementsOptionsArgs, StripeOptions, StripePaymentType, StripeSetup } from './types';
import type { PaymentOptionType } from '../types';

/**
 * Retrieves API key for Stripe
 *
 * @throws {Error} once the API key is not set properly
 * @returns {string} API key for Stripe
 */
export function getStripeKey(): string {
  const key = process.env.REACT_APP_STRIPE_KEY;

  if (!key) {
    throw new Error('Stripe cannot be initialized - REACT_APP_STRIPE_KEY is not set');
  }

  return key;
}

/**
 * Converts payment option type to Stripe payment type
 *
 * @param {PaymentOptionType} from
 * @returns {StripePaymentType}
 */
export function toStripePaymentType(from: PaymentOptionType): StripePaymentType {
  if (from === 'crypto' || from === 'balance') throw new Error(`${from} payment option is not allowed in Stripe flow.`);

  if (from === 'another-card' || from === 'new-card' || from === 'existing-card') return 'card';

  return from;
}

/**
 * TypeScript guard to obtain proper <Stripe /> configuration type
 *
 * @param {StripeOptions} options
 * @returns {boolean}
 */
export function isStripeSetup(options: StripeOptions): options is StripeSetup {
  return options.mode === 'setup';
}

/**
 * Retrieves the clientSecret from the provided configuration
 *
 * @throws {Error} once the Stripe mode is invalid
 * @param {StripeOptions} options
 * @returns {string}
 */
export function getClientSecret(options: StripeOptions): string {
  if (!isStripeSetup(options)) throw new Error('Client secret can be obtained only for the "setup" mode.');

  return options.clientSecret;
}

/**
 * Retrieves Stripe elements configuration based on the provided mode
 *
 * @param {BuildStripeElementsOptionsArgs} options
 * @returns {StripeElementsOptions}
 */
export function buildStripeElementsOptions({
  locale,
  theme,
  ...options
}: BuildStripeElementsOptionsArgs): StripeElementsOptions {
  const baseOptions = {
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap',
      },
    ],
    locale,
    appearance: {
      variables: {
        colorTextPlaceholder: alpha(theme.palette.text.primary, 0.48),
      },

      rules: {
        '.Input': {
          fontFamily: '"Inter"',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          color: theme.palette.text.primary,
          backgroundColor: 'white',
          borderRadius: '4px',
          padding: '13px 12px',
        },

        '.Input:hover': {
          borderColor: 'rgba(4, 82, 251, 0.24)',
        },

        '.Input:focus': {
          borderColor: 'rgba(4, 82, 251, 0.24)',
          borderWidth: '1px',
          outline: 'none',
          boxShadow: 'none',
        },

        '.Label': {
          marginBottom: theme.spacing(1),

          fontFamily: '"Inter"',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: theme.palette.text.secondary,
        },
      },
    },
  };

  if (isStripeSetup(options)) {
    return { ...baseOptions, clientSecret: options.clientSecret };
  }

  const { amount, currency, mode, paymentMethod } = options;

  return {
    ...baseOptions,
    mode,
    amount: parseFloat((amount * 100).toFixed(2)),
    currency,
    paymentMethodTypes: [toStripePaymentType(paymentMethod)],
    paymentMethodCreation: 'manual',
  };
}

/**
 * Verifies if selected payment method requires a custom payment method and redirect.
 *
 * @param {PaymentOptionType} paymentType
 * @returns {boolean}
 */
export function shouldHandlePaymentViaCustomRedirect(paymentType: PaymentOptionType): boolean {
  return PAYMENT_TYPES_WITH_CUSTOM_PAYMENT_AND_REDIRECT.includes(paymentType);
}

/**
 * Builds configuration for `createPaymentMethod` function for payments that need custom logic.
 *
 * @param {PaymentOptionType} paymentType
 * @returns {CreatePaymentMethodData}
 */
export function getCreatePaymentMethodArgs(paymentType: PaymentOptionType): CreatePaymentMethodData {
  return { type: toStripePaymentType(paymentType), customer_balance: {} };
}

/**
 * Retrieves the external vendor redirect URL
 *
 * Used for `PAYMENT_TYPES_WITH_CUSTOM_PAYMENT_AND_REDIRECT`
 *
 * @param {Nullable<PaymentIntent> } paymentIntent
 * @returns {string | undefined}
 */
export function getExternalVendorRedirectURL(paymentIntent: Nullable<PaymentIntent>) {
  const nextAction = paymentIntent?.next_action;

  return nextAction?.alipay_handle_redirect?.url ?? nextAction?.redirect_to_url?.url;
}
