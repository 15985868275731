import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import { useListenFreshChatUnreadCount } from 'components/Freshchat/hooks';
import { Icon } from 'components/Icon';
import { usePopover } from 'hooks/usePopover';

import { AccountMenu } from './AccountMenu';

export function AccountButton() {
  const { anchorRef, isOpen, handleClose, handleOpen } = usePopover<HTMLButtonElement>();
  const { hasUnreadMessages } = useListenFreshChatUnreadCount();

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleOpen}>
        <Badge overlap="circular" invisible={!hasUnreadMessages}>
          <Icon name="profile-circle" color="var(--mui-palette-neutral-400)" />
        </Badge>
      </IconButton>

      <AccountMenu anchorEl={anchorRef.current} isOpen={isOpen} onClose={handleClose} />
    </>
  );
}
