import { useEffect, useRef } from 'react';

import { TopUpStatus } from 'store/accounts/types';
import { useGetTopUpQuery } from 'store/api';
import type { Nullable } from 'types';

export function useGetTopUpStatus(topUpId: Nullable<string>) {
  const skip = useRef(!topUpId);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const query = useGetTopUpQuery({ id: topUpId! }, { pollingInterval: 5_000, skip: skip.current });

  useEffect(() => {
    if (!topUpId) {
      skip.current = false;
    }

    if (!query.data) return;

    if (![TopUpStatus.CANCELED, TopUpStatus.COMPLETED].includes(query.data.status)) return;

    skip.current = true;
  }, [query.data, topUpId]);

  return query;
}
