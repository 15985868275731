import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import * as Yup from 'yup';

import { SetupForm } from './SetupForm';
import { useDiagnosticModalContext } from '../context';
import type { DiagnosticValues } from '../types';

type SetupStepProps = {
  proxyId: number;
  onClose: () => void;
};

type HandleSubmit = FormikConfig<DiagnosticValues>['onSubmit'];

const schema: Yup.Schema<DiagnosticValues> = Yup.object({
  url: Yup.string().optional(),
});

export function SetupStep({ proxyId, onClose }: SetupStepProps) {
  const { errors, onDiagnosticRun } = useDiagnosticModalContext();

  const initialValues = useMemo<DiagnosticValues>(() => ({ url: '' }), []);

  const onFormSubmit = useCallback<HandleSubmit>(
    async ({ url }) => onDiagnosticRun(proxyId, url),
    [onDiagnosticRun, proxyId],
  );

  return (
    <Formik<DiagnosticValues>
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={schema}
      initialErrors={errors}
      initialTouched={{ url: !!errors.url }}
      enableReinitialize
      validateOnChange
    >
      <SetupForm onClose={onClose} />
    </Formik>
  );
}
