import { type ClipboardEvent, type FocusEvent, useCallback } from 'react';

import { type PhoneInputInfo, usePhoneDigits } from './usePhoneDigits';
import { Input, type InputProps } from '../Input';

export type PhoneInputProps = Omit<InputProps<string>, 'onChange'> & {
  disableFormatting?: boolean;
  onChange?: (value: string, info: PhoneInputInfo) => void;
};

function putCursorAtEndOfInput(inputElement: HTMLInputElement) {
  inputElement.focus();
  const { length } = inputElement.value;
  inputElement.setSelectionRange(length, length);
}

export function PhoneInput({
  onCopy,
  onChange,
  onFocus,
  onDoubleClick,
  disableFormatting,
  inputProps,
  value,
  ...props
}: PhoneInputProps) {
  const { inputRef, inputValue, onInputChange } = usePhoneDigits({ value: value ?? '', onChange, disableFormatting });

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setTimeout(() => {
        if (inputRef.current) {
          putCursorAtEndOfInput(inputRef.current);
        }
      }, 0);

      onFocus?.(event);
    },
    [inputRef, onFocus],
  );

  const handleCopy = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      if (onCopy) return onCopy(event);

      const currentSelection = window.getSelection();

      if (!currentSelection) return;

      const valueWithoutSpaces = currentSelection.toString().replaceAll(' ', '');
      event.clipboardData.setData('text/plain', valueWithoutSpaces);
      event.preventDefault();
    },
    [onCopy],
  );

  return (
    <Input
      type="tel"
      value={inputValue}
      inputRef={inputRef}
      onChange={onInputChange}
      inputProps={{ onCopy: handleCopy, ...inputProps }}
      onFocus={handleFocus}
      startIcon="call"
      {...props}
    />
  );
}
