import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ToastManager } from 'utils/toast';

import { useSession } from './useSession';
import { useSignOut } from './useSignOut';

export function useTerminatedCheck() {
  const { t } = useTranslation();
  const { isSessionLoading, error } = useSession();

  const signOut = useSignOut();

  const check = useCallback(() => {
    if (isSessionLoading) return;

    if (!error) return;

    if (error.message !== 'ACCOUNT_HAS_BEEN_TERMINATED') return;

    ToastManager.error(t('errors:ACCOUNT_HAS_BEEN_TERMINATED'), { toastId: 'ACCOUNT_TERMINATION' });

    signOut();
  }, [error, isSessionLoading, signOut, t]);

  useEffect(() => {
    check();
  }, [check]);
}
