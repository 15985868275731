import type { Components, Theme } from '@mui/material/styles';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: {
    '#nprogress': {
      pointerEvents: 'none',
    },

    '#nprogress .bar': {
      height: 3,
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000,
      backgroundColor: 'var(--mui-palette-primary-main)',
    },

    '.cky-btn-revisit-wrapper': {
      display: 'none !important',
    },

    '.Toastify__toast-container': {
      minWidth: 288,
      width: 'max-content',
      maxWidth: '90%',
      padding: 0,
      zIndex: 1400,
    },

    '.Toastify__toast': {
      borderRadius: 32,
      padding: '8px 32px',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      minHeight: 'auto',
    },

    '.Toastify__toast-body': {
      padding: '8px 0',
      flex: 'none',
      width: '100%',
    },

    '.Toastify__toast--success': {
      background: 'var(--mui-palette-neutral-500)',
      color: 'var(--mui-palette-success-main)',
    },

    '.Toastify__toast--error': {
      color: 'var(--mui-palette-common-white)',
      background: 'var(--mui-palette-error-main)',
    },

    '.Toastify__progress-bar': {
      visibility: 'hidden',
    },
  },
};
