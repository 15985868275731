import Menu, { type MenuProps } from '@mui/material/Menu';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { useFreshChatAPI, useListenFreshChatUnreadCount } from 'components/Freshchat/hooks';
import { useSession } from 'hooks/useSession';
import { useSignOut } from 'hooks/useSignOut';
import { paths } from 'paths';

import { AccountMenuItem } from './AccountMenuItem';

type AccountMenuProps = Omit<MenuProps, 'onClose' | 'open'> & {
  isOpen: boolean;
  onClose: () => void;
};

export function AccountMenu({ anchorEl, isOpen, onClose }: AccountMenuProps) {
  const { t } = useTranslation();
  const { openFreshChat } = useFreshChatAPI();
  const { hasUnreadMessages } = useListenFreshChatUnreadCount();

  const { session, isSessionLoading } = useSession();
  const signOut = useSignOut();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
      open={isOpen}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      slotProps={{ paper: { sx: { width: 'var(--Navigation-account-width)' } } }}
    >
      <AccountMenuItem
        href={paths.account.index}
        icon="user"
        content={
          <Stack>
            <Typography variant="body-2" color="inherit">
              {t('common:popovers.account.my-account')}
            </Typography>

            <Typography variant="caption" color="var(--mui-palette-neutral-500)">
              {isSessionLoading ? <Skeleton /> : session?.email}
            </Typography>
          </Stack>
        }
        onClick={onClose}
      />

      <AccountMenuItem href={paths.account.payments} icon="card" content={t('common:popovers.account.billing')} />

      <AccountMenuItem
        href={paths.featureRequest.index}
        icon="message-add"
        content={t('common:popovers.account.featureRequest')}
        onClick={onClose}
      />

      <AccountMenuItem
        href=""
        icon="question"
        badgeOverlap="circular"
        isBadgeVisible={hasUnreadMessages}
        content={t('common:popovers.account.contactSupport')}
        onClick={(e) => {
          e.preventDefault();
          openFreshChat();
          onClose();
        }}
      />

      <AccountMenuItem
        href={paths.external.termsAndConditions}
        icon="note-text"
        content={t('common:popovers.account.terms')}
        external
        onClick={onClose}
      />

      <AccountMenuItem
        href=""
        icon="shield-tick"
        content={t('common:popovers.account.cookiePolicy')}
        onClick={(e) => {
          e.preventDefault();
          document.querySelector('.cky-btn-revisit-wrapper')?.dispatchEvent(new Event('click'));
          onClose();
        }}
      />

      <AccountMenuItem href="" icon="logout" content={t('common:popovers.account.logout')} onClick={signOut} />
    </Menu>
  );
}
