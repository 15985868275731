import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { RouterLink } from 'components/RouterLink';
import { Tabs } from 'components/Tabs';
import { useActiveTab } from 'hooks/useActiveTab';
import { paths } from 'paths';
import type { TabOption } from 'types';

type AccountLayoutTab = 'general' | 'notifications' | 'security' | 'idVerification' | 'payments';

const tabs: Array<TabOption<AccountLayoutTab, string>> = [
  { value: 'general', href: paths.account.index },
  { value: 'notifications', href: paths.account.notifications },
  { value: 'security', href: paths.account.security },
  { value: 'idVerification', href: paths.account.identityVerification },
  { value: 'payments', href: paths.account.payments },
];

export function AccountLayout() {
  const { t } = useTranslation();

  const segment = useActiveTab<AccountLayoutTab>({ items: tabs, defaultValue: 'general' });

  return (
    <Stack
      spacing={4}
      flex="1 1 auto"
      width="var(--Content-width)"
      px={{ xs: 'calc(var(--Content-paddingInline) + 16px)', md: 'calc(var(--Content-paddingInline) + 32px)' }}
      py={{ xs: 'calc(var(--Content-paddingBlock) + 16px)', md: 'calc(var(--Content-paddingBlock) + 32px)' }}
    >
      <Tabs
        items={tabs}
        value={segment}
        variant="scrollable"
        renderTab={({ value, href }) => (
          <Tab
            key={value}
            value={value}
            label={t(`account.${value}.title`)}
            component={RouterLink}
            href={href}
            tabIndex={0}
          />
        )}
      />

      <Outlet />
    </Stack>
  );
}
