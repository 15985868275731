import type { StripeElementLocale } from '@stripe/stripe-js';
import { type TOptions, t } from 'i18next';

import { Language } from 'store/accounts/models';

export function createTranslationLink(key: string, defaultParams: Record<string, unknown> = {}) {
  return (params: TOptions) => t(key, { ...defaultParams, ...params }) ?? '';
}

export function getStripeLocale(language: Language): StripeElementLocale {
  switch (language) {
    case Language.CN:
      return 'zh';
    case Language.EN:
      return 'en';
    case Language.ES:
      return 'es';
    case Language.VI:
      return 'vi';
    case Language.RU:
      return 'ru';
    default:
      return 'en';
  }
}
