import { type ComponentPropsWithoutRef, useMemo, type MouseEvent } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { RouterLink } from 'components/RouterLink';

export type OrderNavigationItemProps = {
  title: string;
  href: string;

  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
};

export function OrderNavigationItem({ title, href, disabled, onClick }: OrderNavigationItemProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isActive = useMemo(() => href === pathname, [href, pathname]);
  const isHoverable = useMemo(() => !disabled && !isActive, [disabled, isActive]);

  const props = useMemo<ComponentPropsWithoutRef<typeof Box>>(() => {
    if (disabled) return { role: 'button' };

    return { component: RouterLink, href, onClick };
  }, [disabled, href, onClick]);

  return (
    <Box
      {...props}
      display="flex"
      alignItems="center"
      flex="0 0 auto"
      color="var(--mui-palette-neutral-400)"
      position="relative"
      sx={(theme) => ({
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        textDecoration: 'none',

        transition: theme.transitions.create(['color', 'border-bottom-color'], { duration: 150 }),

        borderBottom: '1px solid transparent',

        ...(disabled && { cursor: 'default' }),

        ...(isActive && {
          color: 'var(--mui-palette-text-primary)',
          borderBottomWidth: '2px',
          borderBottomColor: 'var(--mui-palette-primary-main)',
        }),

        ...(isHoverable && {
          '&:hover': {
            color: 'var(--mui-palette-text-primary)',
            borderBottomColor: 'var(--mui-palette-primary-main)',
          },
        }),
      })}
      tabIndex={0}
    >
      <Box flex="1 1 auto">
        <Typography
          variant={isActive ? 'subtitle' : 'body-2'}
          color="inherit"
          lineHeight="var(--OrderNavigation-height)"
        >
          {t(title)}
        </Typography>
      </Box>
    </Box>
  );
}
