import { type ReactNode, useEffect, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon, type IconProps } from 'components/Icon';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useBulkDownloadInvoicesMutation } from 'store/api';

export type DownloadInvoicesModalProps = {
  isAllSelected?: boolean;
  selectedIds: number[];
};

type Variant = {
  isLoader?: boolean;
  icon?: IconProps;
  title?: string;
  subtitle?: string;
  actions?: ReactNode[];
};

export function DownloadInvoicesModal({ isAllSelected, selectedIds }: DownloadInvoicesModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const [bulkDownloadInvoices, { isError, isSuccess }] = useBulkDownloadInvoicesMutation();

  const { title, subtitle, icon, isLoader, actions } = useMemo<Variant>(() => {
    if (isError) {
      return {
        title: t('account.modals.downloadInvoices.failure.title'),
        subtitle: t('account.modals.downloadInvoices.failure.subtitle'),
        icon: { name: 'close-circle', color: 'error' },
        actions: [
          <Button
            key="tryAgain"
            fullWidth
            onClick={() => bulkDownloadInvoices({ ids: !isAllSelected ? selectedIds : [] })}
          >
            {t('common:buttons.tryAgain')}
          </Button>,
          <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
            {t('common:buttons.cancel')}
          </Button>,
        ],
      };
    }

    if (isSuccess) {
      return {
        title: t('account.modals.downloadInvoices.success.title'),
        subtitle: t('account.modals.downloadInvoices.success.subtitle'),
        icon: { name: 'tick-circle', color: 'success' },
      };
    }

    return {
      title: t('account.modals.downloadInvoices.pending.title'),
      subtitle: t('account.modals.downloadInvoices.pending.subtitle'),
      isLoader: true,
      actions: [
        <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
          {t('common:buttons.cancel')}
        </Button>,
      ],
    };
  }, [bulkDownloadInvoices, hideModal, isAllSelected, isError, isSuccess, selectedIds, t]);

  useEffect(() => {
    bulkDownloadInvoices({ ids: !isAllSelected ? selectedIds : [] });
  }, [bulkDownloadInvoices, isAllSelected, selectedIds]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      hideModal();
    }, 5_000);

    return () => {
      clearTimeout(timeout);
    };
  }, [hideModal]);

  useEffect(() => {
    if (isError) posthog.capture(posthogEvents.account.billing.invoices.bulk.failed);
  }, [isError, posthog]);

  useEffect(() => {
    if (isSuccess) posthog.capture(posthogEvents.account.billing.invoices.bulk.success);
  }, [isSuccess, posthog]);

  return (
    <ModalContainer>
      <Grid container direction="column" rowSpacing={4}>
        <Grid item>
          <Grid container direction="column" rowSpacing={1.5} alignItems="center">
            <Grid item>
              {icon && <Icon size={40} {...icon} />}
              {isLoader && <CircularProgress size={64} />}
            </Grid>

            {(title || subtitle) && (
              <Grid item>
                <Grid container direction="column" textAlign="center" rowSpacing={1}>
                  {title && (
                    <Grid item component={Typography} variant="headline-2">
                      {title}
                    </Grid>
                  )}

                  {subtitle && (
                    <Grid item component={Typography} variant="body-1" color="var(--mui-palette-neutral-500)">
                      {subtitle}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {actions && (
          <Grid item>
            <Grid container direction="column" rowSpacing={2}>
              {actions.map((action) => (
                <Grid item>{action}</Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </ModalContainer>
  );
}
