import { areIntervalsOverlapping } from 'date-fns';
import dayjs from 'dayjs';

import type { MaintenanceWindowModel } from 'store/common/models';
import type { DashboardMaintenanceWindow } from 'store/common/types';
import type { Nullable } from 'types';

function getOverlappingTimeIntervals(from: MaintenanceWindowModel[]): DashboardMaintenanceWindow[] {
  const overlapping: DashboardMaintenanceWindow[] = [];

  for (let i = 0; i < from.length - 1; i++) {
    for (let j = i + 1; j < from.length; j++) {
      const startI = dayjs(from[i].startAt);
      const endI = dayjs(from[i].endAt);
      const startJ = dayjs(from[j].startAt);
      const endJ = dayjs(from[j].endAt);

      const areOverlapping = areIntervalsOverlapping(
        { start: startI.toDate(), end: endI.toDate() },
        { start: startJ.toDate(), end: endJ.toDate() },
        { inclusive: true },
      );

      if (areOverlapping) {
        overlapping.push({
          start: startI.isBefore(startJ) ? startI.format('YYYY-MM-DD HH:mm:ss') : startJ.format('YYYY-MM-DD HH:mm:ss'),
          end: endI.isAfter(endJ) ? endI.format('YYYY-MM-DD HH:mm:ss') : endJ.format('YYYY-MM-DD HH:mm:ss'),
          note: from[i].note ?? from[j].note ?? null,
          replacementNote: from[i].replacementNote ?? from[j].replacementNote ?? null,
        });
      }
    }
  }

  return overlapping;
}

export function toDashboardMaintenanceStatus(from: MaintenanceWindowModel[]): Nullable<DashboardMaintenanceWindow> {
  if (!from.length) return null;

  if (from.length === 1)
    return {
      start: from[0].startAt ?? null,
      end: from[0].endAt ?? null,
      note: from[0].note,
      replacementNote: from[0].replacementNote,
    };

  return (
    // ! Get only the clesest date range
    getOverlappingTimeIntervals(from)[0] ?? {
      start: from[0].startAt ?? null,
      end: from[0].endAt ?? null,
      note: from[0].note,
      replacementNote: from[0].replacementNote,
    }
  );
}
