import type { Components, Theme } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';

export const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: { disableRipple: true },
  styleOverrides: {
    root: {
      '--Tab-minWidth': 'auto',
      '--Tab-minHeight': '40px',
      '--Tab-maxHeight': '40px',
      '--Tab-paddingInline': '0px',
      '--Tab-paddingBlock': '0px',

      '--Tab-color': 'var(--mui-palette-neutral-400)',
      '--Tab-fontSize': '14px',
      '--Tab-fontWeight': 400,
      '--Tab-lineHeight': '20px',

      '--Tab-selected-color': 'var(--mui-palette-text-primary)',
      '--Tab-selected-fontWeight': 700,

      '--Tab-hover-color': 'var(--mui-palette-text-primary)',

      justifyContent: 'flex-start',

      color: 'var(--Tab-color)',
      fontSize: 'var(--Tab-fontSize)',
      fontWeight: 'var(--Tab-fontWeight)',
      lineHeight: 'var(--Tab-lineHeight)',
      textTransform: 'none',

      minHeight: 'var(--Tab-minHeight)',
      maxHeight: 'var(--Tab-maxHeight)',
      minWidth: 'var(--Tab-minWidth)',

      paddingBlock: 'var(--Tab-paddingBlock)',
      paddingInline: 'var(--Tab-paddingInline)',

      [`&.${tabClasses.selected}`]: {
        color: 'var(--Tab-selected-color)',
        fontWeight: 'var(--Tab-selected-fontWeight)',
      },

      '&:hover': { color: 'var(--Tab-hover-color)' },
      '&:focus-visible': { outline: '2px solid var(--mui-palette-primary-main)' },
    },
  },
};
