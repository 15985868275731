import { paperClasses } from '@mui/material/Paper';
import type { Components, Theme } from '@mui/material/styles';

export const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: { elevation: 1 },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Card-background': 'var(--mui-palette-background-paper)',
      '--Card-border': 'none',
      '--Card-borderRadius': '16px',
      '--Card-boxShadow': 'var(--mui-shadows-4)',
      '--Card-maxWidth': 'unset',
      '--Card-paddingBlock': '24px',
      '--Card-paddingInline': '32px',
      '--Card-zIndex': 1,

      background: 'var(--Card-background)',
      border: 'var(--Card-border)',
      borderRadius: 'var(--Card-borderRadius)',
      maxWidth: 'var(--Card-maxWidth)',
      paddingBlock: 'var(--Card-paddingBlock)',
      paddingInline: 'var(--Card-paddingInline)',
      zIndex: 'var(--Card-zIndex)',

      transition: theme.transitions.create('all', { duration: 300 }),

      [`&.${paperClasses.elevation1}`]: {
        boxShadow: 'var(--Card-boxShadow)',
      },
    }),
  },
};
