import { useState } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'hooks/useSession';
import { useBulkExtendProxyPeriodMutation, useExtendProxyPeriodMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { AxiosBaseQueryError } from 'store/types';
import { ToastManager } from 'utils/toast';

import { ExtendProxyPeriodForm } from './ExtendProxyPeriodForm';

export type ExtendProxyPeriodModalProps = {
  proxies: ProxyModel[];
};

export function ExtendProxyPeriodModal({ proxies }: ExtendProxyPeriodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [extendPeriodSingle] = useExtendProxyPeriodMutation();
  const [extendPeriodBulk] = useBulkExtendProxyPeriodMutation();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  return (
    <Formik
      initialValues={{ periodInMonths: 1 }}
      onSubmit={async ({ periodInMonths }) => {
        const proxyIds = proxies.map(({ id }) => id);

        try {
          if (proxyIds.length > 1) {
            await extendPeriodBulk({ ids: proxyIds, periodInMonths }).unwrap();

            proxyIds.forEach((proxyId) => {
              const foundProxy = proxies.find(({ id }) => id === proxyId);

              GA_EVENTS.proxyPeriodExtended({
                userId: session?.userId,
                value: singleItemPrice,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                networkType: foundProxy!.networkType,
                proxy_id: proxyId,
                period: periodInMonths,
              });
            });

            posthog?.capture(posthogEvents.proxy.bulk.extendPeriod.success, {
              proxyIds,
              total: proxyIds.length,
            });
          } else {
            await extendPeriodSingle({ proxyId: proxyIds[0], periodInMonths }).unwrap();

            GA_EVENTS.proxyPeriodExtended({
              userId: session?.userId,
              value: singleItemPrice,
              networkType: proxies[0].networkType,
              proxy_id: proxyIds[0],
              period: periodInMonths,
            });

            posthog?.capture(posthogEvents.proxy.extendPeriod.success, { proxyId: proxyIds[0], periodInMonths });
          }

          ToastManager.success(t('proxies.modals.extendPeriod.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.message && typeof err.message === 'string') {
            ToastManager.error(t(`errors:${err.message}`));
          } else {
            ToastManager.error(t('proxies.modals.extendPeriod.failure'));
          }

          if (proxyIds.length > 1) {
            posthog?.capture(posthogEvents.proxy.bulk.extendPeriod.failed, {
              proxyIds,
              total: proxyIds.length,
            });
          } else {
            posthog?.capture(posthogEvents.proxy.extendPeriod.failed, { proxyId: proxyIds[0], periodInMonths });
          }
        }
      }}
    >
      <ExtendProxyPeriodForm proxies={proxies} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
