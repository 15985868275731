import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { useBalance } from 'hooks/useBalance';
import { TopUpButton } from 'modules/billing/TopUpButton';
import { CurrencyFormatter } from 'utils/currency';

export function Balance() {
  const { t } = useTranslation();
  const { balance, isBalanceLoading } = useBalance();

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      bgcolor="var(--Sidebar-balance-background)"
      p={2}
      borderRadius={2}
    >
      <Stack flex="1 1 auto">
        {isBalanceLoading ? (
          <Skeleton height={38} />
        ) : (
          <Stack>
            <Typography variant="badge" color="text.secondary">
              {t('common:sidebar.balance')}
            </Typography>
            <Typography variant="title" color="text.primary">
              {CurrencyFormatter.format(balance)}
            </Typography>
          </Stack>
        )}
      </Stack>

      <TopUpButton color="success" />
    </Stack>
  );
}
