import type { AppEndpointBuilder } from 'store/types';
import type { BasePaginationParameters, Paginate } from 'types';
import { buildLegacyAppURL } from 'utils/url';
import { downloadFile } from 'utils/values';

import {
  toAccountBalanceModel,
  toAccountProfileModel,
  toAddCreditCardModel,
  toApiKeyModel,
  toBillingPreferencesModel,
  toCreditCardModel,
  toEnableHardwareTwoFactorModel,
  toEnableSoftwareTwoFactorModel,
  toIdentityVerificationTokenModel,
  toInvoiceModel,
  toNotificationPreferencesModel,
  toTopUpModel,
  toTopUpPaymentFeeModel,
  toTopUpStatusModel,
  toTransactionModel,
  toTwoFactorAuthenticationMethodsModel,
} from './helpers';
import type {
  AccountProfileModel,
  AccountBalanceModel,
  APIKeyModel,
  BillingPreferencesModel,
  CreditCardModel,
  IdentityVerificationTokenModel,
  InvoiceModel,
  NotificationPreferencesModel,
  TopUpStatusModel,
  TransactionModel,
  TwoFactorAuthenticationMethodsModel,
  AddCreditCardModel,
  EnableSoftwareTwoFactorModel,
  EnableHardwareTwoFactorModel,
  TopUpPaymentFeeModel,
  TopUpModel,
} from './models';
import type {
  UpdateAccountProfilePayload,
  BulkDownloadInvoicesPayload,
  ChangeLanguagePayload,
  ChangePasswordPayload,
  DisableHardwareTwoFactorPayload,
  DisableSoftwareTwoFactorPayload,
  EnableHardwareTwoFactorPayload,
  EnableSoftwareTwoFactorPayload,
  GetTopUpFeesPayload,
  GetTopUpStatusPayload,
  TopUpPayload,
  UpdateAutoTopUpPreferences,
  UpdateNotificationPreferencesPayload,
} from './payloads';

export const accountEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getAccountBalance: builder.query<AccountBalanceModel, void>({
    query: () => ({
      url: 'account/balance',
      method: 'GET',
    }),
    providesTags: ['Account-Balance'],
    transformResponse: toAccountBalanceModel,
  }),

  getAccountProfile: builder.query<AccountProfileModel, void>({
    query: () => ({
      url: 'account/profile',
      method: 'GET',
    }),
    providesTags: ['Account-Profile'],
    transformResponse: toAccountProfileModel,
  }),

  getApiKeys: builder.query<Paginate<APIKeyModel>, BasePaginationParameters>({
    query: (params) => ({
      url: 'account/api-keys',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ apiKey }) => ({ type: 'Account-APIKey' as const, id: apiKey })),
            { type: 'Account-APIKey', id: 'LIST' },
          ]
        : [{ type: 'Account-APIKey', id: 'LIST' }],
    transformResponse: toApiKeyModel,
  }),

  getBillingPreferences: builder.query<BillingPreferencesModel, void>({
    query: () => ({
      url: 'account/billing/preferences',
      method: 'GET',
    }),
    providesTags: ['Account-Billing-Preferences'],
    transformResponse: toBillingPreferencesModel,
  }),

  getCreditCard: builder.query<CreditCardModel, void>({
    query: () => ({
      url: 'account/billing/card-details',
      method: 'GET',
    }),
    providesTags: ['Account-CreditCard'],
    transformResponse: toCreditCardModel,
  }),

  getIdentityVerificationToken: builder.query<IdentityVerificationTokenModel, void>({
    query: () => ({
      url: 'account/identity/token',
      method: 'GET',
    }),
    providesTags: ['Account-IdentityVerification-Token'],
    transformResponse: toIdentityVerificationTokenModel,
  }),

  getInvoices: builder.query<Paginate<InvoiceModel>, BasePaginationParameters>({
    query: (params) => ({
      url: 'account/billing/invoices',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ id }) => ({ type: 'Account-Invoice' as const, id })),
            { type: 'Account-Invoice', id: 'LIST' },
          ]
        : [{ type: 'Account-Invoice', id: 'LIST' }],
    transformResponse: toInvoiceModel,
  }),

  getNotificationPreferences: builder.query<NotificationPreferencesModel, void>({
    query: () => ({
      url: 'account/communication/preferences',
      method: 'GET',
    }),
    providesTags: ['Account-Notification-Preferences'],
    transformResponse: toNotificationPreferencesModel,
  }),

  getTopUp: builder.query<TopUpStatusModel, GetTopUpStatusPayload>({
    query: ({ id }) => ({
      url: buildLegacyAppURL(`account/billing/top-up/${id}`),
      method: 'GET',
    }),
    providesTags: (_, __, { id }) => [{ type: 'Account-Top-Up', id }],
    transformResponse: toTopUpStatusModel,
  }),

  getTransactions: builder.query<Paginate<TransactionModel>, BasePaginationParameters>({
    query: (params) => ({
      url: 'account/billing/transactions',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ createdAt }) => ({ type: 'Account-Transaction' as const, id: createdAt })),
            { type: 'Account-Transaction', id: 'LIST' },
          ]
        : [{ type: 'Account-Transaction', id: 'LIST' }],
    transformResponse: toTransactionModel,
  }),

  getTwoFactorAuthenticationMethods: builder.query<TwoFactorAuthenticationMethodsModel, void>({
    query: () => ({
      url: buildLegacyAppURL('account/2fa/authentication-methods'),
      method: 'GET',
    }),
    providesTags: ['Account-Two-Factor-Authentication-Methods'],
    transformResponse: toTwoFactorAuthenticationMethodsModel,
  }),
  // #endregion Queries

  // #region Mutations
  addCreditCard: builder.mutation<AddCreditCardModel, void>({
    query: () => ({
      url: 'account/billing/card/authorize',
      method: 'POST',
    }),
    transformResponse: toAddCreditCardModel,
    invalidatesTags: ['Account-Billing-Preferences', 'Account-CreditCard', 'Session'],
  }),

  bulkDownloadInvoices: builder.mutation<void, BulkDownloadInvoicesPayload>({
    query: (data) => ({
      url: 'account/billing/invoices-download',
      method: 'POST',
      data,
      responseType: 'blob',
    }),
    // Needs to be directly called here
    // RTK provides the serialization check for each value that can be stored in the RTK cache
    // ! https://redux-toolkit.js.org/api/serializabilityMiddleware
    transformResponse: (data: Blob) => downloadFile(data, 'invoices.zip'),
  }),

  changeLanguage: builder.mutation<void, ChangeLanguagePayload>({
    query: (data) => ({
      url: 'account/language',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Session'],
  }),

  changePassword: builder.mutation<void, ChangePasswordPayload>({
    query: (data) => ({
      url: 'account/change-password',
      method: 'POST',
      data,
    }),
  }),

  deleteApiKey: builder.mutation<void, string>({
    query: (apiKey) => ({
      url: `account/api-keys/${apiKey}`,
      method: 'DELETE',
    }),
    invalidatesTags: (_, __, apiKey) => [{ type: 'Account-APIKey', id: apiKey }],
  }),

  deleteCreditCard: builder.mutation<void, void>({
    query: () => ({
      url: 'account/billing/card/unauthorize',
      method: 'POST',
    }),
    invalidatesTags: ['Account-Billing-Preferences', 'Account-CreditCard', 'Session'],
  }),

  disableHardwareTwoFactor: builder.mutation<void, DisableHardwareTwoFactorPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/webauthn'),
      method: 'PUT',
      data,
    }),
    invalidatesTags: ['Account-Two-Factor-Authentication-Methods'],
  }),

  disableSoftwareTwoFactor: builder.mutation<void, DisableSoftwareTwoFactorPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/totp'),
      method: 'PUT',
      data,
    }),
    invalidatesTags: ['Account-Two-Factor-Authentication-Methods'],
  }),

  enableSoftwareTwoFactor: builder.mutation<EnableSoftwareTwoFactorModel, EnableSoftwareTwoFactorPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/totp'),
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Two-Factor-Authentication-Methods'],
    transformResponse: toEnableSoftwareTwoFactorModel,
  }),

  enableHardwareTwoFactor: builder.mutation<EnableHardwareTwoFactorModel, EnableHardwareTwoFactorPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/webauthn'),
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Two-Factor-Authentication-Methods'],
    transformResponse: toEnableHardwareTwoFactorModel,
  }),

  generateApiKey: builder.mutation<void, void>({
    query: () => ({
      url: 'account/api-keys',
      method: 'POST',
    }),
    invalidatesTags: ['Account-APIKey'],
  }),

  getTopUpFees: builder.mutation<TopUpPaymentFeeModel, GetTopUpFeesPayload>({
    query: (data) => ({
      url: 'account/billing/top-up/fees',
      method: 'POST',
      data,
    }),
    transformResponse: toTopUpPaymentFeeModel,
  }),

  resendEmailVerificationLink: builder.mutation<void, void>({
    query: () => ({
      url: 'account/resend-activation-email',
      method: 'POST',
    }),
  }),

  skipProfileDetails: builder.mutation<void, void>({
    query: () => ({
      url: 'account/profile/skip-profile',
      method: 'POST',
    }),
    invalidatesTags: ['Session'],
  }),

  topUp: builder.mutation<TopUpModel, TopUpPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/billing/top-up'),
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Balance', 'Account-CreditCard', 'Session'],
    transformResponse: toTopUpModel,
  }),

  updateAccountProfile: builder.mutation<void, UpdateAccountProfilePayload>({
    query: (data) => ({
      url: 'account/profile',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Profile', 'Session'],
  }),

  updateAutoTopUpPreferences: builder.mutation<void, UpdateAutoTopUpPreferences>({
    query: (data) => ({
      url: 'account/billing/auto-top-up',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Billing-Preferences', 'Session'],
  }),

  updateNotificationPreferences: builder.mutation<void, UpdateNotificationPreferencesPayload>({
    query: (data) => ({
      url: 'account/communication/preferences',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Notification-Preferences'],
  }),
  // #endregion Mutations
});
