import { v4 as uuid } from 'uuid';

import { toNetworkType } from 'store/proxies/helpers';
import type { NetworkType } from 'store/proxies/types';
import type { Paginate, PartialRecord } from 'types';
import { isPaginatedDTO } from 'utils/guards';

import type { AvailableDealDTO } from './dtos';
import type { AvailableDealModel } from './models';

export function toAvailableDealModel(from: AvailableDealDTO): AvailableDealModel;
export function toAvailableDealModel(from: AvailableDealDTO[]): AvailableDealModel[];
export function toAvailableDealModel(from: Paginate<AvailableDealDTO>): Paginate<AvailableDealModel>;

export function toAvailableDealModel(
  from: AvailableDealDTO | AvailableDealDTO[] | Paginate<AvailableDealDTO>,
): AvailableDealModel | AvailableDealModel[] | Paginate<AvailableDealModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toAvailableDealModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: data.map((item) => toAvailableDealModel(item)) };
  }

  const { isUnusedOnly, serviceType, ...rest } = from;

  return {
    ...rest,

    id: uuid(),
    isPremium: isUnusedOnly,
    networkType: toNetworkType(serviceType),
  };
}

export function toMarketplaceCountries(from: AvailableDealDTO[]): string[] {
  return from.map(({ country }) => country);
}

export function toGroupedDeals(from: AvailableDealModel[] = []): PartialRecord<NetworkType, AvailableDealModel[]> {
  return from.reduce<PartialRecord<NetworkType, AvailableDealModel[]>>((acc, deal) => {
    const currentTypeValues = acc[deal.networkType];

    if (currentTypeValues) {
      return { ...acc, [deal.networkType]: [...currentTypeValues, deal] };
    }

    return { ...acc, [deal.networkType]: [deal] };
  }, {});
}
