import { createTheme } from '@mui/material/styles';

import { colorSchemes } from './colorSchemes';
import { components } from './components';
import { shadows } from './shadows';
import { typography } from './typography';

export const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 576, md: 768, lg: 1024, xl: 1280 } },
  colorSchemes,
  components,
  cssVariables: true,
  defaultColorScheme: 'light',
  shadows,
  shape: { borderRadius: 8 },
  typography,
});
