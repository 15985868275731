import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';

export function FeatureRequestNavigation() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Stack
      component="header"
      bgcolor="var(--FeatureRequestNavigation-background)"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="var(--FeatureRequestNavigation-zIndex)"
    >
      <Stack
        direction="row"
        flex="1 1 auto"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="0px 1px 0px 0px rgba(16, 26, 51, 0.08)"
        minHeight="var(--FeatureRequestNavigation-height)"
        maxHeight="var(--FeatureRequestNavigation-height)"
        p={2}
        sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
      >
        <Box
          display="inline-flex"
          component={RouterLink}
          href={paths.index}
          zIndex="var(--FeatureRequestNavigation-logo-zIndex)"
        >
          <Logo wide={!isMobile} />
        </Box>

        <IconButton
          LinkComponent={RouterLink}
          href={paths.index}
          sx={{ zIndex: 'var(--FeatureRequestNavigation-close-zIndex)' }}
        >
          <Icon name="cross" color="var(--mui-palette-neutral-400)" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
