import type { Components, Theme } from '@mui/material/styles';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      '--FormHelperText-marginLeft': 0,
      '--FormHelperText-marginTop': '8px',
      '--FormHelperText-marginRight': 0,

      marginLeft: 'var(--FormHelperText-marginLeft)',
      marginRight: 'var(--FormHelperText-marginRight)',
      marginTop: 'var(--FormHelperText-marginTop)',
    },
  },
};
