import type { ColorSystemOptions } from '@mui/material';

import type { ColorScheme } from 'theme/types';

import { blue, cyan, green, lavaRed, magenta, neutral, orange, secondary, yellow } from './colors';

export const colorSchemes: Partial<Record<ColorScheme, ColorSystemOptions>> = {
  light: {
    palette: {
      action: {},
      background: { default: '#F5F7FA', defaultChannel: '245 247 250', paper: '#FFFFFF', paperChannel: '255 255 255' },
      common: { black: '#000000', white: '#FFFFFF' },
      primary: {
        ...blue,
        light: blue[400],
        main: blue[500],
        dark: blue[600],
        contrastText: 'var(--mui-palette-common-white)',
        activated: 'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-activatedOpacity))',
        hovered: 'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity))',
        selected: 'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      },
      secondary: {
        ...secondary,
        light: secondary[400],
        main: secondary[500],
        dark: secondary[600],
        contrastText: 'var(--mui-palette-primary-main)',
        activated: 'rgba(var(--mui-palette-secondary-mainChannel) / var(--mui-palette-action-activatedOpacity))',
        hovered: 'rgba(var(--mui-palette-secondary-mainChannel) / var(--mui-palette-action-hoverOpacity))',
        selected: 'rgba(var(--mui-palette-secondary-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      },
      success: {
        ...green,
        light: green[400],
        main: green[500],
        dark: green[600],
        contrastText: 'var(--mui-palette-text-primary)',
        activated: 'rgba(var(--mui-palette-success-mainChannel) / var(--mui-palette-action-activatedOpacity))',
        hovered: 'rgba(var(--mui-palette-success-mainChannel) / var(--mui-palette-action-hoverOpacity))',
        selected: 'rgba(var(--mui-palette-success-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      },
      error: {
        ...lavaRed,
        light: lavaRed[400],
        main: lavaRed[500],
        dark: lavaRed[600],
        contrastText: 'var(--mui-palette-common-white)',
        activated: 'rgba(var(--mui-palette-error-mainChannel) / var(--mui-palette-action-activatedOpacity))',
        hovered: 'rgba(var(--mui-palette-error-mainChannel) / var(--mui-palette-action-hoverOpacity))',
        selected: 'rgba(var(--mui-palette-error-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      },
      warning: {
        ...yellow,
        light: yellow[400],
        main: yellow[500],
        dark: yellow[600],
        contrastText: 'var(--mui-palette-text-primary)',
        activated: 'rgba(var(--mui-palette-warning-mainChannel) / var(--mui-palette-action-activatedOpacity))',
        hovered: 'rgba(var(--mui-palette-warning-mainChannel) / var(--mui-palette-action-hoverOpacity))',
        selected: 'rgba(var(--mui-palette-warning-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      },
      info: {
        ...cyan,
        light: cyan[400],
        main: cyan[500],
        dark: cyan[600],
        contrastText: 'var(--mui-palette-text-primary)',
        activated: 'rgba(var(--mui-palette-info-mainChannel) / var(--mui-palette-action-activatedOpacity))',
        hovered: 'rgba(var(--mui-palette-info-mainChannel) / var(--mui-palette-action-hoverOpacity))',
        selected: 'rgba(var(--mui-palette-info-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      },
      magenta,
      neutral,
      orange,
      divider: 'rgba(16, 26, 51, 0.08)',
      dividerChannel: '16 26 51',
      text: {
        primary: '#080A0C',
        primaryChannel: '8 10 12',
        secondary: '#6D8397',
        secondaryChannel: '109 131 151',
      },
    },
  },
};
