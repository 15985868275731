import { t } from 'i18next';

import { isAxiosBaseQueryError } from 'store/utils/errors';

import { getIndexFromKey, truncateArrayPath } from './string';

export function parseError(error: unknown) {
  if (!error || !isAxiosBaseQueryError(error)) return null;

  return error.message ?? 'VALIDATION_ERROR';
}

export function getValidationErrors<T>(error: unknown, keyMapperFn?: (responseKey: keyof T) => string) {
  if (!error || !isAxiosBaseQueryError(error) || typeof error.errors !== 'object') return;

  return Object.keys(error.errors).reduce<Record<string, string | string[]>>((memo, nextKey) => {
    const indexInArray = getIndexFromKey(nextKey);
    const keyValue = error.errors?.[nextKey].map(({ code, parameters }) => t(`errors:${code}`, parameters)).join(' ');

    if (indexInArray === -1) {
      const mappedKey = keyMapperFn?.(nextKey as keyof T) ?? nextKey;

      if (!memo[mappedKey]) {
        memo[mappedKey] = keyValue ?? t('errors:VALIDATION_ERROR');
      }
    } else {
      const truncatedKey = truncateArrayPath(nextKey);
      const mappedKey = keyMapperFn?.(truncatedKey as keyof T) ?? truncatedKey;

      if (!memo[mappedKey] || typeof memo[mappedKey] === 'string') {
        memo[mappedKey] = [];
      }

      memo[mappedKey][indexInArray] = keyValue ?? t('errors:VALIDATION_ERROR');
    }

    return memo;
  }, {});
}
