import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Outlet } from 'react-router-dom';

import { Logo } from 'components/Logo';

import Styled from './styled';
import { LanguageDropdown } from '../Language/LanguageDropdown';

export function BasicLayout() {
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Styled.LayoutRoot>
      <Styled.LogoWrapper>
        <Logo wide={!isMobileDevice} />

        <Box>
          <LanguageDropdown iconOnly />
        </Box>
      </Styled.LogoWrapper>

      <Outlet />
    </Styled.LayoutRoot>
  );
}
