import { useCallback, useEffect, useRef } from 'react';

import { BROADCAST_CHANNELS } from 'constants/broadcastChannels';
import { shouldRenderPaymentElement } from 'modules/billing/helpers';
import { useStripeContext } from 'modules/billing/Stripe';
import type { PaymentCallbackEvent } from 'modules/billing/types';

import { Loader } from './Loader';
import { PaymentElementForm } from './PaymentElementForm';
import { useTopUpModalContext } from '../context';

export function StripeStep() {
  const { createPaymentMethod, handlePayment } = useStripeContext();
  const { creditCard, values, handleTopUp, setStep } = useTopUpModalContext();

  // eslint-disable-next-line compat/compat
  const channel = useRef(new BroadcastChannel(BROADCAST_CHANNELS.topUp)).current;
  const handlePaymentRef = useRef<typeof handlePayment>();
  handlePaymentRef.current = handlePayment;

  /**
   * Handles payment by existing credit card
   * This case needs to be handled by a separate function to prevent sending duplicated Top-Up requests
   *
   * For some reason when the function is unified with the rest payment methods that don't require <PaymentElement /> rendered application sends multiple requests.
   *
   * To prevent this behavior this payment method requires the `handlePaymentRef` usage.
   */
  const handlePaymentByExistingCreditCard = useCallback(async () => {
    if (!values) return;

    return handleTopUp({
      values,
      paymentMethodId: creditCard?.paymentMethodId,
      onSuccess: ({ data }) =>
        handlePaymentRef.current?.(data, {
          onSuccess: () => setStep('status'),
          onFailure: () => setStep('status'),
        }),
    });
  }, [creditCard, handleTopUp, setStep, values]);

  /**
   * Payment by the existing credit card
   *
   * This method does not require <PaymentElement /> rendered or any additional logic to handle payment properly so it needs to be triggered immediately.
   */
  useEffect(() => {
    if (values?.type !== 'existing-card') return;

    handlePaymentByExistingCreditCard();
  }, [handlePaymentByExistingCreditCard, values?.type]);

  /**
   * Handles payment by non-Payment-Element methods
   *
   * Currently it's used only for AliPay and PayPal
   */
  const handlePaymentFlow = useCallback(async () => {
    if (!values) return;

    const paymentMethod = await createPaymentMethod(values.type);

    if (!paymentMethod) return;

    return handleTopUp({
      values,
      paymentMethodId: paymentMethod.id,
      onSuccess: ({ data }) => handlePayment(data, { onSuccess: () => setStep('status') }),
    });
  }, [createPaymentMethod, handlePayment, handleTopUp, setStep, values]);

  /**
   * Payment by the Paypal and AliPay
   *
   * Those methods do not require <PaymentElement /> rendered or any additional logic to handle payment properly.
   * The `existing-card` case is specific and that's why it cannot be handled by this useEffect
   */
  useEffect(() => {
    if (!values || shouldRenderPaymentElement(values.type) || values.type === 'existing-card') return;

    handlePaymentFlow();
  }, [handlePayment, handlePaymentFlow, values]);

  /**
   * Broadcast channel handler
   * This is the listener to handle the payment callback from the `/payments/callback` page.
   *
   * This event needs to be received to proceed to the Status step.
   *
   * Used for the external vendors like. AliPay or Paypal
   */
  useEffect(() => {
    channel.addEventListener('message', (e: MessageEvent<PaymentCallbackEvent>) => {
      if (e.data.isPaymentFinished) return;

      setStep('status');
    });

    return () => {
      channel.close();
    };
  }, [channel, setStep]);

  if (!values || !shouldRenderPaymentElement(values.type)) {
    return <Loader />;
  }

  return <PaymentElementForm />;
}
