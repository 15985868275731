import { type ReactNode, useCallback } from 'react';

import TableSortLabel, { type TableSortLabelProps } from '@mui/material/TableSortLabel';

import Styled from './styled';

export type TableSort<T> = Record<keyof T, TableSortLabelProps['direction']>;

type TableSortableLabelProps<T> = {
  sort: TableSort<T>;
  name: keyof T;
  children: ReactNode;

  onChange: (sort: TableSort<T>) => void;
};

export function TableSortableLabel<T>({ children, sort, name, onChange }: TableSortableLabelProps<T>) {
  const onSortChange = useCallback(() => {
    switch (sort[name]) {
      case 'asc':
        return onChange({ ...sort, [name]: undefined });
      case 'desc':
        return onChange({ ...sort, [name]: 'asc' });
      default:
        return onChange({ ...sort, [name]: 'desc' });
    }
  }, [name, onChange, sort]);

  return (
    <TableSortLabel
      active
      direction={sort[name]}
      IconComponent={() => {
        switch (sort[name]) {
          case 'asc':
            return <Styled.SortableIcon name="chevron-up" size={12} />;
          case 'desc':
            return <Styled.SortableIcon name="chevron-down" size={12} />;
          default:
            return <Styled.SortableIcon name="chevron-up-down" size={12} />;
        }
      }}
      onClick={onSortChange}
    >
      {children}
    </TableSortLabel>
  );
}
