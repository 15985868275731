import { useMemo, type MouseEvent, type ReactNode } from 'react';

import Badge, { badgeClasses, type BadgeProps } from '@mui/material/Badge';
import { boxClasses } from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack, { stackClasses } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useLocation } from 'react-router-dom';

import { Icon, type IconName } from 'components/Icon';
import { RouterLink } from 'components/RouterLink';
import { isNavItemActive } from 'utils/navigation';

type AccountMenuItemProps = {
  href: string;
  icon: IconName;

  content: ReactNode;

  external?: boolean;

  badgeOverlap?: BadgeProps['overlap'];
  badgeColor?: BadgeProps['color'];
  isBadgeVisible?: boolean;

  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

export function AccountMenuItem({
  content,
  href,
  icon,
  external,
  badgeOverlap = 'rectangular',
  badgeColor = 'error',
  isBadgeVisible,
  onClick,
}: AccountMenuItemProps) {
  const { pathname } = useLocation();

  const isActive = useMemo(
    () => isNavItemActive({ pathname, disabled: false, external, href }),
    [external, href, pathname],
  );

  const isHoverable = useMemo(() => !isActive, [isActive]);

  const iconColor = useMemo(() => {
    return isActive ? 'var(--Navigation-account-active-icon-color)' : 'var(--Navigation-account-icon-color)';
  }, [isActive]);

  return (
    <MenuItem
      component={RouterLink}
      href={href}
      onClick={onClick}
      target={external ? '_blank' : undefined}
      rel={external ? 'noreferrer' : undefined}
      sx={(theme) => ({
        color: 'var(--Navigation-account-color)',
        transition: theme.transitions.create(['color'], { duration: 300 }),

        [`& > .${stackClasses.root} > .${badgeClasses.root} >  .${boxClasses.root}`]: {
          transition: theme.transitions.create(['color'], { duration: 300 }),
        },

        ...(isActive && { color: 'var(--Navigation-account-active-color)' }),

        ...(isHoverable && {
          '&:hover': {
            color: 'var(--Navigation-account-hover-color)',

            [`& > .${stackClasses.root} > .${badgeClasses.root} >  .${boxClasses.root}`]: {
              color: 'var(--Navigation-account-hover-icon-color)',
            },
          },
        }),
      })}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Badge color={badgeColor} overlap={badgeOverlap} invisible={!isBadgeVisible}>
          <Icon name={icon} size="small" color={iconColor} />
        </Badge>

        {typeof content === 'string' ? (
          <Typography component="span" variant="body-2" color="inherit">
            {content}
          </Typography>
        ) : (
          content
        )}
      </Stack>
    </MenuItem>
  );
}
