import { useCallback, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useAppSelector } from 'store';

export type ReplaceProxiesProgressModalProps = {
  total: number;
  onFinish: () => void;
};

export function ReplaceProxiesProgressModal({ total, onFinish }: ReplaceProxiesProgressModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { replaceProgress } = useAppSelector((state) => state.proxies);

  const percentage = useMemo(() => {
    const amount = Math.round((replaceProgress * 100) / total);

    return amount <= 100 ? amount : 100;
  }, [replaceProgress, total]);

  useEffect(() => {
    if (total !== replaceProgress) return;

    onFinish();

    hideModal();
  }, [hideModal, onFinish, percentage, replaceProgress, total]);

  const onModalClose = useCallback(
    (_: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') return;

      return hideModal();
    },
    [hideModal],
  );

  return (
    <ModalContainer disableEscapeKeyDown onClose={onModalClose}>
      <Stack flex="1 1 auto" alignItems="center" justifyContent="center">
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress size={64} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="headline-2" component="div" sx={{ color: 'primary.main' }}>
              {`${percentage}%`}
            </Typography>
          </Box>
        </Box>

        <Stack spacing={1} alignItems="center" textAlign="center">
          <Typography variant="headline-2">{t('proxies.modals.replacement.title')}</Typography>

          <Typography variant="body-1" color="var(--mui-palette-neutral-500)">
            {t('proxies.modals.replacement.subtitle')}
          </Typography>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
