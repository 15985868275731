import { paginationItemClasses } from '@mui/material/PaginationItem';
import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

export const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {
    slots: {
      next: () => <Icon name="chevron-right" size="small" />,
      previous: () => <Icon name="chevron-left" size="small" />,
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '--PaginationItem-boxShadow': 'none',
      '--PaginationItem-background': 'var(--mui-palette-common-white)',
      '--PaginationItem-color': 'var(--mui-palette-neutral-400)',

      '--PaginationItem-size': '36px',

      '--PaginationItem-hover-boxShadow': 'var(--mui-shadows-2)',
      '--PaginationItem-hover-background': 'var(--mui-palette-common-white)',
      '--PaginationItem-hover-color': 'var(--mui-palette-text-primary)',

      '--PaginationItem-selected-boxShadow': 'var(--mui-shadows-2)',
      '--PaginationItem-selected-background': 'var(--mui-palette-common-white)',
      '--PaginationItem-selected-color': 'var(--mui-palette-text-primary)',

      boxShadow: 'var(--PaginationItem-boxShadow)',
      background: 'var(--PaginationItem-background)',
      color: 'var(--PaginationItem-color)',

      width: 'var(--PaginationItem-size)',
      height: 'var(--PaginationItem-size)',
      borderRadius: 'var(--PaginationItem-borderRadius, calc(var(--PaginationItem-size) / 2))',

      transition: theme.transitions.create(['box-shadow', 'background-color', 'color'], { duration: 300 }),

      [`&:not(.${paginationItemClasses.ellipsis}):hover`]: {
        boxShadow: 'var(--PaginationItem-hover-boxShadow)',
        background: 'var(--PaginationItem-hover-background)',
        color: 'var(--PaginationItem-hover-color)',
      },

      [`&.${paginationItemClasses.selected}`]: {
        boxShadow: 'var(--PaginationItem-selected-boxShadow)',
        background: 'var(--PaginationItem-selected-background)',
        color: 'var(--PaginationItem-selected-color)',
      },
    }),

    ellipsis: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  },
};
