import { Mutex } from 'async-mutex';

import { sequentialBaseQueryFactory } from 'store/api/base';
import type { AppEndpointBuilder } from 'store/types';
import type { Paginate } from 'types';

import type { ProxyISPDTO, ProxyReplacementOptionsDTO } from './dtos';
import {
  toBulkAutoExtendSettingsModel,
  toChangeAuthenticationTypeModel,
  toChangeProtocolModel,
  toChangeThreadsModel,
  toChangeUplinkSpeedModel,
  toDiagnosticRoutineModel,
  toHostnameModel,
  toIPWhitelistModel,
  toProxyAdminDetailsModel,
  toProxyChangelogModel,
  toProxyEventModel,
  toProxyExtensionPriceModel,
  toProxyISPModel,
  toProxyModel,
  toProxyOverviewModel,
  toProxyReplacementCheckModel,
  toProxyReplacementDetailsModel,
  toProxyReplacementOptionsModel,
  toProxySubnetModel,
  toProxySummaryModel,
  toUpgradeThreadsPriceModel,
  toUpgradeUplinkSpeedPriceModel,
} from './helpers';
import type {
  ProxyExtensionPriceModel,
  BulkAutoExtendSettingsModel,
  ChangeThreadsModel,
  ChangeUplinkSpeedModel,
  IPWhitelistModel,
  HostnameModel,
  ProxyOverviewModel,
  ChangeAuthenticationTypeModel,
  ProxyChangelogModel,
  ProxyEventModel,
  ProxyAdminDetailsModel,
  ProxySubnetModel,
  ChangeProtocolModel,
  ProxyModel,
  ProxyISPModel,
  ProxySummaryModel,
  ProxyReplacementDetailsModel,
  UpgradeThreadsPriceModel,
  UpgradeUplinkSpeedPriceModel,
  ProxyReplacementOptionsModel,
  DiagnosticRoutineModel,
} from './models';
import type {
  BulkProxyExtendPeriodPayload,
  BulkProxyExtendBandwidthPayload,
  ExtendProxyBandwidthPayload,
  ExtendProxyPeriodPayload,
  ProxyQueryParams,
  ReactivateProxyPayload,
  AddProxyRoutePayload,
  BulkAutoExtendProxyPayload,
  BulkChangeAuthenticationTypePayload,
  BulkChangeProtocolPayload,
  BulkDownloadProxyCredentials,
  ChangeProxyPortPayload,
  ChangeThreadsPayload,
  ChangeUplinkSpeedPayload,
  DeleteProxyRoutePayload,
  GetProxyReplacementOptionsPayload,
  ProxyReplacementPayload,
  RunDiagnosticRoutinePayload,
  UpsertProxyNotePayload,
  UpdateAuthenticationTypePayload,
  UpdateGlobalIPWhitelistPayload,
  UpdateIPWhitelistPayload,
  UpdateProxyProtocolPayload,
  UpgradeThreadsPayload,
  UpgradeUplinkSpeedPayload,
  RequestCountryPayload,
  EnableProxyAutoExtendPayload,
} from './payloads';

const mutex = new Mutex();

export const proxyEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getBulkAutoExtendSettings: builder.query<BulkAutoExtendSettingsModel, number[]>({
    query: (proxyIds) => ({
      url: 'proxies/bulk/auto-extend/details',
      method: 'POST',
      data: { ids: proxyIds },
    }),
    providesTags: ['Proxy-Bulk-Auto-Extend-Settings'],
    transformResponse: toBulkAutoExtendSettingsModel,
  }),

  getBulkProxyExtendBandwidthPrice: builder.query<ProxyExtensionPriceModel, BulkProxyExtendBandwidthPayload>({
    query: (data) => ({
      url: 'proxies/bulk/buy-traffic/price',
      method: 'POST',
      data,
    }),
    providesTags: ['Proxy-Bulk-Extend-Bandwidth-Price'],
    transformResponse: toProxyExtensionPriceModel,
  }),

  getBulkProxyExtendPeriodPrice: builder.query<ProxyExtensionPriceModel, BulkProxyExtendPeriodPayload>({
    query: (data) => ({
      url: 'proxies/bulk/extend-period/price',
      method: 'POST',
      data,
    }),
    providesTags: ['Proxy-Bulk-Extend-Period-Price'],
    transformResponse: toProxyExtensionPriceModel,
  }),

  getChangeAuthenticationTypeOptions: builder.query<ChangeAuthenticationTypeModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-authentication-type`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Authentication-Type', id }],
    transformResponse: toChangeAuthenticationTypeModel,
  }),

  getChangeProtocolOptions: builder.query<ChangeProtocolModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-protocol`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Protocol', id }],
    transformResponse: toChangeProtocolModel,
  }),

  getChangeThreadsOptions: builder.query<ChangeThreadsModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-threads`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Change-Threads-Options', id }],
    transformResponse: toChangeThreadsModel,
  }),

  getChangeUplinkSpeedOptions: builder.query<ChangeUplinkSpeedModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-uplink-speed`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Change-Uplink-Speed-Options', id }],
    transformResponse: toChangeUplinkSpeedModel,
  }),

  getGlobalIPWhitelist: builder.query<IPWhitelistModel, void>({
    query: () => ({
      url: 'proxies/settings/global-ip-whitelist',
      method: 'GET',
    }),
    providesTags: ['Proxy-Global-IPWhitelist'],
    transformResponse: toIPWhitelistModel,
  }),

  getHostnameIp: builder.query<HostnameModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/credential-generator`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Hostname', id }],
    transformResponse: toHostnameModel,
  }),

  getIsProxyReplacementAvailable: builder.query<boolean, void>({
    query: () => ({
      url: 'services/proxies/replacement/check',
      method: 'GET',
    }),
    providesTags: ['Proxy-Replacement-Status'],
    transformResponse: toProxyReplacementCheckModel,
  }),

  getProxy: builder.query<ProxyOverviewModel, number>({
    query: (proxyId) => ({
      url: `services/proxies/${proxyId}`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy', id }],
    transformResponse: toProxyOverviewModel,
  }),

  getProxyExtendBandwidthPrice: builder.query<ProxyExtensionPriceModel, ExtendProxyBandwidthPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/bandwidth-price`,
      method: 'POST',
      data,
    }),
    providesTags: (_, __, { proxyId }) => [{ type: 'Proxy-Extend-Bandwidth-Price', id: proxyId }],
    transformResponse: toProxyExtensionPriceModel,
  }),

  getProxyExtendPeriodPrice: builder.query<ProxyExtensionPriceModel, ExtendProxyPeriodPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/period-extension-price`,
      method: 'POST',
      data,
    }),
    providesTags: (_, __, { proxyId }) => [{ type: 'Proxy-Extend-Period-Price', id: proxyId }],
    transformResponse: toProxyExtensionPriceModel,
  }),

  getProxyChangelog: builder.query<ProxyChangelogModel[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-history`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Changelog', id }],
    transformResponse: toProxyChangelogModel,
  }),

  getProxyEvents: builder.query<ProxyEventModel[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/events`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Event', id }],
    transformResponse: toProxyEventModel,
  }),

  getProxyAdminDetails: builder.query<ProxyAdminDetailsModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/details`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Admin-Details', id }],
    transformResponse: toProxyAdminDetailsModel,
  }),

  getProxyIPWhitelist: builder.query<IPWhitelistModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/whitelist-ip`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-IPWhitelist', id }],
    transformResponse: toIPWhitelistModel,
  }),

  getProxySubnets: builder.query<ProxySubnetModel[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/routes/details`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Subnet', id }],
    transformResponse: toProxySubnetModel,
  }),

  getProxies: builder.query<Paginate<ProxyModel>, ProxyQueryParams>({
    query: (params) => ({
      url: 'services/proxies',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [...result.data.map(({ id }) => ({ type: 'Proxy' as const, id })), { type: 'Proxy', id: 'LIST' }]
        : [{ type: 'Proxy', id: 'LIST' }],
    transformResponse: toProxyModel,
  }),

  getProxiesISPs: builder.query<ProxyISPModel[], void>({
    query: () => ({
      url: 'services/proxies/isp',
      method: 'GET',
    }),
    providesTags: (result) =>
      result?.map
        ? [...result.map(({ id }) => ({ type: 'Proxy-ISP' as const, id })), { type: 'Proxy-ISP', id: 'LIST' }]
        : [{ type: 'Proxy-ISP', id: 'LIST' }],
    transformResponse: ({ data }: { data: ProxyISPDTO[] }) => toProxyISPModel(data),
  }),

  getProxiesSummary: builder.query<ProxySummaryModel, void>({
    query: () => ({
      url: 'proxies/summary',
      method: 'GET',
    }),
    providesTags: ['Proxy-Summary'],
    transformResponse: toProxySummaryModel,
  }),

  getReactivateProxyPrice: builder.query<ProxyExtensionPriceModel, ReactivateProxyPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/reactivate/price`,
      method: 'POST',
      data,
    }),
    providesTags: (_, __, { proxyId }) => [{ type: 'Proxy-Reactivate-Price', id: proxyId }],
    transformResponse: toProxyExtensionPriceModel,
  }),

  getReplaceableProxies: builder.query<ProxyReplacementDetailsModel[], void>({
    query: () => ({
      url: 'services/proxies/replacement/list',
      method: 'GET',
    }),
    providesTags: (result) =>
      result?.map
        ? [
            ...result.map(({ id }) => ({ type: 'Replaceable-Proxy' as const, id })),
            { type: 'Replaceable-Proxy', id: 'LIST' },
          ]
        : [{ type: 'Replaceable-Proxy', id: 'LIST' }],
    transformResponse: toProxyReplacementDetailsModel,
  }),

  getUpgradeThreadsPrice: builder.query<UpgradeThreadsPriceModel[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/buy-threads`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Upgrade-Threads-Price', id }],
    transformResponse: toUpgradeThreadsPriceModel,
  }),

  getUpgradeUplinkSpeedPrice: builder.query<UpgradeUplinkSpeedPriceModel[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/buy-uplink-speed`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Upgrade-Uplink-Speed-Price', id }],
    transformResponse: toUpgradeUplinkSpeedPriceModel,
  }),
  // #endregion Queries

  // #region Mutations
  activateProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/activate`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  addProxyRoute: builder.mutation<void, AddProxyRoutePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/admin/routes`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
    ],
  }),

  bulkAutoExtendProxy: builder.mutation<void, BulkAutoExtendProxyPayload>({
    query: (data) => ({
      url: 'proxies/bulk/auto-extend',
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, data) => [
      ...data.ids.map((id) => ({ type: 'Proxy' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Overview' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Admin-Details' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Event' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Changelog' as const, id })),
      'Account-Balance',
    ],
  }),

  bulkChangeProxyAuthenticationType: builder.mutation<void, BulkChangeAuthenticationTypePayload>({
    query: (data) => ({
      url: 'proxies/bulk/change-authentication-type',
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, data) => [
      ...data.ids.map((id) => ({ type: 'Proxy' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Overview' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Admin-Details' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Event' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Changelog' as const, id })),
    ],
  }),

  bulkChangeProtocol: builder.mutation<void, BulkChangeProtocolPayload>({
    query: (data) => ({
      url: 'proxies/bulk/change-protocol',
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, data) => [
      ...data.ids.map((id) => ({ type: 'Proxy' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Overview' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Admin-Details' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Event' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Changelog' as const, id })),
    ],
  }),

  bulkDownloadProxyCredentials: builder.mutation<string, BulkDownloadProxyCredentials>({
    query: (data) => ({
      url: 'proxies/bulk/download-credentials',
      method: 'POST',
      data,
    }),
  }),

  bulkExtendProxyBandwidth: builder.mutation<void, BulkProxyExtendBandwidthPayload>({
    query: (data) => ({
      url: 'proxies/bulk/buy-traffic',
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, data) => [
      ...data.ids.map((id) => ({ type: 'Proxy' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Overview' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Admin-Details' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Event' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Changelog' as const, id })),
      'Account-Balance',
    ],
  }),

  bulkExtendProxyPeriod: builder.mutation<void, BulkProxyExtendPeriodPayload>({
    query: (data) => ({
      url: 'proxies/bulk/extend-period',
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, data) => [
      ...data.ids.map((id) => ({ type: 'Proxy' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Overview' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Admin-Details' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Event' as const, id })),
      ...data.ids.map((id) => ({ type: 'Proxy-Changelog' as const, id })),
      'Account-Balance',
    ],
  }),

  cancelProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/cancel`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  changeProxyPort: builder.mutation<void, ChangeProxyPortPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/admin/change-port`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
    ],
  }),

  changeThreads: builder.mutation<void, ChangeThreadsPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-threads`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Upgrade-Threads-Price',
    ],
  }),

  changeUplinkSpeed: builder.mutation<void, ChangeUplinkSpeedPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-uplink-speed`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Upgrade-Uplink-Speed-Price',
    ],
  }),

  deleteProxyRoute: builder.mutation<void, DeleteProxyRoutePayload>({
    query: ({ proxyId, routeId }) => ({
      url: `proxies/${proxyId}/admin/routes/${routeId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
    ],
  }),

  disableProxyAutoExtend: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/auto-extend/disable`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
    ],
  }),

  disableProxyHttps: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/https/disable`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      { type: 'Proxy-Protocol', id },
    ],
  }),

  enableProxyAutoExtend: builder.mutation<void, EnableProxyAutoExtendPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/auto-extend/enable`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
    ],
  }),

  enableProxyHttps: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/https/enable`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      { type: 'Proxy-Protocol', id },
    ],
  }),

  expireProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/expire`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  extendProxyBandwidth: builder.mutation<void, ExtendProxyBandwidthPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/buy-bandwidth`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
      'Account-Balance',
    ],
  }),

  extendProxyPeriod: builder.mutation<void, ExtendProxyPeriodPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/extend-period`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
      'Account-Balance',
    ],
  }),

  getProxyReplacementOptions: builder.mutation<ProxyReplacementOptionsModel, GetProxyReplacementOptionsPayload>({
    query: (data) => ({
      url: 'order/configuration',
      method: 'POST',
      params: { errorFormat: 'codes' },
      data,
    }),
    transformResponse: (data: ProxyReplacementOptionsDTO, _, { networkType, isUnusedProxy }) =>
      toProxyReplacementOptionsModel({ ...data, networkType, isUnused: isUnusedProxy }),
  }),

  initializeProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/initialize`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  reactivateProxy: builder.mutation<void, ReactivateProxyPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/reactivate`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
      'Account-Balance',
    ],
  }),

  regenerateCredentials: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/regenerate-credentials`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  replaceProxy: builder.mutation<unknown, ProxyReplacementPayload>({
    queryFn: async (data, api, extraOptions) =>
      sequentialBaseQueryFactory(mutex)(
        { url: 'services/proxies/replacement/execute', method: 'POST', data },
        api,
        extraOptions,
      ),
    invalidatesTags: ['Proxy-Replacement-Status'],
    // invalidatesTags: (_, __, { proxyId }) => [
    //   { type: 'Replaceable-Proxy', id: proxyId },
    //   { type: 'Proxy', id: proxyId },
    // ],
  }),

  requestCountry: builder.mutation<void, RequestCountryPayload>({
    query: (data) => ({
      url: 'proxies/countries-requests',
      method: 'POST',
      data,
    }),
  }),

  runDiagnosticRoutine: builder.mutation<DiagnosticRoutineModel[], RunDiagnosticRoutinePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/admin/diagnostic-routine`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
    transformResponse: toDiagnosticRoutineModel,
  }),

  upsertProxyNote: builder.mutation<void, UpsertProxyNotePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/note`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  updateAuthenticationType: builder.mutation<void, UpdateAuthenticationTypePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-authentication-type`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  updateGlobalIPWhitelist: builder.mutation<void, UpdateGlobalIPWhitelistPayload>({
    query: (data) => ({
      url: 'proxies/settings/global-ip-whitelist',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Global-IPWhitelist'],
  }),

  updateIPWhitelist: builder.mutation<void, UpdateIPWhitelistPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/whitelist-ip`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  updateProxyProtocol: builder.mutation<void, UpdateProxyProtocolPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-protocol`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
    ],
  }),

  upgradeThreads: builder.mutation<void, UpgradeThreadsPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/buy-threads`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
      'Proxy-Upgrade-Threads-Price',
      'Account-Balance',
    ],
  }),

  upgradeUplinkSpeed: builder.mutation<void, UpgradeUplinkSpeedPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/buy-uplink-speed`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId: id }) => [
      { type: 'Proxy', id },
      { type: 'Proxy-Overview', id },
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy-Event', id },
      { type: 'Proxy-Changelog', id },
      'Proxy-Summary',
      'Proxy-Upgrade-Uplink-Speed-Price',
      'Account-Balance',
    ],
  }),
  // #endregion Mutations
});
