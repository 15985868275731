export const US_STATES = [
  { label: 'countriesregions:regions.AL', value: 'AL' },
  { label: 'countriesregions:regions.AZ', value: 'AZ' },
  { label: 'countriesregions:regions.AR', value: 'AR' },
  { label: 'countriesregions:regions.CA', value: 'CA' },
  { label: 'countriesregions:regions.CO', value: 'CO' },
  { label: 'countriesregions:regions.CT', value: 'CT' },
  { label: 'countriesregions:regions.DE', value: 'DE' },
  { label: 'countriesregions:regions.DC', value: 'DC' },
  { label: 'countriesregions:regions.FL', value: 'FL' },
  { label: 'countriesregions:regions.GA', value: 'GA' },
  { label: 'countriesregions:regions.HI', value: 'HI' },
  { label: 'countriesregions:regions.ID', value: 'ID' },
  { label: 'countriesregions:regions.IL', value: 'IL' },
  { label: 'countriesregions:regions.IN', value: 'IN' },
  { label: 'countriesregions:regions.IA', value: 'IA' },
  { label: 'countriesregions:regions.KS', value: 'KS' },
  { label: 'countriesregions:regions.KY', value: 'KY' },
  { label: 'countriesregions:regions.LA', value: 'LA' },
  { label: 'countriesregions:regions.MD', value: 'MD' },
  { label: 'countriesregions:regions.MA', value: 'MA' },
  { label: 'countriesregions:regions.MI', value: 'MI' },
  { label: 'countriesregions:regions.MN', value: 'MN' },
  { label: 'countriesregions:regions.MS', value: 'MS' },
  { label: 'countriesregions:regions.MO', value: 'MO' },
  { label: 'countriesregions:regions.NE', value: 'NE' },
  { label: 'countriesregions:regions.NV', value: 'NV' },
  { label: 'countriesregions:regions.NJ', value: 'NJ' },
  { label: 'countriesregions:regions.NY', value: 'NY' },
  { label: 'countriesregions:regions.NC', value: 'NC' },
  { label: 'countriesregions:regions.OH', value: 'OH' },
  { label: 'countriesregions:regions.OK', value: 'OK' },
  { label: 'countriesregions:regions.OR', value: 'OR' },
  { label: 'countriesregions:regions.PA', value: 'PA' },
  { label: 'countriesregions:regions.RI', value: 'RI' },
  { label: 'countriesregions:regions.SC', value: 'SC' },
  { label: 'countriesregions:regions.TN', value: 'TN' },
  { label: 'countriesregions:regions.TX', value: 'TX' },
  { label: 'countriesregions:regions.UT', value: 'UT' },
  { label: 'countriesregions:regions.VA', value: 'VA' },
  { label: 'countriesregions:regions.WA', value: 'WA' },
  { label: 'countriesregions:regions.WV', value: 'WV' },
  { label: 'countriesregions:regions.WI', value: 'WI' },
];
