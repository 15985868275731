import { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useAdminModal } from 'hooks/useAdminModal';
import { useGetProxyEventsQuery } from 'store/api';

export type ProxyAdminEventsModalProps = {
  proxyId: number;
  onClose: () => void;
};

export function ProxyAdminEventsModal({ proxyId, onClose }: ProxyAdminEventsModalProps) {
  const { t } = useTranslation();
  const { maxModalWidth } = useAdminModal();

  const { data = [], isLoading } = useGetProxyEventsQuery(proxyId, { skip: !proxyId });

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={onClose}>
        {t('common:buttons.close')}
      </Button>,
    ],
    [onClose, t],
  );

  return (
    <ModalContainer
      maxWidth={maxModalWidth}
      actions={actions}
      title={t('proxies.modals.eventlog.title')}
      onClose={onClose}
      scrollable
    >
      {isLoading ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress size={80} />
        </Stack>
      ) : (
        <Stack spacing={2} divider={<Divider />}>
          {data.map(({ createdAt, message }) => (
            <Grid key={`vpn-event-${createdAt}`} container alignItems="center" justifyContent="space-between">
              <Grid size={{ xs: 9 }}>
                <Typography variant="body-2">{message}</Typography>
              </Grid>

              <Grid size={{ xs: 3 }} display="flex" justifyContent="flex-end">
                <Typography variant="caption">{dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}
    </ModalContainer>
  );
}
