import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import type { Language } from 'store/accounts/models';
import { getStripeLocale } from 'translations/helpers';

import { stripePromise } from './constants';
import { StripeProvider, type StripeProviderProps } from './context';
import { buildStripeElementsOptions } from './helpers';

export function Stripe({ children, ...props }: StripeProviderProps) {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const options = useMemo(
    () => buildStripeElementsOptions({ ...props, locale: getStripeLocale(i18n.language as Language), theme }),
    [i18n.language, props, theme],
  );

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeProvider {...props}>{children}</StripeProvider>
    </Elements>
  );
}

export { useStripeContext } from './context';
