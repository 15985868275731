import { useMemo, type ReactNode } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon, type IconName, isIconName } from 'components/Icon';
import type { LiteralNodeUnion } from 'types';

type ResultProps = {
  title?: string;
  subtitle?: ReactNode;
  icon?: LiteralNodeUnion<IconName>;
  actions?: ReactNode;
};

export function Result({ title, subtitle, icon, actions }: ResultProps) {
  const resultIcon = useMemo(() => {
    if (isIconName(icon)) return <Icon name={icon} size={48} color="var(--mui-palette-text-secondary)" />;

    return icon;
  }, [icon]);

  return (
    <Stack spacing={4} justifyContent="center" alignItems="center" textAlign="center" maxWidth="400px">
      {resultIcon}

      {(title || subtitle) && (
        <Stack spacing={0.5}>
          {title && (
            <Typography variant="headline-2" color="var(--mui-palette-text-secondary)">
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography variant="body-1" color="var(--mui-palette-text-secondary)">
              {subtitle}
            </Typography>
          )}
        </Stack>
      )}

      {actions}
    </Stack>
  );
}
