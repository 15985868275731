import { ReactComponent as LogoIcon } from 'assets/icons/logo-mark-only.svg';
import { ReactComponent as LogoMenuIcon } from 'assets/icons/logo-menu.svg';
import { ReactComponent as VPNCheapIcon } from 'assets/icons/logo-vpn-cheap.svg';
import { ReactComponent as WhiteLogoIcon } from 'assets/icons/logo-white.svg';
import { ReactComponent as WideLogoIcon } from 'assets/icons/logo.svg';

export type LogoProps = {
  wide?: boolean;
  white?: boolean;
  vpn?: boolean;
  menu?: boolean;
};

export function Logo({ wide = true, white, vpn = false, menu = false }: LogoProps) {
  if (vpn) return <VPNCheapIcon />;

  if (menu) return <LogoMenuIcon />;

  if (!wide) return <LogoIcon />;

  if (!white) return <WideLogoIcon />;

  return <WhiteLogoIcon />;
}
