import type { Components, Theme } from '@mui/material/styles';
import { tableBodyClasses } from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';

export const MuiTable: Components<Theme>['MuiTable'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '--Table-border': '1px solid rgba(var(--mui-palette-text-primaryChannel) / 0.08)',
      '--Table-borderRadius': '16px',

      border: 'var(--Table-border)',
      borderRadius: 'var(--Table-borderRadius)',

      borderCollapse: 'separate',
      position: 'relative',

      transition: theme.transitions.create('all', { duration: 225 }),

      [`& .${tableBodyClasses.root} > .${tableRowClasses.root} .${tableCellClasses.root}`]: {
        borderBottom: 'var(--Table-border)',
      },

      [`& .${tableBodyClasses.root} > .${tableRowClasses.root}:last-of-type .${tableCellClasses.root}`]: {
        border: 'none',
      },

      [`& .${tableBodyClasses.root} > .${tableRowClasses.root}:last-of-type:hover`]: {
        [`& .${tableCellClasses.root}:first-of-type`]: {
          borderBottomLeftRadius: 'var(--Table-borderRadius)',
        },

        [`& .${tableCellClasses.root}:last-of-type`]: {
          borderBottomRightRadius: 'var(--Table-borderRadius)',
        },
      },
    }),
  },
};
