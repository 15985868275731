import { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useRouter } from 'hooks/useRouter';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { paths } from 'paths';
import { useGetReferralsDetailsQuery, useReferralsPayoutMutation } from 'store/api';
import { isAxiosBaseQueryError } from 'store/utils/errors';
import { CurrencyFormatter } from 'utils/currency';
import { ToastManager } from 'utils/toast';

export function PayoutModal() {
  const { t } = useTranslation();
  const router = useRouter();
  const hideModal = useHideModal();

  const { refetch } = useIdentityVerificationStatus();
  const { data, isLoading } = useGetReferralsDetailsQuery();
  const [payout, { isLoading: isPayoutLoading }] = useReferralsPayoutMutation();

  const onPayoutClick = useCallback(async () => {
    try {
      await payout().unwrap();

      ToastManager.success(t('referrals.modals.payout.success'));
    } catch (error) {
      if (!isAxiosBaseQueryError(error)) {
        return;
      }

      if (error.message === 'VERIFICATION_REQUIRED') {
        await refetch();

        router.push(paths.account.identityVerification);
      }
    } finally {
      hideModal();
    }
  }, [hideModal, payout, refetch, router, t]);

  const actions = useMemo(
    () => [
      <Button fullWidth key="cancel" color="secondary" onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button
        fullWidth
        key="payout"
        color="success"
        disabled={(data && data.finalEarnings < 0) || isPayoutLoading}
        onClick={onPayoutClick}
      >
        {t('common:buttons.payout')}
      </Button>,
    ],
    [data, hideModal, isPayoutLoading, onPayoutClick, t],
  );

  const footer = useMemo(
    () => (
      <Typography variant="caption" color="var(--mui-palette-neutral-500)" textAlign="center">
        {t('referrals.modals.payout.footer')}
      </Typography>
    ),
    [t],
  );

  return (
    <ModalContainer
      title={t('referrals.modals.payout.title')}
      subtitle={t('referrals.modals.payout.subtitle')}
      actions={actions}
      footer={footer}
    >
      <Stack spacing={2} divider={<Divider />}>
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
              {t('referrals.modals.payout.earnings')}
            </Typography>

            <Typography variant="body-2">
              {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.earnings || 0)}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
              {t('referrals.modals.payout.taxFee', { amount: data?.transactionTaxInPercent })}
            </Typography>

            <Typography variant="body-2">
              {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.earningsTax || 0)}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
              {t('referrals.modals.payout.bankFee')}
            </Typography>

            <Typography variant="body-2">
              {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.transactionFee || 0)}
            </Typography>
          </Stack>
        </>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="title" color="var(--mui-palette-neutral-500)">
            {t('referrals.modals.payout.total')}
          </Typography>

          <Typography variant="headline-2">
            {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.finalEarnings || 0)}
          </Typography>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
