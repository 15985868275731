import { type FieldProps, getIn, Field } from 'formik';

import {
  PhoneInput as BasePhoneInput,
  type PhoneInputProps as BasePhoneInputProps,
} from 'components/form-partials/base';

type PhoneInputProps<Values> = FieldProps<string, Values> &
  Omit<BasePhoneInputProps, 'hasError' | 'error' | 'value'> & { name: string };

function fieldToPhoneInput<Values>({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue },
  onBlur,
  onChange,
  ...props
}: PhoneInputProps<Values>): BasePhoneInputProps {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  return {
    hasError,
    error,
    disabled: disabled ?? isSubmitting,
    onBlur: (e) => {
      if (onBlur) return onBlur(e);

      return fieldOnBlur(e ?? field.name);
    },
    onChange: (value, info) => {
      if (onChange) return onChange(value, info);

      return setFieldValue(field.name, info.numberValue);
    },
    ...field,
    ...props,
  };
}

function PhoneInput<Values>(props: PhoneInputProps<Values>) {
  return <BasePhoneInput {...fieldToPhoneInput(props)} />;
}

export type FormikPhoneInputProps<Values> = Omit<PhoneInputProps<Values>, 'form' | 'meta' | 'field'>;

export function FormikPhoneInput<Values>(props: FormikPhoneInputProps<Values>) {
  return <Field component={PhoneInput} {...props} />;
}
