import { type FieldProps, getIn, Field } from 'formik';

import { Input as BaseInput, type InputProps as BaseInputProps } from 'components/form-partials/base';

type InputProps<Value, Values> = FieldProps<Value, Values> &
  Omit<BaseInputProps<Value>, 'hasError' | 'error' | 'value'> & { name: string };

function fieldToInput<Value, Values>({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  ...props
}: InputProps<Value, Values>): BaseInputProps<Value> {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  return {
    hasError,
    error,
    disabled: disabled ?? isSubmitting,
    onBlur: (e) => {
      if (onBlur) {
        return onBlur(e);
      }

      return fieldOnBlur(e ?? field.name);
    },
    ...field,
    ...props,
  };
}

function Input<Value, Values>(props: InputProps<Value, Values>) {
  return <BaseInput {...fieldToInput(props)} />;
}

export type FormikInputProps<Value, Values> = Omit<InputProps<Value, Values>, 'form' | 'meta' | 'field'>;

export function FormikInput<Value, Values>(props: FormikInputProps<Value, Values>) {
  return <Field component={Input} {...props} />;
}
