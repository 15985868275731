import type { Components, Theme } from '@mui/material/styles';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  defaultProps: { slotProps: { typography: { variant: 'caption', color: 'var(--mui-palette-text-secondary)' } } },
  styleOverrides: {
    root: {
      '--FormControlLabel-gap': '16px',

      gap: 'var(--FormControlLabel-gap)',
      margin: 0,
    },
  },
};
