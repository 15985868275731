import type { AppEndpointBuilder } from 'store/types';

import {
  toVPNAdminDetailsModel,
  toVPNChangelogModel,
  toVPNEventModel,
  toVPNLocationModel,
  toVPNModel,
  toVPNPortModel,
} from './helpers';
import type {
  VPNAdminDetailsModel,
  VPNChangelogModel,
  VPNEventModel,
  VPNLocationModel,
  VPNModel,
  VPNPortModel,
} from './models';
import type {
  DownloadOpenVPNConfigurationPayload,
  DownloadWireGuardConfigurationPayload,
  ExtendVPNPeriodPayload,
} from './payloads';

export const vpnEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getVPN: builder.query<VPNModel, number | void>({
    query: (vpnId) => ({
      url: `vpn${vpnId ? `/${vpnId}` : ''}`,
      method: 'GET',
    }),
    providesTags: (result) => (result ? [{ type: 'VPN', id: result.id }] : ['VPN']),
    transformResponse: toVPNModel,
  }),

  getVPNAdminDetails: builder.query<VPNAdminDetailsModel, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/admin/details`,
      method: 'GET',
    }),
    providesTags: (result) => (result ? [{ type: 'VPN-Admin-Details', id: result.id }] : ['VPN-Admin-Details']),
    transformResponse: toVPNAdminDetailsModel,
  }),

  getVPNChangelog: builder.query<VPNChangelogModel[], number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/change-history`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'VPN-Changelog' as const, id })), { type: 'VPN-Changelog', id: 'LIST' }]
        : [{ type: 'VPN-Changelog', id: 'LIST' }],
    transformResponse: toVPNChangelogModel,
  }),

  getVPNEvents: builder.query<VPNEventModel[], number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/events`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'VPN-Event' as const, id })), { type: 'VPN-Event', id: 'LIST' }]
        : [{ type: 'VPN-Event', id: 'LIST' }],
    transformResponse: toVPNEventModel,
  }),

  getVPNLocations: builder.query<VPNLocationModel[], void>({
    query: () => ({
      url: 'vpn/locations',
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'VPN-Location' as const, id })), { type: 'VPN-Location', id: 'LIST' }]
        : [{ type: 'VPN-Location', id: 'LIST' }],
    transformResponse: toVPNLocationModel,
  }),

  getVPNPorts: builder.query<VPNPortModel[], void>({
    query: () => ({
      url: 'vpn/ports',
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'VPN-Port' as const, id })), { type: 'VPN-Port', id: 'LIST' }]
        : [{ type: 'VPN-Port', id: 'LIST' }],
    transformResponse: toVPNPortModel,
  }),
  // #endregion Queries

  // #region Mutations
  cancelVPN: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/cancel`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'VPN-Admin-Details', id },
      { type: 'VPN', id },
      'VPN-Changelog',
      'VPN-Event',
    ],
  }),

  disableVPNAutoExtend: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/auto-extend/disable`,
      method: 'POST',
    }),
    invalidatesTags: () => ['VPN', 'VPN-Admin-Details', 'VPN-Changelog', 'VPN-Event'],
  }),

  downloadOpenVPNConfiguration: builder.mutation<string, DownloadOpenVPNConfigurationPayload>({
    query: ({ vpnId, ...data }) => ({
      url: `vpn/${vpnId}/download/openvpn`,
      method: 'POST',
      data,
    }),
  }),

  downloadWireGuardVPNConfiguration: builder.mutation<string, DownloadWireGuardConfigurationPayload>({
    query: ({ vpnId, ...data }) => ({
      url: `vpn/${vpnId}/download/wireguard`,
      method: 'POST',
      data,
    }),
  }),

  enableVPNAutoExtend: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/auto-extend/enable`,
      method: 'POST',
    }),
    invalidatesTags: () => ['VPN', 'VPN-Admin-Details', 'VPN-Changelog', 'VPN-Event'],
  }),

  expireVPN: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/expire`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'VPN-Admin-Details', id },
      { type: 'VPN', id },
      'VPN-Changelog',
      'VPN-Event',
    ],
  }),

  extendVPNPeriod: builder.mutation<void, ExtendVPNPeriodPayload>({
    query: ({ vpnId, ...data }) => ({
      url: `vpn/${vpnId}/extend-period`,
      method: 'POST',
      data,
    }),
    invalidatesTags: ['VPN', 'VPN-Admin-Details', 'VPN-Changelog', 'VPN-Event', 'Account-Balance'],
  }),

  reactivateVPN: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/activate`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'VPN-Admin-Details', id },
      { type: 'VPN', id },
      'VPN-Changelog',
      'VPN-Event',
    ],
  }),
  // #endregion Mutations
});
