import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useAuthParamsGuard } from 'hooks/useAuthParamsGuard';
import { useNprogress } from 'hooks/useNProgress';
import { useRouter } from 'hooks/useRouter';
import { useTerminatedCheck } from 'hooks/useTerminatedCheck';
import { paths } from 'paths';
import { useAppSelector } from 'store';

type AuthGuardProps = {
  children: ReactNode;
};

export function AuthGuard({ children }: AuthGuardProps) {
  const [checked, setChecked] = useState(false);
  const { isAuthenticated, isMFACompleted } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const router = useRouter();

  useNprogress();
  useAuthParamsGuard();
  useTerminatedCheck();

  const check = useCallback(() => {
    if (isMFACompleted != null && !isMFACompleted) {
      setChecked(true);

      return router.replace({
        pathname: paths.auth.mfa,
        search: new URLSearchParams({ returnTo: location.pathname + location.search }).toString(),
      });
    }

    if (!isAuthenticated) {
      setChecked(true);

      return router.replace({
        pathname: paths.auth.signIn,
        search: new URLSearchParams({ returnTo: location.pathname + location.search }).toString(),
      });
    }

    setChecked(true);
  }, [isAuthenticated, isMFACompleted, location.pathname, location.search, router]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) return null;

  return children;
}
