// fix for old android phones
import 'polyfill-object.fromentries';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsBetween from 'dayjs/plugin/isBetween';
import dayjsUTC from 'dayjs/plugin/utc';
import { AbortController } from 'node-abort-controller';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { App } from 'App';
import { store } from 'store';
import { theme } from 'theme';

import 'sentry';

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsBetween);

// add polyphill for old browsers, like India UC Browser to prevent crashes
if (!global.AbortController) {
  global.AbortController = AbortController as typeof globalThis.AbortController;
}

if (process.env.REACT_APP_ENV === 'production') {
  if (process.env.REACT_APP_GTM_CONTAINER_ID) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
    });
  }

  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }
}

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
  loaded: (posthog) => {
    // Disable PostHog in development and staging
    if (['development', 'staging'].includes(process.env.REACT_APP_ENV)) {
      posthog.opt_out_capturing();
    }
  },
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: { password: true },
    maskInputFn: (text, element) => {
      if (
        // @ts-expect-error - This is a proper way to get the field name
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        element?.attributes.name?.value?.toLowerCase()?.includes('password') ||
        // @ts-expect-error - This is a proper way to get the field name
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        element?.attributes.name?.value?.toLowerCase()?.includes('passwd')
      ) {
        return '*'.repeat(text.length);
      }

      return text;
    },
    maskTextSelector: '#password, #passwd, #passwd2, #newPassword, #newPasswordConfirmation',
  },
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={options}>
      <HelmetProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme} defaultMode="light" disableTransitionOnChange>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Provider>
      </HelmetProvider>
    </PostHogProvider>
  </Sentry.ErrorBoundary>,
);
