import { useMemo } from 'react';

import { useGetAccountBalanceQuery } from 'store/api';

type UseBalanceArgs = {
  skip?: boolean;
  pollingInterval?: number;
};

export function useBalance({ pollingInterval, skip }: UseBalanceArgs = {}) {
  const { data, isLoading, ...query } = useGetAccountBalanceQuery(undefined, {
    skip,
    ...(pollingInterval && { pollingInterval }),
  });

  return useMemo(
    () => ({ balance: data?.balance ?? 0, isBalanceLoading: isLoading, ...query }),
    [data?.balance, isLoading, query],
  );
}
