import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/Icon';

type SetupItemProps = {
  index: number;
  title: string;
  subtitle: string;
  checked?: boolean;
};

export function SetupItem({ index, subtitle, title, checked }: SetupItemProps) {
  return (
    <Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack
          borderRadius="50%"
          bgcolor={checked ? 'var(--mui-palette-primary-main)' : 'var(--mui-palette-divider)'}
          width={24}
          height={24}
          justifyContent="center"
          alignItems="center"
        >
          {checked ? (
            <Icon name="check" size={16} color="var(--mui-palette-background-paper)" />
          ) : (
            <Typography variant="body-2" color="var(--mui-palette-neutral-400)">
              {index}
            </Typography>
          )}
        </Stack>

        <Typography variant="title">{title}</Typography>
      </Stack>

      <Typography variant="body-2" color="var(--mui-palette-neutral-500)" pl={5}>
        {subtitle}
      </Typography>
    </Stack>
  );
}
