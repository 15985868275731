import type { Components, Theme } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      '--TableRow-hover-background': 'rgba(var(--mui-palette-primary-mainChannel) / 0.04)',

      [`& .${tableCellClasses.root}`]: {
        whiteSpace: 'nowrap',
      },

      ...(ownerState.onClick && {
        cursor: 'pointer',
        transition: theme.transitions.create('all', { duration: 225 }),

        '&:hover': {
          backgroundColor: 'var(--TableRow-hover-background)',
        },
      }),
    }),
  },
};
