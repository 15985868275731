import { createApi } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import axios, { isAxiosError } from 'axios';
import { camelizeKeys } from 'humps';

import { store } from 'store';
import type { TokenDTO } from 'store/auth/dtos';
import { toTokenModel } from 'store/auth/helpers';
import { onSignOut } from 'store/auth/reducers';
import { tags } from 'store/tags';
import type { AxiosBaseQueryError, AxiosBaseQueryFn, QueryReturnValue, ValidationError } from 'store/types';
import { isTokenExpired, isTokenValid, parseJWT } from 'utils/jwt';
import { PersistService } from 'utils/persist';

// Array of endpoints that are not included to camelizeKeys
const excludedEndpoints = ['order/configuration', 'features'];

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  transformResponse: [
    ...(axios.defaults.transformResponse as []),
    function camelize(data) {
      if (data instanceof Blob) {
        return data;
      }

      // Return data if endpoint is excluded
      if (excludedEndpoints.includes(this.url ?? '')) {
        return data;
      }

      return camelizeKeys(data);
    },
  ],
  withCredentials: true,
});

const mutex = new Mutex();

const axiosBaseQuery: AxiosBaseQueryFn = async ({ headers, isRefresh = false, ...config }) => {
  const { accessToken, impersonatedEmail } = PersistService.getCredentials();

  try {
    const result = await apiInstance.request({
      ...config,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        ...headers,
        ...(accessToken && !isRefresh ? { Authorization: `Bearer ${accessToken}` } : undefined),
        ...(impersonatedEmail && { 'X-Switch-User': impersonatedEmail }),
      },
    });

    return { data: result.data };
  } catch (error) {
    if (!isAxiosError(error)) throw error;

    if (error.response?.status === 403 && impersonatedEmail) {
      PersistService.remove('X-Switch-User');
      window.location.reload();
    }

    const errors = error.response?.data.errors as ValidationError;

    return {
      error: {
        message: error.response?.data.message ?? error.response?.data.error ?? error.code,
        status: Number(error.response?.status ?? error.status),
        errors,
        stripeError: error.response?.data.stripeError,
      },
    };
  }
};

const axiosBaseQueryWithReAuth: AxiosBaseQueryFn = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  const { accessToken, refreshToken } = PersistService.getCredentials();

  let result: QueryReturnValue<unknown, AxiosBaseQueryError, object> = { data: null };

  if (!accessToken || !refreshToken) {
    // Send a request to unprotected routes
    result = await axiosBaseQuery(args, api, extraOptions);
  } else if (!isTokenValid(accessToken)) {
    result = { error: { message: 'TOKEN_MALFORMED', status: 401 } };
    store.dispatch(onSignOut());
  } else if (!isTokenExpired(accessToken) && mutex.isLocked()) {
    // Wait to unlock requests because of token refresh
    await mutex.waitForUnlock();

    result = await axiosBaseQuery(args, api, extraOptions);
  } else if (!isTokenExpired(accessToken)) {
    // Send a request to protected routes
    result = await axiosBaseQuery(args, api, extraOptions);
  } else {
    // Token is expired and requests are blocked
    const release = await mutex.acquire();

    try {
      const refreshResult = await axiosBaseQuery(
        {
          ...args,
          method: 'POST',
          url: '/token/refresh',
          data: { refresh_token: refreshToken },
          isRefresh: true,
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const credentials = toTokenModel(refreshResult.data as TokenDTO);

        PersistService.setCredentials(credentials);

        // Save new exp for the token
        const payload = parseJWT(credentials.accessToken);

        PersistService.set('token-expiry-time', payload ? payload.exp : null);

        result = await axiosBaseQuery(args, api, extraOptions);
      } else {
        result = await axiosBaseQuery({ ...args, method: 'POST', url: '/logout' }, api, extraOptions);
        store.dispatch(onSignOut());
      }
    } finally {
      release();
    }
  }

  return result;
};

export const sequentialBaseQueryFactory = (sequentialMutex: InstanceType<typeof Mutex>) => {
  const sequentialBaseQuery: AxiosBaseQueryFn = async (args, api, extraOptions) => {
    await sequentialMutex.waitForUnlock();
    const releaseSequence = await sequentialMutex.acquire();

    const result = await axiosBaseQueryWithReAuth(args, api, extraOptions);

    releaseSequence();

    return result;
  };

  return sequentialBaseQuery;
};

export const base = createApi({
  baseQuery: axiosBaseQueryWithReAuth,
  tagTypes: tags,
  endpoints: () => ({}),
});
