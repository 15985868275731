import type { Nullable } from 'types';

export type CommonState = {
  reviewBannerDismissed: boolean;
};

export enum Experiment {
  AppreciationBanner = 'APPRECIATION_BANNER',
  BlackFriday = 'BLACK_FRIDAY',
}

export type DashboardMaintenanceWindow = {
  start: Nullable<string>;
  end: Nullable<string>;
  note: Nullable<string>;
  replacementNote: Nullable<string>;
};
