import type { Components, Theme } from '@mui/material/styles';

export const MuiFormGroup: Components<Theme>['MuiFormGroup'] = {
  styleOverrides: {
    root: {
      '--FormGroup-gap': '16px',

      gap: 'var(--FormGroup-gap)',
    },
  },
};
