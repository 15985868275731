import { type MouseEvent, useCallback, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { useFeatureFlagEnabled } from 'posthog-js/react';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';

import { AppSwitchMenu } from './AppSwitchMenu';

export function LogoButton() {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const isVPNPromotionEnabled = useFeatureFlagEnabled('vpn-promotion');

  const handleOpenMenu = useCallback(() => {
    if (!isVPNPromotionEnabled) return;

    setPopoverOpen(true);
  }, [isVPNPromotionEnabled]);

  const onLogoClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (!isVPNPromotionEnabled) return;

      e.preventDefault();
    },
    [isVPNPromotionEnabled],
  );

  return (
    <>
      <Stack
        ref={anchorRef}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        maxHeight="var(--Navigation-height)"
        borderBottom="var(--Navigation-border)"
        p={2}
        onClick={handleOpenMenu}
        sx={{ ...(isVPNPromotionEnabled && { cursor: 'pointer' }) }}
      >
        <Box display="inline-flex" onClick={onLogoClick} component={RouterLink} href={paths.index}>
          <Logo wide />
        </Box>

        {isVPNPromotionEnabled && (
          <IconButton>
            <Icon name="chevron-up-down" size="large" color="var(--mui-palette-neutral-400)" />
          </IconButton>
        )}
      </Stack>

      <AppSwitchMenu anchorEl={anchorRef.current} open={isPopoverOpen} onClose={() => setPopoverOpen(false)} />
    </>
  );
}
