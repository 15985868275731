import { inputBaseClasses } from '@mui/material/InputBase';
import type { Components, Theme } from '@mui/material/styles';

export const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  defaultProps: { disableUnderline: true },
  styleOverrides: {
    root: {
      paddingRight: 'var(--Input-paddingInline)',
      paddingLeft: 'var(--Input-paddingInline)',

      'label + &': { marginTop: 8 },
    },

    input: {
      padding: 0,
      height: 'auto',

      [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
        '&::placeholder': { opacity: '1 !important' },
      },

      '&:-webkit-autofill': {
        marginInline: 'calc(var(--Input-paddingInline) * -1)',
        paddingInline: 'var(--Input-paddingInline)',
        boxShadow: 'none',
        WebkitTextFillColor: 'var(--mui-palette-text-primary)',
        caretColor: 'var(--mui-palette-common-black)',
      },
    },

    adornedEnd: {
      paddingRight: 'var(--Input-adorned-end-padding)',
    },

    adornedStart: {
      paddingLeft: 'var(--Input-adorned-start-padding)',
    },
  },
};
