import { useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { MultiUpdatesAccordion } from 'modules/proxies/MultiUpdatesAccordion';
import { useGetBulkProxyExtendPeriodPriceQuery, useGetProxyExtendPeriodPriceQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

import { BalanceSummary } from '../BalanceSummary';
import ModalStyled from '../styled';

type ExtendProxyPeriodFormProps = {
  proxies: ProxyModel[];
  setPriceForSingle: (price: number) => void;
};

type ExtendProxyPeriodValues = {
  periodInMonths: number;
};

export function ExtendProxyPeriodForm({ proxies, setPriceForSingle }: ExtendProxyPeriodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { values, handleSubmit, isSubmitting, isValid } = useFormikContext<ExtendProxyPeriodValues>();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const { data: extensionPriceSingle, isFetching: isPriceSingleFetching } = useGetProxyExtendPeriodPriceQuery(
    { proxyId: proxyIds[0], periodInMonths: values.periodInMonths },
    { skip: proxyIds.length > 1 },
  );

  const { data: extensionPriceBulk, isFetching: isPriceBulkFetching } = useGetBulkProxyExtendPeriodPriceQuery(
    { ids: proxyIds, periodInMonths: values.periodInMonths },
    { skip: proxyIds.length <= 1 },
  );

  const extensionPrice = proxyIds.length > 1 ? extensionPriceBulk : extensionPriceSingle;
  const isPriceFetching = isPriceSingleFetching || isPriceBulkFetching;

  const priceForSingle = useMemo(() => {
    if (extensionPriceSingle) {
      return +extensionPriceSingle.finalPrice.toFixed(2);
    }

    if (!extensionPrice?.finalPrice) {
      return 0;
    }

    return proxyIds.length > 1 ? +(extensionPrice.finalPrice / proxyIds.length).toFixed(2) : extensionPrice.finalPrice;
  }, [extensionPrice?.finalPrice, extensionPriceSingle, proxyIds.length]);

  useEffect(() => {
    setPriceForSingle(priceForSingle);
  }, [priceForSingle, setPriceForSingle]);

  const periodOptions = useMemo<Array<Option<number>>>(() => {
    return [1, 2, 3, 6, 12].map((count) => ({
      value: count,
      label: t('common:form.month', { count }),
    }));
  }, [t]);

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.extend')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, isPriceFetching, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendPeriod.title')}
      subtitle={t('proxies.modals.extendPeriod.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <FormikAutocomplete
          size="small"
          disableClearable
          options={periodOptions}
          label={t('common:form.timePeriod')}
          name="periodInMonths"
        />

        <Stack direction="column" rowGap={2}>
          <BalanceSummary isLoading={isPriceFetching} price={extensionPrice?.finalPrice} />

          <Divider />

          <ModalStyled.PriceContainer>
            <Typography variant="title" color="var(--mui-palette-neutral-500)">
              {t('common:form.total')}
            </Typography>

            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(extensionPrice?.finalPrice || 0)}</Typography>
            )}
          </ModalStyled.PriceContainer>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
