import { useCallback, useMemo } from 'react';

export function useFreshChatAPI() {
  const openFreshChat = useCallback(() => {
    window.fcWidget?.show();
    window.fcWidget?.open();
  }, []);

  const hideFreshChat = useCallback(() => {
    window.fcWidget?.hide();
  }, []);

  return useMemo(() => ({ openFreshChat, hideFreshChat }), [hideFreshChat, openFreshChat]);
}
