import { toLanguage } from 'store/accounts/helpers';
import {
  type AuthenticationDTO,
  type CaptchaDTO,
  type MultiFactorDTO,
  type ProfileDetailsDTO,
  type SessionDTO,
  type TokenDTO,
  type VerificationRequestDTO,
  VerificationStatusDTO,
} from 'store/auth/dtos';

import {
  type AuthenticationModel,
  type CaptchaModel,
  type MultiFactorModel,
  type SessionModel,
  type SuspensionModel,
  type TokenModel,
  type VerificationRequestModel,
  VerificationStatus,
} from './models';

export function toCaptchaModel({ captchaEnabled, captchaSiteKey }: CaptchaDTO): CaptchaModel {
  return { isEnabled: captchaEnabled, siteKey: captchaSiteKey };
}

export function toTokenModel({ token, ...from }: TokenDTO): TokenModel {
  return { ...from, accessToken: token };
}

export function toMultiFactorModel(from: MultiFactorDTO): MultiFactorModel {
  return from;
}

export function toAuthenticationModel({ mfa, refreshToken, token }: AuthenticationDTO): AuthenticationModel {
  return { accessToken: token, refreshToken, mfa: mfa ? toMultiFactorModel(mfa) : null };
}

export function toVerificationStatus(from?: VerificationStatusDTO): VerificationStatus {
  if (from === VerificationStatusDTO.VERIFICATION_FAILED) {
    return VerificationStatus.FAILED;
  }

  if (from === VerificationStatusDTO.VERIFICATION_PENDING) {
    return VerificationStatus.PENDING;
  }

  if (from === VerificationStatusDTO.VERIFICATION_REQUIRED) {
    return VerificationStatus.REQUIRED;
  }

  if (from === VerificationStatusDTO.VERIFICATION_SUCCESSFUL) {
    return VerificationStatus.SUCCESSFUL;
  }

  return VerificationStatus.NOT_VERIFIED;
}

export function toSuspensionModel(from: ProfileDetailsDTO): SuspensionModel {
  return {
    reason: from.suspensionReason ?? null,
    explanation: from.suspensionReasonExplanation ?? null,
  };
}

export function toVerificationRequestModel(from: VerificationRequestDTO): VerificationRequestModel {
  return {
    reason: from.reason,
    status: toVerificationStatus(from.status),
  };
}

export function toSessionModel({ roles, profileDetails, userId, language, ...from }: SessionDTO): SessionModel {
  return {
    ...from,
    userId,
    language: toLanguage(language),
    email: profileDetails.email,
    firstName: profileDetails.firstName,
    lastName: profileDetails.lastName,
    roles: roles ?? [],

    isActive: profileDetails.isActive,
    isProfileValid: profileDetails.valid,
    isProfileSkipped: profileDetails.isProfileSkipped,
    isSuspended: profileDetails.isSuspended,

    suspension: toSuspensionModel(profileDetails),
    verificationRequest: toVerificationRequestModel(profileDetails.verificationRequest),
  };
}
