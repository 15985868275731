import { useCallback, useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';

import { AsYouType, type NumberType } from 'libphonenumber-js';
import metadatas from 'libphonenumber-js/metadata.min.json';
import isEqual from 'lodash/isEqual';

import type { Nullable } from 'types';
import { getKeys } from 'utils/values';

export const PHONE_NUMBER_COUNTRIES = metadatas.countries;

export const ISO_CODES = getKeys(PHONE_NUMBER_COUNTRIES);

export type PhoneNumberCountryCode = (typeof ISO_CODES)[number];

type GetInitialStateArgs = {
  initialValue: string;
  disableFormatting?: boolean;
};

function getInitialState({ initialValue, disableFormatting }: GetInitialStateArgs) {
  const fallbackValue = '';

  const asYouType = new AsYouType();

  let inputValue = asYouType.input(initialValue);

  if (inputValue === '+') {
    inputValue = '+';
  }

  const phoneNumberValue = asYouType.getNumberValue();

  if (disableFormatting && phoneNumberValue) {
    inputValue = phoneNumberValue;
  }

  return inputValue ?? fallbackValue;
}

export interface PhoneInputInfo {
  nationalNumber: Nullable<string>;
  numberType: Nullable<Exclude<NumberType, undefined>>;
  numberValue: Nullable<string>;
}

type UsePhoneDigitsArgs = {
  value: string;
  onChange?: (value: string, info: PhoneInputInfo) => void;
  disableFormatting?: boolean;
};

export function usePhoneDigits({ value, onChange, disableFormatting }: UsePhoneDigitsArgs) {
  const asYouTypeRef = useRef(new AsYouType());
  const inputRef = useRef<HTMLInputElement>(null);
  const previousCountryRef = useRef<Nullable<PhoneNumberCountryCode>>(null);

  const [inputValue, setInputValue] = useState(() => getInitialState({ initialValue: value, disableFormatting }));
  const [previousValue, setPreviousValue] = useState(value);

  const buildOnChangeInfo = useCallback((): PhoneInputInfo => {
    return {
      nationalNumber: asYouTypeRef.current.getNationalNumber(),
      numberType: asYouTypeRef.current.getNumber()?.getType() ?? null,
      numberValue: asYouTypeRef.current.getNumberValue() ?? null,
    };
  }, []);

  const typeNewValue = useCallback((newValue: string) => {
    asYouTypeRef.current.reset();

    return asYouTypeRef.current.input(newValue);
  }, []);

  const makeSureStartsWithPlusOrEmpty = useCallback((newValue: string) => {
    return newValue.startsWith('+') || newValue === '' ? newValue : `+${newValue}`;
  }, []);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = makeSureStartsWithPlusOrEmpty(event.target.value);

      const formattedValue = typeNewValue(newValue);
      const newCountryCode = asYouTypeRef.current.getCountry();
      const country = newCountryCode || previousCountryRef.current;
      const numberValue = asYouTypeRef.current.getNumberValue() || '';
      previousCountryRef.current = country;

      if (numberValue && !country) {
        setPreviousValue(numberValue);
        setInputValue(numberValue);

        return onChange?.(numberValue, { ...buildOnChangeInfo(), nationalNumber: null });
      }

      setPreviousValue(formattedValue);
      setInputValue(formattedValue);

      return onChange?.(formattedValue, buildOnChangeInfo());
    },
    [buildOnChangeInfo, makeSureStartsWithPlusOrEmpty, onChange, typeNewValue],
  );

  useEffect(() => {
    if (!isEqual(value, previousValue)) {
      setPreviousValue(value);
      setInputValue(getInitialState({ initialValue: value }));
    }
  }, [previousValue, value]);

  return useMemo(() => ({ inputValue, onInputChange, inputRef }), [inputValue, onInputChange]);
}
