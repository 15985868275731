import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow/arrow-down.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow/arrow-right.svg';
import { ReactComponent as ArrowUpDownIcon } from 'assets/icons/arrow/arrow-up-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow/arrow-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/arrow/chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/arrow/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/arrow/chevron-right.svg';
import { ReactComponent as ChevronUpDownIcon } from 'assets/icons/arrow/chevron-up-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/arrow/chevron-up.svg';
import { ReactComponent as CircleArrowUp } from 'assets/icons/arrow/circle-arrow-up.svg';
import { ReactComponent as ForwardSquareIcon } from 'assets/icons/arrow/forward-square.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/arrow/logout.svg';
import { ReactComponent as ReceiveSquareIcon } from 'assets/icons/arrow/receive-square.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/arrow/refresh-2.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/arrow/repeat.svg';

export const arrow = {
  'arrow-down': ArrowDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'arrow-up-down': ArrowUpDownIcon,
  'arrow-up': ArrowUpIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-up-down': ChevronUpDownIcon,
  'chevron-up': ChevronUpIcon,
  'circle-arrow-up': CircleArrowUp,
  'forward-square': ForwardSquareIcon,
  logout: LogoutIcon,
  'receive-square': ReceiveSquareIcon,
  refresh: RefreshIcon,
  repeat: RepeatIcon,
};
