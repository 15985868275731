import type { AppEndpointBuilder } from 'store/types';

import { toAvailableDealModel, toMarketplaceCountries } from './helpers';
import type { AvailableDealModel } from './models';
import type { MarketplaceParams } from './payloads';

export const marketplaceEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getMarketplaceAvailableCountries: builder.query<string[], void>({
    query: () => ({
      url: 'marketplace',
      method: 'GET',
    }),
    providesTags: ['Marketplace-Country'],
    transformResponse: toMarketplaceCountries,
  }),

  getMarketplaceDeals: builder.query<AvailableDealModel[], MarketplaceParams>({
    query: (params) => ({
      url: 'marketplace',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'Marketplace' as const, id })), { type: 'Marketplace', id: 'LIST' }]
        : [{ type: 'Marketplace', id: 'LIST' }],
    transformResponse: toAvailableDealModel,
  }),

  // #endregion Queries
});
