import { useMemo } from 'react';

import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

import type { Optional, TabOption } from 'types';

type Options<K, Href extends Optional<string> = undefined, T extends TabOption<K, Href> = TabOption<K, Href>> = {
  items: readonly T[];
  defaultValue?: K;
  type?: 'pathname' | 'param';
};

export function useActiveTab<
  K,
  Href extends Optional<string> = undefined,
  T extends TabOption<K, Href> = TabOption<K, Href>,
>({ items = [], defaultValue, type = 'pathname' }: Options<K, Href, T> = { items: [], type: 'pathname' }) {
  const { pathname, search } = useLocation();

  const activeTab = useMemo(() => {
    if (type === 'pathname') {
      return (
        items.find((item) => {
          if (!('href' in item)) {
            throw new Error('useActiveTab depends on the `href` parameter - it should not be used without it');
          }

          const { href } = item;

          return includes(pathname, href) && isEqual(pathname, href);
        })?.value ?? defaultValue
      );
    }

    return (
      items.find((item) => {
        if (!('href' in item)) {
          throw new Error('useActiveTab depends on the `href` parameter - it should not be used without it');
        }

        const { href } = item;

        return includes(pathname + search, href);
      })?.value ?? defaultValue
    );
  }, [defaultValue, items, pathname, search, type]);

  return useMemo(() => activeTab, [activeTab]);
}
