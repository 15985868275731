import { useEffect, useMemo, useState } from 'react';

export function useListenFreshChatUnreadCount() {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    // FreshChat should be initialized by other code, need space to hook on events
    setTimeout(() => {
      window.fcWidget?.on('unreadCount:notify', ({ count }) => {
        setHasUnreadMessages(!!count);
      });
    }, 2000);
  }, []);

  return useMemo(() => ({ hasUnreadMessages }), [hasUnreadMessages]);
}
