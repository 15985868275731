import { useMemo } from 'react';

import { useSession } from 'hooks/useSession';
import { paths } from 'paths';

import { useOrderFlow } from './useOrderFlow';

const NAV_ITEMS = [
  { label: 'common:order.steps.type', path: paths.order.index },
  { label: 'common:order.steps.setup', path: paths.order.proxy },
  { label: 'common:order.steps.vpnPlan', path: paths.order.vpn },
  { label: 'common:order.steps.details', path: paths.order.details },
  { label: 'common:order.steps.checkout', path: paths.order.checkout },
];

export function useOrderNavigationItems() {
  const { isVPNFlow, isMarketplaceFlow } = useOrderFlow();

  const { isProfileDetailsValid, isSessionLoading } = useSession();

  return useMemo(() => {
    return (
      NAV_ITEMS
        // by flow
        .filter(({ path }) =>
          isVPNFlow ? ![paths.order.index, paths.order.proxy].includes(path) : path !== paths.order.vpn,
        )
        .filter(({ path }) => (isMarketplaceFlow ? ![paths.order.index].includes(path) : true))
        // by profile details
        .filter(({ path }) => path !== paths.order.details || (!isProfileDetailsValid && !isSessionLoading))
    );
  }, [isMarketplaceFlow, isProfileDetailsValid, isSessionLoading, isVPNFlow]);
}
