/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import trim from 'lodash/trim.js';

const NAMESPACES = ['common', 'countriesregions', 'errors', 'headers', 'pages'];
const SUPPORTED_POE_LANGUAGES = ['en', 'es', 'ru', 'vi', 'zh'];

/**
 * Get namespace and actual context from Poeditor context
 *
 * @param {string} context - Context string from Poeditor
 * @returns Namespace and remain context
 */
export function getNamespaceFromContext(context) {
  const contextParts = context.split('.').map((item) => trim(item, '"'));

  return {
    namespace: contextParts[0],
    actualContext: contextParts.slice(1).join('.'),
  };
}

/**
 * Add missing translation into the resource
 *
 * @param {object} resource - Translation resource
 * @param {string} param1.key - Translation key
 * @param {string} param1.context - Translation context
 * @param {string} param1.content - Translation content
 */
function assignTranslationToResource(resource, { key, context, content }) {
  if (context) {
    context.split('.').forEach((rawContextKey) => {
      const contextKey = trim(rawContextKey, '"');

      if (!resource[contextKey] || typeof resource[contextKey] !== 'object') {
        resource[contextKey] = {};
      }

      resource = resource[contextKey];
    });
  }

  if (content) {
    resource[key] = content;
  }
}

/**
 * Transforms json path into Poeditor friendly context format
 *
 * @param {object} resTranslation - Translation subtree
 * @param {string[]} parents - Array of keys to concat
 * @returns Poeditor context formatted string
 */
function flattenResourceTranslation(resTranslation, parents = []) {
  return Object.keys(resTranslation).reduce((acc, key) => {
    const keys = [...parents, key];

    if (typeof resTranslation[key] === 'string') {
      return {
        ...acc,
        [keys.join('.')]: resTranslation[key],
      };
    }

    return {
      ...acc,
      ...flattenResourceTranslation(resTranslation[key], keys),
    };
  }, {});
}

/**
 * Create resource object from Poeditor translation
 *
 * @param {object} translations - Poeditor translations
 * @param {object} resource - Resource to extend
 * @returns Extended resource translation
 */
export function mapTranslationsToResource(translations, resource = {}) {
  translations.forEach((translation) => assignTranslationToResource(resource, translation));

  return resource;
}

/**
 * Transform resource translation into Poeditor object
 *
 * @param {string} namespace - Resouce namespace
 * @param {object} resource - Resource namespace object with translation
 * @returns Poeditor object
 */
export function mapResourceToTranslations(namespace, resource) {
  const flattenTranslations = flattenResourceTranslation(resource, [namespace]);

  return Object.keys(flattenTranslations).map((pathKey) => {
    const contextKeys = pathKey.split('.');
    const key = contextKeys.pop();

    return {
      key,
      context: contextKeys.map((contextKey) => `"${contextKey}"`).join('.'),
      content: flattenTranslations[pathKey],
    };
  });
}

/**
 * Get default resources object
 *
 * @returns Default resources object
 */
export function getDefaultResources() {
  return SUPPORTED_POE_LANGUAGES.reduce((memo, language) => {
    memo[language] = NAMESPACES.reduce(async (result, fileName) => {
      result[fileName] = await import(`../../translations/${language}/${fileName}.json`);

      return result;
    }, {});

    return memo;
  }, {});
}

/**
 * Map i18n language string with poeditor
 *
 * @param {string} langCode - i18n Language code
 * @returns Poeditor supported language
 */
export function toPoeditorLanguage(langCode) {
  switch (langCode) {
    case 'zh':
      return 'zh-Hans';
    default:
      return langCode;
  }
}
