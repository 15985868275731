/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactGA from 'react-ga4';

import { PaymentOption } from 'modules/billing/types';
import type { NetworkType } from 'store/proxies/types';
import type { Nullable } from 'types';

type CommonEcommerceEventProps = {
  networkType: Nullable<NetworkType>;
  variant?: PaymentOption;
};

type CommonProxyEventProps = {
  userId?: number;
  value?: number;
  proxy_id?: number;
};

type EcommercePurchaseEventProps = CommonEcommerceEventProps & {
  value: number;
  coupon?: string;
  discount?: number;
  count?: number;
  userId?: number;

  // extending
  isExtending?: boolean;
  bandwidth?: number;
  period?: number;

  newCustomer?: boolean;
};

type CommonVPNEventProps = {
  userId?: number;
  value?: number;
  vpnId?: number;
};

export const GA_EVENTS = {
  userLogIn: () => {
    return ReactGA.event('sign_in');
  },

  userRegistered: () => {
    return ReactGA.event('registration');
  },

  topUpSuccess: (value: number, userId?: number) => {
    return ReactGA.event('top_up_success', { user_id: userId, value });
  },

  purchaseSuccess: ({
    value,
    networkType,
    variant,
    count = 1,
    coupon,
    discount,
    userId,
    bandwidth,
    period,
    isExtending = false,
    newCustomer = false,
    transactionId,
  }: EcommercePurchaseEventProps & { transactionId: string }) => {
    return ReactGA.event('purchase', {
      transaction_id: transactionId, // random unique field to avoid dusplicated on GA level
      value,
      currency: 'USD',
      items: [
        {
          item_name: networkType,
          item_variant: variant,
          coupon,
          discount,
          index: 0,
          price: value,
          quantity: count,
          item_list_id: userId,
          item_list_name: isExtending ? 'extend' : 'new',
          item_category2: period,
          item_category3: bandwidth,
        },
      ],
      new_customer: newCustomer,
    });
  },

  proxyPeriodExtended: (eventProps: CommonProxyEventProps & CommonEcommerceEventProps & { period: number }) => {
    ReactGA.event('proxy_period_extended', eventProps);

    GA_EVENTS.purchaseSuccess({
      transactionId: `T_${Date.now()}`,
      value: eventProps.value!,
      networkType: eventProps.networkType,
      variant: PaymentOption.WALLET,
      isExtending: true,
      userId: eventProps.userId,
      bandwidth: eventProps.period,
    });
  },

  proxyBandwidthPurchased: (eventProps: CommonProxyEventProps & CommonEcommerceEventProps & { bandwidth: number }) => {
    ReactGA.event('proxy_bandwidth_purchased', eventProps);

    GA_EVENTS.purchaseSuccess({
      transactionId: `T_${Date.now()}`,
      value: eventProps.value!,
      networkType: eventProps.networkType,
      variant: PaymentOption.WALLET,
      isExtending: true,
      userId: eventProps.userId,
      bandwidth: eventProps.bandwidth,
    });
  },

  profileUpdated: (userId?: number) => {
    return ReactGA.event('user_profile_updated', { user_id: userId });
  },

  proxyBandwidthSpeedUpgraded: (eventProps: CommonProxyEventProps & { bandwidthSpeed: number }) => {
    return ReactGA.event('proxy_bandwidth_speed_upgraded', eventProps);
  },

  proxyThreadsUpgraded: (eventProps: CommonProxyEventProps & { threads: number }) => {
    return ReactGA.event('proxy_threads_upgraded', eventProps);
  },

  vpnPeriodExtended: (eventProps: CommonVPNEventProps & CommonEcommerceEventProps & { period: number }) => {
    ReactGA.event('vpn_period_extended', eventProps);

    GA_EVENTS.purchaseSuccess({
      transactionId: `T_${Date.now()}`,
      value: eventProps.value!,
      networkType: eventProps.networkType,
      variant: PaymentOption.WALLET,
      isExtending: true,
      userId: eventProps.userId,
      period: eventProps.period,
    });
  },

  addToCart: ({
    value,
    networkType,
    count = 1,
    coupon,
    discount,
    userId,
    bandwidth,
    period,
  }: EcommercePurchaseEventProps) => {
    ReactGA.event('add_to_cart', {
      value,
      currency: 'USD',
      items: [
        {
          item_name: networkType,
          coupon,
          discount,
          index: 0,
          price: value,
          quantity: count,
          item_list_id: userId,
          item_list_name: 'new',
          item_category2: period,
          item_category3: bandwidth,
        },
      ],
    });
  },

  removeFromCart: ({
    value,
    networkType,
    count = 1,
    coupon,
    discount,
    userId,
    bandwidth,
    period,
  }: EcommercePurchaseEventProps) => {
    ReactGA.event('remove_from_cart', {
      value,
      currency: 'USD',
      items: [
        {
          item_name: networkType,
          coupon,
          discount,
          index: 0,
          price: value,
          quantity: count,
          item_list_id: userId,
          item_list_name: 'new',
          item_category2: period,
          item_category3: bandwidth,
        },
      ],
    });
  },

  beginCheckout: ({
    value,
    networkType,
    variant,
    count = 1,
    coupon,
    discount,
    userId,
    bandwidth,
    period,
  }: EcommercePurchaseEventProps) => {
    ReactGA.event('begin_checkout', {
      value,
      currency: 'USD',
      items: [
        {
          item_name: networkType,
          item_variant: variant,
          coupon,
          discount,
          index: 0,
          price: value,
          quantity: count,
          item_list_id: userId,
          item_list_name: 'new',
          item_category2: period,
          item_category3: bandwidth,
        },
      ],
    });
  },
};
