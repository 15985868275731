import { useEffect } from 'react';

import ButtonBase from '@mui/material/ButtonBase';

import { Icon } from 'components/Icon';

import { useCannyContext } from './CannyProvider';

export function CannyChangelogButton() {
  const { isLoaded, appID, canny } = useCannyContext();

  useEffect(() => {
    if (!isLoaded || !appID) {
      return;
    }

    canny.initChangelog({ appID, align: 'right', position: 'bottom' });

    return () => canny.closeChangelog();
  }, [appID, canny, isLoaded]);

  return (
    <ButtonBase data-canny-changelog sx={{ display: 'flex', alignItems: 'center' }} disableRipple>
      <Icon name="notification" color="var(--mui-palette-neutral-400)" />
    </ButtonBase>
  );
}
